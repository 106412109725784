.works-container {
    margin: 0 auto;
    width: 90%;
    padding-bottom: 55px;
}

.works-container p {
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: #29292E;
    flex: 1;
    padding: 10px;
}

.text-works {
    background: #FFFFFF;
    box-shadow: 0px 8px 36px rgba(7, 42, 68, 0.04);
    border-radius: 10.9746px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.works-container img {
    flex: 1;
    padding: 10px;
}

.line p {
    color: #95A585;
    font-weight: 900;
    font-size: 40px;
}

.side-img {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.side-text {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.works-area-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(186, 208, 162, 0.7);
    padding-left: 7%;
    padding-right: 7%;
    margin-top: 8%;
    justify-content: right;

    position: sticky;
    top: 0;
    margin-bottom: 7rem;
}

.text-works-container {
    text-align: left !important;
    margin: 40px 0 30px 19rem;
}

.text-works-container button {
    background: #BAD0A2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 2rem 0;
    padding: 1rem 2rem;
}

.text-works-container h2 {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 32px;
    line-height: 30px;
    color: #29292E;
    text-align: left;
}

.text-works-container h1 {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 32px;
    line-height: 30px;
    margin-top:10px;
    color: #29292E;
    text-align: left;
}

.button-message p {
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #29292E;
}


.works-area-container img {
    position: absolute;
    top: -4.5rem;
    left: 6rem;
    width: 40%;
}

@media screen and (max-width: 475px) {
    .works-area-container {
        flex-direction: column;
        padding-left: 0%;
        padding-right: 0%;
        justify-content: center;
        margin-bottom: 5rem;
    }

    .side-text {
        width: 100%;
        padding-top: 10rem;
    }

    .text-works-container h2 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .text-works-container h1 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .text-works-container {
        text-align: center !important;
        margin: 8px 0 30px;
    }

    .works-area-container img {
        left: 0rem;
        width: 100%;
    }

    .we-help-you {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}
@media (min-width: 476px) and (max-width: 575px) {
    .works-area-container {
        flex-direction: column;
        padding-left: 0%;
        padding-right: 0%;
        justify-content: center;
    }


    .side-text {
        width: 100%;
        padding-top: 13rem;
    }


    .text-works-container h2 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .text-works-container h1 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }


    .text-works-container {
        text-align: center !important;
        margin: 8px 0 30px;
    }


    .works-area-container img {
        left: 0rem;
        width: 100%;
    }

    .we-help-you {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}

@media (min-width: 575px) and (max-width: 768px) {
    .works-area-container {
        flex-direction: column;
        padding-left: 0%;
        padding-right: 0%;
        justify-content: center;
    }

    .side-text {
        width: 100%;
        padding-top: 16rem;
    }


    .text-works-container h2 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .text-works-container h1 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }


    .text-works-container {
        text-align: center !important;
        margin: 8px 0 30px;
    }

    .works-area-container img {
        left: 7%;
        width: 85%;
    }

    .works-area-container {
        margin-top: 12%
    }

    .we-help-you {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }

}

@media (min-width: 768px) and (max-width: 992px) {
    .works-area-container {
        flex-direction: column;
        padding-left: 0%;
        padding-right: 0%;
        justify-content: center;
    }

    .side-text {
        width: 100%;
        padding-top: 20rem;
    }


    .text-works-container h2 {
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .text-works-container h1 {
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .text-works-container {
        text-align: center !important;
        margin: 8px 0 30px;
    }

    .works-area-container img {
        top: -7rem;
        left: 10%;
        width: 80%;
    }

    .works-area-container {
        margin-top: 15%
    }

    .we-help-you {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}

@media (min-width: 992px) and (max-width: 1366px) {
    .we-help-you {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}

@media (min-width: 1300px) and (max-width: 1550px) {
    .works-area-container img {
        position: absolute;
        top: -5.5rem;
        left: 6rem;
        width: 40%;
    }

    .we-help-you {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}

@media (min-width: 1550px) and (max-width: 1800px) {
    .works-area-container img {
        position: absolute;
        top: -7rem;
        left: 6rem;
        width: 40%;
    }

    .we-help-you {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}

@media (min-width: 1800px) and (max-width: 1932px) {
    .works-area-container img {
        position: absolute;
        top: -9rem;
        left: 6rem;
        width: 38%;
    }

    .works-area-container {
        margin-top: 9%
    }

    .we-help-you {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}

@media (min-width: 1932px)  and (max-width: 2100px){
    .works-area-container img {
        position: absolute;
        top: -12rem;
        left: 6rem;
        width: 37%;
    }

    .works-area-container {
        margin-top: 9.5%
    }

    .we-help-you {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}

@media (min-width: 2100px) {
    .works-area-container img {
        position: absolute;
        top: -15rem;
        left: 6rem;
        width: 35%;
    }

    .works-area-container {
        margin-top: 10%
    }

    .we-help-you {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}

.how-column {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 8px 36px rgba(7, 42, 68, 0.04);
    border-radius: 10.9746px;
    padding: 2rem 2rem;
    margin-top: 0.5rem;
    flex-direction: column;
    justify-content: center;

}

.how-column h3 {
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: #29292E;
}

.how-column-span {
    display: flex;
    margin-top: 0.5rem;
    flex-direction: column;
    justify-content: center;
    margin: 0rem 0.5rem;

    color: #95A585;
    font-weight: 900;
    font-size: 40px;
}

.how-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: space-between;
    margin-left: 7rem;
    margin-right: 7rem;
}
