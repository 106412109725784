.message-green-chat {
    background: #F6FFEA;
    border: 1px solid #F5F6F7;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 5px;
    padding: 15px;
    margin-right: 20px;
    width: 80%; 
}

.message-white-chat {
    background: #FFFFFF;
    border: 1px solid #F5F6F7;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 5px;
    padding: 15px;
    margin-left: 20px;
    width: 80%;
}

.message {
    margin-bottom: 20px;
}

.message img {
    width: 45px;
    height: 45px;
}

@media (max-width: 768px){

    .message-green-chat {
        width: 90%; 
    }

    .message-white-chat {
        width: 90%; 
    }
}

@media screen and (max-width: 485px) {
    
}

