.form-chapt1{
    position: relative;
    height: 287px;
    max-width: 920px;
    margin: auto;
    padding: 60px;
}

.form-chapt1{
    /* background: linear-gradient(0deg, #C4C4C4, #C4C4C4),
    linear-gradient(0deg, #FFFFFF, #FFFFFF); */
    /* border: 0.2px solid #C3C3C3; */
    border: 0.2px solid #C3C3C3;
    border-radius: 5px;
    box-shadow: 0px 4px 8px 3px #F2F4F5;  
}

.form-button-right{
    margin-top: 20px;
    float: right;
}

.form-button-right{
    text-transform: capitalize;
    font-family: 'Montserrat',sans-serif;
    font-size: 20px;
    font-weight:500;
    line-height:20px;
    background-color: transparent;
}

.form-chapt1 button:disabled{
    color: #B1B2B2;
}

.cuestionary-selection hr{
    max-width: 90%;
    margin: auto;
}

.cuestionary-selection label{
    font-family: Montserrat SemiBold;
    margin-left:5.5rem;
}

.cuestionary-selection-option{
   height: 30px;
   /* padding: 20px; */
   font-family:Montserrat !important;
   font-weight:400 !important;
   font-size:14px !important;
}


.cuestionary-selection-select{
    height: 47px !important;
    width: 80%;
    border-radius: 8px;
    margin-top:10px;
    margin-bottom: 70px;
    margin-left:5.5rem;
    font-family:Montserrat !important;
    font-weight:400 !important;
    font-size:14px !important;
    color:  #B1B2B2;
}

.begin-form select{
    height: 47px !important;
    border-radius: 2px;
    margin-bottom: 35px;
    color:  #050505;
}

.vikika-suscription-forms{
    /* position: relative; */
    color: #29292E;
    padding: 15%;
    padding-top: 200px;
    padding-bottom:10%;
}

.vikika-suscription-forms h2{
    position: absolute;
    font-family: 'Recline Medium';
    margin-left: auto; 
    left: 0; 
    right: 0; 
    margin-right: auto; 
    top: -70px;
    font-size: 40px;
    /* font-style: italic; */
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    
}

.page3-form-container{
    position: relative;
    color: #29292E;  
    margin-bottom: 2rem;
    /* max-width: 722px;
    margin: auto; */
    /* top: 200px; */
}


.image-file-container{
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
    border-width: 2px;
    border-radius: 10px;
    border-style: dashed;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 20px;
    padding-bottom:20px;
    box-shadow: 0px 7.493447780609131px 33.720516204833984px 0px #072A440A;
    border-color: #BAD0A2;
}

.image-file-container-error{
    border-color: #F70808 !important;
}

.image-file-container .img-file-section{
    margin: 20px;
}

.image-file-container p{
    /* padding-top: 20px; */
    text-align: center;
    font-weight: bolder; 
    font-family: 'Montserrat SemiBold';
}

.image-file-container input[type="file"] {
    display: none;
}

.custom-file-upload {
    background-color: #D9D9D9;
    width: 126px;
    height: 153px;
    border-radius: 10px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

/* body{
    background-color: white ;
    background-image: none;
} */
/* @media (max-width:1290px){
    .begin-form input[type=text]{
        display: block;
       max-width: 380.52px;
       width: 90% !important;
    }
} */

.photos-description{
    line-height:20px;
}

.photos-description-bold{
    font-weight:700;
}

#your-photos-title{
    font-family: Montserrat SemiBold;
    /* font-size: 10px; */
    font-style: normal;
    font-weight: 700;
    /* line-height: 30px;  */
    text-align: left;
}



/*::::::::::::::::::: BEGIN FORM ::::::::::::::::::::::::::::::*/

.begin-form{
    position: relative;
    /* max-height:974px; */
    /* height: 974px; */
    max-width: 920px;
    margin: auto;
    /* border: 0.2px solid #C3C3C3; */
    padding: 68px;
    /* box-shadow: 0px 2px 4px -2px #1113220F; */
    border-radius: 6px;
    box-shadow: 0px 4px 8px 3px #F2F4F5;
}

.data-form-titles{
    height: 68px;
    width: inherit;
    display: flex;
    margin: auto;
    margin-bottom: 0px;
    justify-content: center;
    
}

.data-form-titles p{
    margin-top: 10px;
}

.data-form-titles span{
    color:white;
    height: 36px;
    width: 36px;
    text-align:center;
    margin-right: 10px;
    border-radius: 50%;
    line-height: 35px;
    margin-bottom: 10px;
    background-color: #B1B2B2;
}

.data-form-titles-checks{
    display: flex;
    justify-content: center;
    line-height: 18px;
}

.data-form-titles-checks p{
    color: #B1B2B2;
}

.data-form-titles-checks span{
    height: 36px;
    width: 36px; 
}

span.success-step{
    background-color:#BAD0A2 ;
}

.success-step-p1{
    color: #29292E !important;
    width: inherit;
}

.success-step-p2{
    color: #29292E !important;
    width: inherit;
}

.success-step-p3{
    color: #29292E !important;
    width: inherit;
}

.data-form-titles span{
    color:white;
    height: 36px;
    width: 36px;
    text-align:center;
    margin-right: 10px;
    border-radius: 50%;
    line-height: 35px;
    margin-bottom: 10px;
    background-color: #B1B2B2;
}

.data-form-titles-checks span{
    height: 36px;
    width: 36px; 
}

.begin-form .line-progress{
    max-width: 64.5px;
    width:5vw;
    height: 2px;
    margin-top: 20px;
    margin-left: 15px;
}

.begin-form span.line-progress-active{
    background: linear-gradient(to right, #BAD0A2 50%, #B1B2B2 50%);
}

.begin-form span.line-progress-active-full{
    background: linear-gradient(to right, #BAD0A2 50%, #BAD0A2 50%);

}

.begin-form h3 img{
    width: 25px;
    margin-left: 8px;
    margin-right: 8px;
}

.begin-form h3{
    font-family: 'Montserrat SemiBold';
    font-size: 20px;
    margin: 5px;
    margin-bottom: 20px;
    font-weight: 900;
}

.begin-form a{
    margin-top: 15px;
}

.begin-form h4{
    font-family: 'Montserrat SemiBold';
    margin-top: 20px;
    margin-bottom: 15px;
}

.begin-form .photo-change{
    width:433px ;
    margin-bottom: 20px;
}

.begin-form .accept-box{
    margin-top: 15px;
}

.accept-box label{
    font-family:'Montserrat SemiBold';
    font-weight:600;
    font-size:14px;
    line-height:20px;
}

.begin-form .accept-box a{
    color: #0B49EB;
    text-decoration: none;
}

#support-form-contact{
    font-family:'Montserrat SemiBold';
    font-weight:600;
    font-size:14px;
    line-height:16px;
    margin-bottom: 20px;
    margin-top: 50px;
    color: #29292E;
} 

#support-form-contact-text{
    font-family:'Montserrat';
    font-weight:400;
    font-size:14px;
    line-height:16px;
    margin-left:2.5%;
} 

#support-form-contact-text a{
    color:#0B49EB;
}

.begin-form .questionnaire-button-left{
    font-family: Montserrat;
    font-size:20px;
    font-weight:500;
    line-height:20px;
    text-transform: capitalize;
    background-color: transparent;
}

.begin-form .questionnaire-button-right{
    width:242px;
    height:48px;
    float: right;
    text-transform: capitalize;
    background-color: #BAD0A2;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family:Montserrat;
    line-height:14.63px;
}

.send-button{
    font-family:Montserrat !important;
    font-weight:600;
}


.begin-form input[type="radio"]{
    /* margin-right: 10px;
    margin-left: 10px; */
    margin: 15px;
}

.final-form{
    padding:18px 28px !important;
}

.final-container{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.final-header{
    font-family: 'Recline Medium' !important;
    font-size: 29.06px !important;
    font-weight: 500 !important;
    line-height: 60.54px !important;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

.final-text{
    text-align:center;
    font-family: 'Montserrat';
    font-size: 16px;
}

.final-icon{
    background-color: #BAD0A2;
    color: white;
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    font-family: 'Montserrat', sans-serif;
    font-size: 44px;
}

.final-button{
    width: 242px;
    height: 48px;
    float: right;
    text-transform: capitalize;
    background-color: #BAD0A2;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family: Montserrat;
    line-height: 14.63px;
    margin-top:30px;
    margin-bottom:20px;
}

.instructions-images{
    font-size:10px;
    line-height:20px;
}

.instructions-images>img{
    position:relative;
    top:3px;
}

.instructions-images-error{
    color:#F70808;
}

.instructions-send-form{
    color: #29292E;
    font-size:12px;
    font-weight:600;
    margin-top: 10px;
    margin-bottom: 10px;
}


/*Modificaciones etiquetas MaterialUI*/

.materialui-fields-check{
    flex-direction: row !important;
}

.materialui-fields-label{
    width: auto !important;
    margin-top: 1rem;
    margin-left: 0px;
    margin-right: 0px;
}

.materialui-fields-label>span{
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 17.07px !important;
    /* padding-left:5px; */
    color:  #29292E !important;
}

.materialui-fields-select{
    width:100%;
    height: 47px !important;
    border-radius: 4px !important;
    border:0px solid #E5E5E5;
    /* border-color:#E5E5E5; */
    margin-top: 10px;
    margin-bottom: 35px; 
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 17.07px !important;
    /* padding-left:5px; */
    color:  #29292E !important; 
    padding:16.5px 14px 16.5px 4px !important;
}

.materialui-fields-select>div{
    padding:16.5px 0px !important;
}

.materialui-fields-option{
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 17.07px !important;
    color:  #29292E !important;
    height: 45px !important;
    padding:16.5px 4px !important;
}

.materialui-fields-select-button{
    padding-left:0px !important;
    padding-right:0px !important;
}

.MuiOutlinedInput-notchedOutline{
    border-style: solid;
    border-width: 0.83px;
    border-color: #E5E5E5;
}

.buySubscriptionsSelect{
    margin-bottom: 30px;
}

@media (max-width:1230px){

    .data-form-titles-checks p{
        font-size: 13px;
    }

    .sucess-step-icon{
        min-height: 36px !important;
        min-width: 36px !important;
        line-height:31.07px !important;
        padding-top:2px;
    }

}

@media (max-width:1120px){
    .data-form-titles{
        justify-content:normal;
    }
    .begin-form .line-progress{
        width:3vw;
    }

    .cuestionary-selection label{
        margin-left: 3.5rem;
    }

    .cuestionary-selection-select{
        margin-left: 3.5rem;
    }
}

@media (max-width:970px){

    .data-form-titles-checks p{
        font-size: 13px;
    }

    .begin-form .line-progress {
        margin-right:10px;
    }

    .data-form-titles span {
        margin-right:0px;
    }
}

@media (max-width:834px){

    .data-form-titles-checks p{
        font-size: 12px;
        margin-left:5px;
    }

}

@media (max-width:768px){

    .vikika-suscription-forms h2{
        font-size: 20px;
        top: -48px;
    }

    .begin-form{
        /* max-height:490px; */
        height:auto;
        min-height: 490px;
        position: relative;
/*         max-width: 336px;
        min-width: 336px; */
    }

    .begin-form .questionnaire-button-right{
        /* width: 107.5px; */
        width: 160px;
        height: 25px;
        font-size: 12px;
    }

    .vikika-suscription-forms{
        padding-top:150px;
        padding-left: 12px;
        padding-right: 12px;
        min-height: 500px;
    }

    .data-form-titles{
        height: 51px;
        width: 111px;
        margin: auto;
    }

    .data-form-titles span{
        margin-right: 0px;
        margin-top: 11px;
    }

    .data-form-titles p{
        display: none;
    }

    .sucess-step-icon{
        min-height: 22px !important;
        min-width: 22px !important;
        line-height:17.07px !important;
        padding-top:2px;
    }

    .begin-form .line-progress{
        width:43.94px;
        margin-left:0px;
        margin-right:0px !important;
    }

    .cuestionary-selection label{
        margin-left: 2.5rem;
    }
    
    .cuestionary-selection-select{
        margin-left: 2.5rem;
    }

    .cuestionary-selection-option{
        font-size:12px !important;
    }

    .fam-groups{
        padding-bottom:0px !important;
    } 

    .fam-groups input[type=text]{
        margin-bottom:0px !important;
    } 

    .data-form-titles span{
        margin-right: 0px;
        margin-top: 11px;
    }

    .data-form-titles p{
        display: none;
    }

    .photos-description{
        line-height:15px !important;
    }

    .send-button{
        width:107.5px;
        height:25px;
        /* padding-top:0.2rem; */
    }

    .instructions-send-form{
        font-size:10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .materialui-fields-label{
        font-size:12px !important;
        margin-top:0rem;
        margin-bottom:0rem !important; 
    }

    .materialui-fields-label>span {
        font-size:12px !important;
    }

    .materialui-fields-select{
        height: 28px !important;
        font-size:12px !important;
    }

    .materialui-fields-option{
        font-size:12px !important;
        padding-left:0px !important;
    }

    .final-form{
        padding:18px 28px !important;
        min-height: auto !important;
    }

    .final-icon{
        height: 50px;
        width: 50px;
        font-size: 34px;
    }

    .final-text{
        font-size:14px;
        margin-top:10px;
    }

    .final-header{
        font-size:20px !important;
        text-align:center;
        line-height:30px !important;
    }

    .final-button{
        font-size: 12px;
        width: 160px;
        height: 35px;
    }

}

@media(max-width:760px){

    .nxt-form-button,.prev-form-button,.next-field{
        font-size: 14px;
    }
    .nxt-form-button img,.prev-form-button img,.next-field img{
        width: 20%;
    }
     .questionnaire-button-left {
        width: max-content !important;
        font-size: 15px !important;
        margin-top: 5px;
    }
    .questionnaire-button-left img{
        width: 18%;
    }

    .begin-form .questionnaire-button-right{
        /* width: 200px; */
        font-size: 10px;
    }

    .custom-file-upload{
        width: 119.434px !important;
        height: 142.17px !important;
    } 
    
    .cuestionary-selection label{
        margin-left: 1.5rem;
    }
    
    .cuestionary-selection-select{
        margin-left: 1.5rem;
        white-space: pre-line;
    }

    .cuestionary-selection-option{
        font-size:12px !important;
        min-height:27px;
    }
}

@media (max-width:500px){

    .image-file-container{
        padding-top: 0px;
        padding-bottom:0px;
    }

    .page3-form-container{
        margin-bottom: 0px;
    }

    #img-fill-doubt strong{
        font-size: 12px;
    }

   .cuestionary-selection label{
        margin-left: 0rem;
   }

   .form-chapt1{
        padding: 8px;
        padding-top: 20px;    height: 142px;
   }

   .begin-form .photo-change{
        width: 252px !important;
    }

   .cuestionary-selection hr {
        max-width: 100%;
    }

    .begin-form label{
        font-size: 12px;
    }

    .begin-form p{
        /* line-height: 18px; */
        font-size: 12px !important;
    }

    .begin-form h3{
        text-align: left;
        font-size: 14px !important;
    }

    .begin-form h3>img{
        width:12px;
        height:15px;
    }

    .begin-form a{
        margin-top: 5px !important;
        line-height: 18px;
        font-size: 12px;
    }

    .cuestionary-selection-select{
        margin-left: 0rem;
        width: 100%;
        min-width:100%;
        height: 28px !important;
        margin-bottom: 10px;
        font-size: 10px !important;
        white-space: pre-line;
    }

    .cuestionary-selection-option{
        font-size:10px !important;
        min-height:20px !important;
    }

    .form-button-right {
        margin-top: 5px;
        font-size:15px ;
        float: right;
    }

    .form-button-right img{
        width:12px ;
    }

    .custom-file-upload{
        width: 89.434px !important;
        height: 112.17px !important;
    }  

    .send-button{
        font-size: 10px !important;
    }
}

@media(max-width:420px){

    .custom-file-upload{
        width: 69.434px !important;
        height: 92.17px !important;
    } 
}

@media(max-width:398px){
    
    .begin-form .questionnaire-button-right{
    font-size: 12px;
    text-transform: uppercase;
    /* width: 160px;
    height: 25px; */
    }

    .begin-form .questionnaire-button-left{
        font-size: 10px;
    }

    .cuestionary-selection-select{
        font-size: 10px;
        white-space: pre-line;
        padding-left:0rem;
        /* width:auto; */
    }
    .cuestionary-selection-option{
        font-size: 8px;
    }

}

@media(max-width:360px){

    .image-file-container{
        display:grid;
        grid-template-columns:100%;
        grid-template-rows: 33.3% 33.3% 33.3%;
        justify-items: center;
    }

    .image-file-container .img-file-section{
        margin:0px;
    }

    .img-section-1 label,.img-section-2 label,.img-section-3 label{
        width: 110px !important;
        height: 130px !important;
    }
}

@media(max-width:328px){
    .begin-form .photo-change{
        width:200px !important;
    }

    .send-button{
        font-size: 10px !important;
    }
}

.form-data-processing{
    margin-left: 2.5%;
    margin-top: 18px;
    align-items: center;
    display: flex;
}
.form-data-processing p{
    margin-left: 10px;
font-family: Montserrat;
font-size: 14px;
font-weight: 400;
}