.begin-form label {
    margin-bottom: 12px;
}

.begin-form input[type=text] {
    margin-bottom: 30px;
}

.feeding-data-residence{
    margin-bottom:0px !important;
}

.feeding-data-label{
    font-family:Montserrat SemiBold;
    font-weight: 600;
    margin-top:0rem;
    margin-bottom:0px !important;
}

.feeding-data-p{
    font-family:Montserrat SemiBold;
    font-weight: 600;
}

.optional-sp input[type="text"]{
    min-width: 0; 
    margin-top: 10px;
    width: 97%;
    margin: 10px;
    background-color: #F6FFEA;
    color: #B1B2B2;
    font-weight: 100;
}

.optional-sp {
    padding-top: 15px;
    margin-bottom: 20px;
    background-color: #F6FFEA;
}

.optional-sp label{
    font-family:Montserrat SemiBold;
}

.foods-optional{
    display: flex;
}

.foods-optional>div{
    margin-right: 30px; 
    margin-top: 20px;
}

.foods-optional>div>div{
    margin-right: 30px; 
    margin-left:10px;
    margin-top: 0px;
}

.foods-optional p{
    margin-left: 10px;
    font-weight: 100;
}

.cocinar-check{
    flex-wrap: nowrap !important;
}

@media (max-width:878px){
    .foods-optional>div{
        margin-right: 0px; 
    }
}

@media (max-width:768px){

    .begin-form input[type=text] {
        margin-bottom: 0px;
    }
    
    .feeding-data-label{
        font-size:12px;
        margin-top: 1rem;
        margin-bottom:10px !important;
    }

    .feeding-data-p{
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .feeding-data-p-optional{
        font-size: 12px;
    }

    .feeding-data-residence-input{
        margin-top:0px !important;
        margin-bottom:0px !important;
    }

    .cook-option .materialui-fields-check>label{
        margin-top:0rem;
        margin-bottom:0rem; 
    }

    .cook-p {
        font-size:12px;
        padding-top:10px;
    }

    .cook-option{
        margin-top:0px !important;
    }

    .optional-sp {
        padding-top: 5px;
        margin-bottom: 10px;
        background-color: #F6FFEA;
    }

    .optional-sp>label{
        font-size: 12px;
    }

    .optional-sp>input[type=text]{
        margin-left:2px !important;
    } 

    .materialui-fields-select{
        margin-bottom: 0px;
    }

}

@media (max-width:528px){

    .foods-optional{
        display: grid;
        grid-template-columns:50% 50%;
        grid-template-rows:50% 50%;
    }
}
