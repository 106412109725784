.modal-success-messages{
    padding: 2rem 3rem !important;
    top: 10% !important;
    bottom: 10% !important;
    left: 5% !important;
    right: 5% !important;
    width: 90% !important;
}
.modal-success-scroll{
    overflow-y: scroll;
    overflow-x: hidden;
}
.message-container{
    width:98% !important;
    margin:1%;
}
.message-green{
    width:100% !important; 
    background: #F6FFEA;
    border: 1px solid #F5F6F7;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 5px;
    padding: 15px;
    margin-right: 20px;
    /* width: 80%;  */
    text-align: justify;
}
.message-white{
    width:100% !important;
    background: #FFFFFF;
    border: 1px solid #F5F6F7;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 5px;
    padding: 15px;
    margin-left: 20px;
    /* width: 80%; */
    text-align: justify;
}

@media screen and (max-width: 485px) {
    .message-container {
        width: 60vw !important;
    }
    .employee-icon,.client-icon{
        display:none;
    }
    .message-green{
        width: 100%;
    }
    .message-white{
        width: 100%;
        margin-left: 0px;
        margin-right: 20px;
    }

    .modal-success-messages{
        height:80vh !important;
    }
}