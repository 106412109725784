.home-container {
    background-color: white;
    width: 100%;
    height: auto;
    text-align: center;
}

.home-container section {
    background-color: white;
    margin: 0 2rem;
}

.title-home {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 39.06px;
    line-height: 61px;
    color: #29292E;
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.vertical-spacer {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 5rem;
    margin-right: 5rem;
}

.slider {
    background-size: contain;
    width: 100%
}

.why {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    margin-left: 12%;
}

.why-text {
    padding-left: 1rem;
}

.why-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
}

.testimonials-home-content {
    margin-top: 5rem;
    background: rgba(255, 246, 240, 0.7);
}

@media screen and (max-width: 700px) {
    .why-group {
        display: inline-block;
    }

    .title-home {
        font-family: 'Recline Medium';
        font-weight: 500;
        font-size: 28px;
        line-height: 28px;
    }
}

@media screen and (max-width: 475px) {
    .title-home {
        font-family: 'Recline Medium';
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
    }
}
