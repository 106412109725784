.bf-promotions-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.bf-promotions-title-wrapper h2 {
  font-family: "Recline Medium";
  font-size: 2.625rem;
  line-height: 2.625rem;
  color: rgba(241, 246, 236, 1);
}

.bf-promotions-title-wrapper p {
  font-family: "Montserrat";
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: rgba(241, 246, 236, 1);
}

@media (width > 37.5rem) {
  .bf-promotions-title-wrapper {
    gap: 2.5rem;
  }

  .bf-promotions-title-wrapper h2 {
    font-size: 4rem;
    line-height: 4rem;
  }

  .bf-promotions-title-wrapper p {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

@media (width > 62.5rem) {
  .bf-promotions-title-wrapper {
    gap: 1.5rem;
  }

  .bf-promotions-title-wrapper h2 {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}
