.accordion-description-panel .Mui-expanded {
    box-shadow: none;
    margin: 0px;
}

.accordion-description-panel .Mui-expanded h6 {
    color: #29292E;
}

.title-accordion h6 {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 25px;
    line-height: 39px;
    color: rgba(41, 41, 46, 0.5);
    margin-left: 1rem;
}

.description-accordion p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.03em;
    color: #29292E;
    margin-right: 1rem;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 1rem 0 !important;
    background: #FFFFFF;
    border-top: 4px solid #BAD0A2;
    box-shadow: 0px 4px 4px #BAD0A2 !important;
    border-radius: 11px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    margin: 1rem 0 !important;
    /* margin: 0px !important; */
    /* box-shadow: none !important; */
}

.accordion-description-panel {
    border: 0px solid #B1B2B2;
    background: #FFFFFF;
    /* color/base/lineas */
    border-top: 1px solid #B1B2B2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 11px !important;
    margin: 1rem 0 !important;
}

.accordion-description-panel.Mui-expanded {
    border: 0px solid #B1B2B2;
    background: #FFFFFF;
    border-top: 4px solid #BAD0A2;
    box-shadow: 0px 4px 4px #BAD0A2 !important;
    border-radius: 11px !important;
    margin: 1rem 0 !important;
}

.first-accordion {
    /*  border-bottom: none; */
}

.description-accordion {
    padding: 2px 16px 16px 16px !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    background-color: rgb(0 0 0 / 0%) !important;
}

.accordion-description-panel .Mui-expanded img {
    opacity: 1;
}

.accordion-description-panel img {
    opacity: 0.5;
}

.accordion-description-panel svg {
    color: #B1B2B2;
}

.accordion-description-panel .Mui-expanded svg {
    color: #29292E;
}



@media only screen and (max-width: 768px) {

    .accordion-details {
        width: 100%;
        padding-top: 0;
        margin-top: -1rem;
        padding-bottom: 0;
    }

    .accordion-details h1 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
    }

    .title-accordion h6 {
        font-size: 20px;
        line-height: 24px;
        margin-left: 1rem;
        text-align: left;
    }


    .description-accordion p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }


}
