.bf-hero-cta {
  width: 100%;
  height: 3.5rem;
  border-radius: 6.25rem;
  padding: 1.25rem;
  font-family: "Montserrat SemiBold";
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-decoration: none;
  background-color: #cf9758;
  color: #f9fafa;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (width > 37.5rem) {
  .bf-hero-cta {
    width: 28.5625rem;
    padding: 1.5rem 4rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

@media (width > 62.5rem) {
  .bf-hero-cta {
    width: 30.5625rem;
    padding: 1.5rem 5rem;
  }
}
