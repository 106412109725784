.plans-subscriptions-container {
    background-color: white;
    width: 100%;
    height: auto;
    text-align: center;
}

.plans-subscriptions-text {
    margin-bottom: 3rem;
}

.order-history-pagination {
    display: flex;
    align-items: center;
    color: black;
    margin-bottom: 4rem;
}

.plans-subscriptions-text h1 {
    font-family: 'Recline Regular';
    font-size: 32px;
    line-height: 36px;
    text-transform: uppercase;
    margin-top: 96px;
    color: #29292E;

}
@media (max-width:480px){
    .plans-subscriptions-text h1{
        width: 213px;
        margin: auto;
    }
}
.plans-subscriptions-text h2 {
    width: 80%;
    margin: 0 auto;
    font-family: 'Montserrat';
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #29292E;
}

.active-subscriptions h2 {
    font-family: 'Recline Regular';
    font-size: 25px;
    line-height: 30px;
    text-align: left;
    color: #29292E;
    /* margin-bottom: 1rem; */
}

.subscription-card-desktop {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 8px;
    height: 125px;
    box-shadow: 0px 8px 36px #e8e7e7;
    border-radius: 10px;
    margin-bottom: 2.5rem;
}

.subscription-card-mobile {
    box-sizing: border-box;
    padding: 8px;
    /* height: 130px; */
    box-shadow: 0px 8px 36px #e8e7e7;
    border-radius: 10px;
    margin-bottom: 2.5rem;
}

.subcsription-img img {
    width: 125px;
    height: 105px;
    border-radius: 4px;
}

.subscription-content {
    width: 59%;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    justify-content: center;
}

.subscription-content-mobile {
    color: #29292E;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscription-content h2 {
    font-family: 'Recline Medium';
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    color: #29292E;
}

.subscription-content p {
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #29292E;
}

.subscription-action {
    display: flex;
    justify-content: end;
    width: 25%;
    align-items: center;
}

.ssubscription-action-mobile {
    display: flex;
    justify-content: end;
    width: 100%;
    align-items: center;
}

.subscription-action button, .subscription-action-mobile button {
    margin-top: 0.5rem;
    width: 169px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #D0021B;
    border-radius: 5px;
    font-family: 'Montserrat SemiBold';
    font-size: 11px;
    line-height: 13px;
}

.order-history {
    color: #29292E;
    text-align: left;
    /* margin-bottom: 3rem; */
    margin-bottom: 59px;
}

.order-history h2 {
    font-family: 'Recline Regular';
    font-size: 25px;
    line-height: 30px;
    text-align: left;
    color: #29292E;
    margin-bottom: 2.5rem;
}

.order-history-options {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
}

.order-history p {
    cursor: pointer;
    font-family: 'Montserrat SemiBold';
    font-size: 20px;
    line-height: 30px;
    width: 200px;
    text-align: center;
    color: #29292E;
    opacity: 0.5;
    border-bottom: none;
}

.order-history p.active-option {
    opacity: 1;
    border-bottom: 2px solid #BAD0A2;
}

.order-history table {
    width: 100%;
    border-collapse: collapse;
    /* margin-bottom: 2rem; */
}

.order-history table th {
    font-family: 'Montserrat SemiBold';
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    color: #29292E;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #B1B2B2;
}

.order-history table td {
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #29292E;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 0;
    border-bottom: 1px solid #B1B2B2;
}

.subscriptions-card-not-active{
    width: 100%;
    margin-top: 17.56px;
    }
    .sub-not-active-box{
        position: relative;
        height: 66.13px;
    }
.subscriptions-card-not-active h2{
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
}

@media screen and (max-width: 992px) {
    .subscription-content {
        width: 54%;
        padding-left: 1.5rem;
    }
}

@media screen and (min-width: 768px) {
    .plans-subscription-table-only-mobile {
        display: none;
    }

    .plans-subscription-table-only-desktop {
        display: table;
    }

    .plans-subscription-card-only-mobile {
        display: none;
    }

    .plans-subscription-card-only-desktop {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .order-history h2 {
        font-size: 14px !important;
        font-family: 'Montserrat SemiBold';
        line-height: 20px;
        margin-bottom: 1rem;
    }

    .order-history p {
        font-size: 16px;
        font-family: 'Recline Regular';
        line-height: 20px;
        margin-bottom: 1rem;
    }

    .order-history {
        margin-bottom: 2rem;
    }

    .order-history-pagination span {
        display: none;
    }

    .order-history-pagination {
        margin-bottom: 2rem;
    }

    .plans-subscriptions-text h1 {
        font-family: 'Recline Regular';
        font-size: 24px;
        line-height: 28px;
    }

    .plans-subscriptions-text h2 {
        width: 94%;
        margin: 0 auto;
        font-family: 'Montserrat';
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #29292E;
    }

    .plans-subscription-table-only-mobile {
        display: flex;
        flex-direction: column;
    }

    .plans-subscription-table-only-desktop {
        display: none;
    }

    .plans-subscription-card-only-mobile {
        display: flex;
        flex-direction: column;
    }

    .plans-subscription-card-only-desktop {
        display: none;
    }

    .active-subscriptions h2 {
        font-size: 14px;
        font-family: 'Montserrat SemiBold';
        line-height: 18px;
    }

    .subscription-modality {
        font-size: 11px;
        line-height: 14px;
        text-align: left;
        font-family: 'Montserrat';
    }

    .subscription-renovation {
        font-size: 11px;
        line-height: 14px;
        text-align: left;
        font-family: 'Montserrat';
        /* margin-bottom: 0.6rem !important; */
    }

    .subscription-state {
        font-size: 11px;
        line-height: 14px;
        text-align: left;
        font-family: 'Montserrat';
        margin-bottom: 0.6rem !important;
    }

    .tu-plan-de-dieta {
        font-size: 16px !important;
        line-height: 24px !important;
        margin-top: 0.2rem !important;
        margin-bottom: 0.2rem !important;
        font-family: 'Recline SemiBold' !important;
    }

    .table-card-mobile {
        box-sizing: border-box;
        padding: 8px;
        height: 150px;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid #B1B2B2;
    }

    .subscription-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px !important;
        line-height: 18px !important;
        margin-bottom: 0.8rem;
    }

    .subscription-data-header {
        width: 45% !important;
        font-family: "Montserrat Bold" !important;
        opacity: 1 !important;
        color: #29292E !important;
        font-size: 12px !important;
        line-height: 18px !important;
        text-align: start !important;
        margin-bottom: 0 !important;
    }

    .subscription-data-content {
        font-family: "Montserrat" !important;
        padding-left: 8px !important;
        justify-content: start;
        width: 55% !important;
        color: #29292E !important;
        font-size: 12px !important;
        line-height: 18px !important;
        text-align: start !important;
        opacity: 0.8 !important;
        margin-bottom: 0 !important;
    }
}
.change-payment-method{
    margin-bottom: 66px;
    text-align: right;
    color: #29292E;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 200% */
    text-decoration-line: underline;
}
@media (max-width:360px){
    .change-payment-method{
        margin-bottom: 36px;
        text-align: right;
        color: #29292E;
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 200% */
        text-decoration-line: underline;
    }  
}