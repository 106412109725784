.title_cites h1 {
  font-family: "Recline Regular";
  font-size: 28px;
  line-height: 50px;
  color: #29292E;
}

.title_cites {
  background: #e7fad2;
  mix-blend-mode: normal;
  border-radius: 20px;
  max-width: 920px;
  width: 100%;
  margin: 5rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 2.5rem;
}

@media (max-width: 1317px) {
  .title_cites h1 {
    line-height: 40px;
    width: 100%;
  }
}

@media (max-width: 968px) {

  .title_cites h1 {
    font-size: 24px;
  }

  .title_cites {
    padding: 2rem 0.8rem;
  }
  
}

@media (max-width: 768px) {
  .title_cites {
    width: 90%;
    height: auto;
    padding: 2rem 2.5rem;
  }

  .title_cites h1 {
    font-size: 16px;
    line-height: 20px;
  }
}
