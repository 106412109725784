@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('./Montserrat-Regular.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Medium';
    src: local('Montserrat Medium'), url('./Montserrat-Medium.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat SemiBold';
    src: local('Montserrat SemiBold'), url('./Montserrat-SemiBold.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Bold';
    src: local('Montserrat Bold'), url('./Montserrat-Bold.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Recline Regular';
    src: local('Recline Regular'), url('./Recline-Regular.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Recline BoldItalic';
    src: local('Recline BoldItalic'), url('./Recline-BoldItalic.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Recline Italic';
    src: local('Recline Italic'), url('./Recline-Italic.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Recline Light';
    src: local('Recline Light'), url('./Recline-Light.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Recline Medium';
    src: local('Recline Medium'), url('./Recline-Medium.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Recline MediumItalic';
    src: local('Recline MediumItalic'), url('./Recline-MediumItalic.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Recline SemiBold';
    src: local('Recline SemiBold'), url('./Recline-SemiBold.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Recline SemiBoldItalic';
    src: local('Recline SemiBoldItali'), url('./Recline-SemiBoldItalic.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Recline LightDemo';
    src: local('Recline LightDemo'), url('./ReclineLightDemo-jEnOM.ttf') format('truetype');
    font-style: normal;
}
