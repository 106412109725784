.card_calendar {
  width: 70%;
  height: 20% !important;
}

.text_dates {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 2%;
  background: #f5f5f5;
}

.calendar_dates_loading{
  margin-right: 140px;
}

.avatar_calendar {
  width: 80px;
  height: 80px;
  margin-top: 10px;
}

.text_dates_div {
  display: flex;
}

.arrow_text {
  width: 8px;
  height: 4px;
}





.text_dates_img_container {
  display: flex;
  justify-content: end;
}

.title_calendar {
  max-width: 388px;
  font-size: 31px;
  line-height: 36px;
  font-family: "Recline Regular";
  color: #29292e;
  text-align: center;
  margin: 1rem auto 1.1rem;
}

.subtitle_calendar {
  width: 65%;
  font-family: "Montserrat";
  max-width: 600px;
  font-size: 16px;
  line-height: 28px;
  margin: auto auto 3rem;
  font-weight: 400;
  text-align: center;
}

.card_calendar_main {
  margin-top: auto;
  margin-right:auto;
  margin-left:auto;
  margin-bottom: 15% !important;
  height: fit-content;
  /* width: 70%; */
  max-width: 800px;
  flex-direction: row;
  box-sizing: border-box;
  padding: 8px;
  box-shadow: 0px 8px 36px #e8e7e7;
  border-radius: 10px;
  display: flex;
  padding: 1rem;
  margin-top: 3.5rem;
}

.calendar-content {
  margin: auto;
  width: 70%;
  max-width: 700px;
}

.info_dates {
  position: relative;
  left: 1rem;
}

.info_dates b {
  font-family: "Montserrat SemiBold";
  font-size: 12px;
  line-height: 20px;
  color: #29292e;
}

.info_dates span {
  font-size: 12px;
  line-height: 20px;
  color: #29292e;
}

.text_dates p {
  width: 100% !important;
  text-align: left !important;
  opacity: 1 !important;
  border-bottom: none;
  font-size: 11px !important;
  line-height: 18px !important;
  color: #29292e;
  margin-bottom: 1rem;
  font-family: "Montserrat" !important;
}

.couple-icon-container{
  display:flex;
}

.couple-icon{
  width: 32px;
  height: 32px;
}

.dates_container {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-top: 6%;
}

.container_calendar_general {
  display:flex;
  flex-direction:column;
  width: 90%;
  margin: auto auto 4rem;
}

.calendar-card-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  justify-content: center;
}

.calendar-card-content p {
  width:auto !important;
  text-align:left !important;
  font-size: 14px !important;
  line-height: 20px !important;
  opacity:1 !important;
  font-family: 'Montserrat', sans-serif !important;
}

.calendar-card-action {
  display: flex;
  justify-content: end;
  width: 30%;
  align-items: center;
}

.calendar-card-action button {
  margin-top: 0;
  width: 169px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #d0021b;
  border-radius: 5px;
  font-family: "Montserrat SemiBold";
  font-size: 11px;
  line-height: 13px;
}

.info_text_calendar {
  margin: 2% auto auto;
  font-size: 10px;
  width: 98%;
}

.subscription-card {
  margin-bottom: 0rem !important;
}

.arrow_calendar {
  margin-left: 27px;
}

.text_dates_calendar {
  display: flex;
  justify-content: row;
}

.cancel_calendar {
  position: relative;
  top: 88%;
  width: 12px;
  height: 12px;
}

.card-mi-cita {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 1.5rem;
  height: 125px;
  box-shadow: 0px 8px 36px #e8e7e7;
  border-radius: 10px 10px 0 0;
}

.mi-cita-instruc-link>a{
  color: blue;
}

.mailVikika{
  color:blue;
}
.employee-icon{
  padding-top:10px;
}

.modal-redirect-success{
  text-align:center;
}
.modal-redirect-success-button{
  padding: 12px 50px !important;
}

.css-i7y1zb-MuiStack-root {
  overflow:auto;
}

.css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition{
  overflow-y:auto;
}

@media screen and (min-width: 1180px) and (max-width: 1287px){
  .order-history {
    display:flex;
    flex-direction: column;
    align-items:center;
    padding-left: 2rem;
  }
}

@media (max-width: 768px) {

  .container_calendar_general {

  }

  .text-monserrat {
    font-family: "Montserrat SemiBold" !important;
    font-size: 14px;
    line-height: 20px;
  }

  .card_calendar_main {
    width: 80%;
    flex-direction: column;
  }

  .card_calendar_main div {
    width: 100% !important;
  }

  .title_calendar {
    font-size: 20px;
    line-height: 24px;
  }

  .subtitle_calendar {
    width: 96%;
    font-size: 12px;
    line-height: 18px;
    margin: auto auto 2rem;
  }

  .card_calendar {
    width: 100%;
  }

  .text_dates {
    width: 100%;
  }

  .calendar-card-content p {
    font-size: 12px !important;
    line-height: 18px !important;
    text-align: center !important;
  }

  .calendar-card-action {
    justify-content: center;
  }

  .calendar-card-action button {
    width: 85%;
  }

  .calendar-content {
    width: 92%;
  }

  .calendar-options {
    margin-bottom: 0;
  }

  .couple-icon{
    width: 22px;
    height: 22px;
  }
}


@media screen and (max-width: 520px){
  /* .modal-success-container .modal-success {
    height: 40vh;
  } */

  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 90% !important;
  }

  .calendar_dates_loading{
    margin-right: 0px;
  }
  
  .avatar_calendar {
    width: 60px;
    height: 60px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 376px){
  .modal-redirect-success>div>img{
    margin-right: -1.0rem;
  }
  .modal-redirect-success-button{
    padding: 6px 50px !important;
  }
}
@media (max-width:360px){
  .videocall-cancelation h1{
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }
  .videocall-cancelation p{
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }

}
