.method-title {
    text-align: center;
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 25px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #29292E;
  }

  .red {
    color: red;
  }

  .stripe-text {
    color: rgb(109, 110, 120);
    font-size: 12px !important;
    line-height: 17px !important;
    font-family: '-apple-system', 'system-ui', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', 'sans-serif' !important;
    font-weight: 400 !important;
  }

.method-form-container{
  
  color: #29292E;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 2rem 0;
  padding-bottom: 2.5rem;
  border-left: 0.1rem solid  #D8D8D8; 
  border-right: 0.1rem solid  #D8D8D8; 
  border-bottom: 0.1rem solid  #D8D8D8; 
  border-top: 0.1rem solid  #BAD0A2;
  border-radius: 0.9rem 0.9rem 0.5rem 0.5rem;
}

.method-form-container-header{
  height: 1rem;
  background-color:#BAD0A2 ;  
  border-top-right-radius: 0.9rem;
  border-top-left-radius: 0.9rem;
}

.form-method {
    display:flex;
    flex-direction: column;    
      
    background-color: #dfd9d9;
    width: 75%;
  padding: 5rem 3rem;
  margin: 2.5rem auto 2rem;
}
.method-form-bottom{
  display:flex;
    flex-direction: column;    
    height: auto;
  margin: 1.5rem 0 0;
  font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-align: left;
}
.method-form-bottom-policy{
  margin: 1.5rem 0 1.5rem;
  padding: 0 0rem;
  
} 
.method-form-bottom-policy label {
  display:flex;
  flex-direction: row;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1rem;
  text-align: left;
}
.red {
  color: red;
}



.space {
  padding-left: 0.5rem;
}

.payment-button{
  width: 100%
}

@media only screen and (max-width: 496px) {
  .form-method {    
    width: 90%;    
    padding: 1.2rem;
    margin-bottom: 2rem;
  }

  .method-form-bottom {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 90%;
    margin: 1.5rem 0 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    text-align: left;
  }

.method-form-bottom-policy {
  width: 90%;
  margin: 1.5rem 0 1.5rem;
  padding: 0 0rem;
  
}
.space {
  font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    text-align: left;
    color: #29292E;
    padding-left: 1rem;
}
.method-form-container {
  color: #29292E;
  margin-bottom: 0;
  background: #FFFFFF;
  box-shadow: none; 
  padding-bottom: 2.5rem;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
  border-radius: unset;
}
.method-form-container-header {
  height: 0; 
  background-color: unset;
  border-top-right-radius: unset;
  border-top-left-radius: unset;
}
.method-title {
  text-align: center;
  font-family: 'Recline Medium';
  font-weight: 500;
  font-size: 24px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #29292E;
}

  .stripe-text {
    color: rgb(109, 110, 120);
    font-size: 12px !important;
    line-height: 17px !important;
    font-family: '-apple-system', 'system-ui', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', 'sans-serif' !important;
    font-weight: 400 !important;
  }
}
