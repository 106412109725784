/* Contenedor principal del radio y el texto */
.radio-container.bf-radio {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

/* Input radio oculto */
.bf-radio .radio-input {
  display: none;
}

/* Estilos del círculo personalizado del radio */
.bf-radio .radio-custom {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid rgba(115, 133, 140, 1);
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Estilo para el radio cuando está seleccionado */
.bf-radio .radio-input:checked + .radio-custom {
  background-color: transparent;
  border-color: rgba(186, 208, 162, 1);
}

/* Punto interior del radio cuando está seleccionado */
.bf-radio .radio-input:checked + .radio-custom::after {
  content: "";
  width: 9px;
  height: 9px;
  background-color: rgba(186, 208, 162, 1);
  border-radius: 50%;
}

/* Estilo del texto */
.bf-radio .radio-label {
  font-family: "Montserrat";
  font-size: 1rem;
  line-height: 1.2rem;
  color: rgba(0, 0, 0, 1);
}

@media (width > 37.5rem) {
  .bf-radio .radio-label {
    font-size: 1.125rem;
    line-height: 1.35rem;
  }
}
