.begin-form .food-fields{
    display: flex;
    justify-content: space-between;
}

.begin-form .food-groups{
    display: grid;
    position: relative;
    width: 49%;
}

.food-groups .data-label{
    margin-bottom:0px;
}

.feeding-data-header-one{
    font-family:Montserrat;
    font-weight:400;
}

.feeding-data-header-two{
    margin-top: 0px !important;
    margin-bottom: 35px !important;
}


@media(max-width:768px){

    .begin-form .food-fields{
        display: block;
        margin-bottom: 0px;
    }
    .food-fields input[type=text]{
        font-size: 12px;
    }

    .begin-form .food-groups{
        display: grid;
        position: relative;
        width: 100%;
    }

    .food-groups .data-label{
        margin-top: 0rem;
    }

    .feeding-data-header-two {
        margin-bottom: 20px !important;
    }
}