.title_video {
  font-family: "Recline Medium";
  font-size: 25px;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 3rem;
  color: #29292E;
}

.container_video h3 {
  margin-bottom: 4%;
  margin-left: 6%;
  font-family: 'Montserrat SemiBold';
  font-size: 16px;
  line-height: 20px;
  color: #29292E;
}

.container_video p {
  margin-bottom: 7%;
  margin-left: 6%;
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 16px;
  color: #29292E;
}

.info_video_text {
  text-align: justify;
  width: 90%;
}

.button_video {
  width: 242px !important;
  height: 50px !important;
  border-radius: 5px !important;
  margin-top: 0rem;
}
.videocall-buttons-container{
  margin-bottom: 59px;
}
@media (max-height:600px){
.MuiDrawer-paperAnchorRight{
  height: 90% !important;
}
}


.button_video_call {
  display: flex;
  justify-content: center;
}

.final_text_video {
  text-align: center;
  margin-top: 6% !important;
  font-family: 'Montserrat';
  font-size: 16px !important;
  line-height: 20px !important;
  text-decoration-line: underline;
  color: #29292E;
  cursor: pointer;
}
.container_video{
  height: 90%;
}


@media (max-width: 768px) {
   .container_video{
    height: 95%;
  } 

}