.bf-hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.bf-hero-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

.bf-hero-marquee-wrapper {
  width: 100%;
  background-color: rgba(10, 13, 11, 1);
  display: flex;
  justify-content: center;
  flex-grow: 0;
}

.bf-hero-logo-container {
  width: 100%;
  max-width: 80rem;
  height: 2.5rem;
  padding-block: 0.125rem;
  padding-inline: 1.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
  z-index: 1;
}

.bf-hero-content {
  width: 100%;
  max-width: 80rem;
  padding-block: 0.125rem;
  padding-inline: 1.25rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  flex-grow: 1;
  z-index: 1;
}

.bf-hero-title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.bf-hero-title,
.bf-hero-title-subtitle {
  color: rgba(249, 250, 250, 1);
}

.bf-hero-title {
  font-family: "Montserrat Bold";
  font-size: 4rem;
  line-height: 3.2rem;
}
.bf-hero-title-subtitle {
  font-family: "Recline Medium";
  font-size: 2.625rem;
  font-weight: 500;
  line-height: 2.625rem;
}

@media (width > 37.5rem) {
  .bf-hero-logo-container {
    height: 4rem;
    padding-block: 0.5rem;
    padding-inline: 4rem;
  }

  .bf-hero-content {
    padding-inline: 4rem;
    gap: 2.5rem;
  }

  .bf-hero-title {
    font-size: 5rem;
    line-height: 4rem;
  }

  .bf-hero-title-subtitle {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}

@media (width > 62.5rem) {
  .bf-hero-logo-container {
    height: 5rem;
    padding-block: 0.75rem;
    padding-inline: 5rem;
  }

  .bf-hero-content {
    padding-inline: 5rem;
    gap: 2.5rem;
  }

  .bf-hero-title {
    font-size: 8rem;
    line-height: 6.4rem;
  }

  .bf-hero-title-subtitle {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}
