.container_specialist_psychology {
  width: 70%;
  max-width: 700px;
  color: #29292e;
  margin: auto auto 6rem;
}

.title_specialist_psychology {
  text-align: center;
  font-family: "Recline Regular";
  font-size: 31px;
  margin-bottom: 51px;
  margin-top: 1rem;
}

.hints-only-desktop_psychology {
  display: block;
}

.hints-only-mobile_psychology {
  display: none;
}

@media screen and (max-width: 768px) {
  .container_specialist_psychology {
    width: 94%;
  }

  .title_specialist_psychology {
    font-size: 20px;
    line-height: 24px;
  }

  .hints-only-desktop_psychology {
    display: none;
  }

  .hints-only-mobile_psychology {
    display: block;
  }
}
