.brands-container {
    position: relative;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.brands-container img {
    filter: grayscale(100%);
    width: 70%;
}

@media only screen and (max-width: 475px) {
    .brands-container {
        padding-left: 0%;
        padding-right: 0%;
    }
}

