.interest-data-activity{
    margin-top:20px;
}

@media(max-width:768px){

    .interest-data-activity {
        margin-top: 0px; 
    }
}
