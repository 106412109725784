.bf-team-content {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 3.5rem 1.25rem 4rem;
  background-color: rgba(10, 13, 11, 1);

  max-width: none;
  min-height: auto;
  color: initial;
  border: none;
  border-radius: 0;
}

.bf-team-wrapper {
  width: 100%;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;
}

.bf-team-title {
  font-family: "Recline Medium";
  font-size: 2.625rem;
  line-height: 2.625rem;
  color: rgba(249, 250, 250, 1);
}

.bf-team-subtitle {
  font-family: "Montserrat";
  font-size: 1rem;
  line-height: 1.2rem;
  color: rgba(249, 250, 250, 1);
}

@media (width > 37.5rem) {
  .bf-team-content {
    padding: 4rem 4rem 5rem;
  }

  .bf-team-title-wrapper {
    gap: 1rem;
  }

  .bf-team-title {
    font-size: 4rem;
    line-height: 4rem;
  }

  .bf-team-subtitle {
    font-size: 1.125rem;
    line-height: 1.35rem;
  }
}

.bf-team-slider {
  width: 100%;
  padding-bottom: 3.75rem;
}

.bf-team-slider .slick-list {
  overflow: visible;
}

.bf-team-slider .slick-prev,
.bf-team-slider .slick-next {
  z-index: 500;
  bottom: -40px;
  top: auto;
  left: 50%;
  right: auto;
  margin: 0;
  width: 60px;
  height: 60px;
}
.bf-team-slider .slick-prev {
  transform: translate(-150%, 100%);
}
.bf-team-slider .slick-next {
  transform: translate(50%, 100%);
}

.bf-team-slider .slick-prev:before,
.bf-team-slider .slick-next:before {
  font-size: 60px;
}

@media (width > 62.5rem) {
  .bf-team-content {
    padding: 5rem 5rem 6rem;
  }

  .bf-team-title {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}

@media (width > 2000px) {
  .bf-team-slider .slick-list::after,
  .bf-team-slider .slick-list::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 50vw;
    z-index: 100;
    pointer-events: none;
  }
  .bf-team-slider .slick-list::after {
    right: 0;
    transform: translateX(100%);
    background: linear-gradient(-90deg, rgba(10, 13, 11, 1) 60%, rgba(255, 255, 255, 0) 100%);
  }
  .bf-team-slider .slick-list::before {
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg, rgba(10, 13, 11, 1) 60%, rgba(255, 255, 255, 0) 100%);
  }
}
