.container-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container-reset input {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #29292E;
  outline: none;
}

.container-reset .form {
  width: 400px;
}

.container-reset > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-reset .error {
  color: red;
  font-size: 12px;
  line-height: 18px !important;
  display: block;
  margin-top: -2rem;
  margin-bottom: 1rem
}

.container-reset button.custom-button {
  width: 220px;
  height: 46px;
  margin: 10% auto auto;
  font-family: 'Montserrat SemiBold';
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: #BAD0A2;
  cursor: pointer !important;
  border-width: 0;
  line-height: 1.5rem;
}

.container-reset .title {
  font-family: 'Recline Regular';
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 5rem;
}

.container-reset label {
  color: #29292E;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
}
