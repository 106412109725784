.css-j204z7-MuiFormControlLabel-root{
    width:110%;
}

.training-option-label .MuiFormControlLabel-label{
    font-size:14px;
}
.psycho-bono-labels{
    font-size: 14px;
    font-weight: 400;
    color: #29292E;
}
.psycho-bono-label-price{
    font-size: 14px;
    font-weight: 600;
    color: #29292E !important;
}
.psycho-bono-label-reduced-price{
    font-size: 12px;
    font-weight: 600;
    color: #B1B2B2 !important;
}