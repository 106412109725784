html {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #fff;
}

.login-form label {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 20px;
}

body {
  background-color: #FFFF;
}

.login-area {
  width: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0rem;
  background-color: #FFFFFF;
  color: #29292E;
  margin-top: 30%;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
}

a,
a:visited {
  color: #29292E;
}

.login-form input,
.login-form textarea {
  background-color: #fff;
  /* Fondo blanco */
  border: none;
  /* Borde vacío */
  border-bottom: 0.1rem solid #29292E;
  ;
  /* Línea inferior */
  padding: 5px;
  /* Espacio interno */
  outline: none;

}

.login-container .instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #29292E;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions>svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.login-logo {
  height: auto;
  width: 75%;
}

.centered {
  text-align: center;
  color: #29292E;
}

.btn-login {
  font-family: 'Montserrat SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14.63px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #BAD0A2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 40.8px;
  width: 206.1px;
  margin-top: 3rem;
}

.icon {
  margin-left: 10px;
}

.forgot-pass .normal a {
  font-family: 'Montserrat';
  text-decoration: none;
  cursor: pointer;
  color: rgba(11, 73, 235, 0.99) !important;
  border-bottom: 1px solid rgba(11, 73, 235, 0.99);
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0
}

.red {
  color: red;
}

.login-title {
  margin-bottom: 1.5rem;
  font-family: 'Recline Medium';
  font-size: 2rem;
  text-align: center;
}

.login-container .forgot-pass {
  margin-top: -2rem;

}

.login-container {
  display: flex;
  flex-direction: row;
}

.side-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 70%;
  height: auto;
}

.policy-container {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: 'Recline Regular';
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #BAD0A2;
}

.side-1 img {
  width: 100%;
  height: auto;
}

.side-1 .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #29292E;
  mix-blend-mode: multiply;
  opacity: 0.3;
}

.side-2 {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.login-form .info img {
  height: 14px;
}

.container-email-forgot-password {
    width: 70%;
    margin-left: 10%;
    margin-bottom: 1.5rem;
}

.container-email-forgot-password label {
  font-family: 'Montserrat SemiBold';
  margin-left: 1rem;
}

.email-forgot-password {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  background-color: white !important;
  width: 80%;
  box-sizing: border-box;
  border: 0.1rem solid #E5E5E5;
  border-radius: 0.5rem;
  height: 32px !important;
  min-height: 32px !important;
  padding-left: 1rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #757575;
  outline: none;
}

@media screen and (max-width: 992px) {
  .container-email-forgot-password {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 1.5rem;
  }

  .email-forgot-password {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    background-color: white !important;
    width: 100%;
    box-sizing: border-box;
    border: 0.1rem solid #E5E5E5;
    border-radius: 0.5rem;
    height: 32px !important;
    min-height: 32px !important;
    padding-left: 1rem;
    margin-left: 0 !important;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    outline: none;
  }

  .login-container {
    flex-direction: column;
  }
  .login-logo{
    margin-top: 70px;
  }
  .login-logo {
    position: fixed;
    width: 50%;
    left: 25%;
    top: 3rem;
  }

  .centered {
    margin-top: -8rem;
    margin-bottom: 15rem;
  }

  .side-1 {
    width: 100%;
    display: none;
  }

  .side-2 {
    background-image: url('../../../assets/img/img-login-mobile.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-title {
    font-weight: 500;
    font-size: 25px;
    line-height: 39px;
  }

  .side-1 img {
    width: 100%;
    height: auto;
  }

  .login-area {
    margin-top: 5%;
    background-color: transparent;
    margin: 0 3rem;
  }

  .login-form label {
    font-weight: 500;
    font-size: 15px !important;
    line-height: 20px;
    font-family: 'Montserrat SemiBold';
  }

  .policy-container {
    display: none;
  }

  .login-form input,
  .login-form textarea {
    background-color: transparent;
  }

  .forgot-pass .normal a {
    font-weight: 600;
    font-size: 10.24px;
    line-height: 12px;
  }
}

@media (max-height: 720px) {
  .login-logo {
    top: 0rem;
    margin-top: 0px;
  }
}

@media (max-height: 670px) {
  .login-logo {
    top: 3rem;
    margin-top: 20px;
  }
}

@media (max-height: 500px) {
  .login-logo {
    top: 3rem;
    margin-top: 0px;
  }
}
