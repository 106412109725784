.questions-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(186, 208, 162, 0.7);
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 3%;
    padding-right: 7%;
}

.questions-container-private{
    display: flex;
    flex-direction: row-reverse;
}

.side-questions-a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.side-questions-b {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}


.questions-container button {
    background: #F6FFEA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 2rem 0;
    padding: 1rem 2rem;
}

.questions-text-container h2 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 31.25px;
    line-height: 48px;
    text-align: left;
    text-transform: uppercase;
    color: #29292E;
    margin-right: 1rem;
}

.button-message p {
    font-family: 'Montserrat Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #29292E;
}

.social-row {
    display: flex;
    align-content: center;
}

.spacer-social {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.side-questions-b p {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    color: #29292E;
}

.facebook1,
.instagram1 {
    margin-right: 2rem;
}

@media (max-width: 768px) {
    .questions-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        justify-content: space-between;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 7%;
        padding-right: 7%;
    }

    .side-questions-a {
        display: flex;
        flex-direction: column;
    }

    .side-questions-a button {
        margin: 1rem;
    }

    .questions-text-container h1 {
        margin-bottom: 0;
    }

    .questions-text-container h1 {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 31px;
        font-family: 'Recline Medium';
    }

    .button-message p {
        font-size: 10px;
        line-height: 10px;
    }

    .side-questions-b p {
        font-family: 'Recline Medium';
        font-size: 16px;
        line-height: 25px;
    }
}
