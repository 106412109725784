.bf-vital-wellness-container {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 3.5rem 1.25rem 4rem;
}

.bf-vital-wellness-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../../../assets/blackfriday/vital-wellness-mobile.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: 1;
}

.bf-vital-wellness-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(16, 37, 46, 0.7); /* Cambiar color o ajustar su opacidad  */
  z-index: 2;
}

.bf-promotions-content {
  position: relative;
  width: 100%;
  max-width: 80rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  z-index: 100;
}

.bf-promotions-subcontent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media (width > 37.5rem) {
  .bf-vital-wellness-container {
    padding-inline: 5.4375rem;
  }
  .bf-vital-wellness-container::before {
    background-image: url("../../../../../assets/blackfriday/vital-wellness-desktop.jpg");
  }
}

@media (width > 62.5rem) {
  .bf-vital-wellness-container {
    padding-inline: 5rem;
  }

  .bf-promotions-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2.5rem;
  }

  .bf-promotions-subcontent {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  .bf-promotions-title-subcontent,
  .bf-promotions-subcontent {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
  }
}
