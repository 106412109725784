.home-private-container {
    background-color: white;
    width: 100%;
    height: auto;
    text-align: center;
    padding-bottom: 48px;

}
#rouge{
    background-color: #F3E4DC;
}
.circular-bell-image{
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.circular-bell-image img {
    /* Establece la posición inicial de la imagen */
    position: relative;
    top: 0;
    left: 0;
    transform-origin: bottom center;
  }
  
  /* Define la animación utilizando @keyframes */
  @keyframes bellSwing {
    0% {
        opacity: 0%;
      transform: rotate(0deg);
    }
    25% {
        opacity: 50%;

      transform: rotate(15deg);
    }
    /* 50% {
      transform: rotate(0deg);
    } */
    75% {
        opacity: 75%;

        transform: rotate(-15deg);
      }
      100% {
        opacity: 100%;

        transform: rotate(0deg);
      }
  }
  
  /* Aplica la animación a la imagen */
  .circular-bell-image img{
    animation: bellSwing 1s;
    transform-origin: top center;
  }
.notification-content-subtitle a{
    text-decoration: none;
    color: #1b55ea;
    font-weight: bold;
}
.is-couple{
    position: absolute;
margin-top:0.5vh;
right: 0.5vw;
}
.is-couple img{
    width: 38px;

}
.home-private-msg {
    position: relative;
    background-color: rgba(231, 250, 210, 0.7);
    mix-blend-mode: normal;
    margin-bottom: 1rem;
    margin: auto;
    width: 70%;
    max-width: 699px;
    border-radius: 10px;
}
.suscription_psycho_msg span{
    font-size: 13.36px;
    font-family: 'Recline Medium';

}
.suscription_psycho_msg{
    position: absolute;
    margin-top: 0px;
    top: 10px;
    margin-bottom:14px ;
    width: min-content;
    padding-left: 4px;
    padding-right: 4px;
    background-color: rgba(255, 246, 240, 1);
    padding-bottom: 2px;
    padding-top: 2px;
    border-radius: 3px;
    box-shadow: 0 3px 3px -4px grey;
}

.home-private-others-products {
margin: auto;    margin-top: 5rem;
    width: 100%;
    max-width:699px;
}

.notification-content-title {
    font-family: 'Recline Medium';
    font-size: 31px;
    font-weight: 500;
    line-height: 40px;
}

.notification-content-subtitle {
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 20px;
}

.home-private-msg h2 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 31.25px;
    line-height: 36px;
    text-align: center;
    color: #29292E;
}

#lightrouge{
    background-color: #FFF6F0;
}
.circular-bell-bg {
    background: #F6FFEA;
    /* border-radius: 50%; */
    padding: 10px;
    width: 20%;
    border-top-left-radius: 10px;
    max-width: 112px;
    border-bottom-left-radius: 10px;
    
}
.circular-bell-bg img{
    background-color: #BAD0A2;
    height: 4rem;
    padding: 10px;
    border-radius: 50%;

}
#lightrouge img{
    background-color:#F3E4DC ;
    height: 4rem;
    padding: 10px;
    border-radius: 50%;
}
.notification-content {
    margin-left: 0px;
    text-align: left;
    padding-top: 3rem !important;
    padding-bottom: 1.5rem !important;
    width: 70%;
    padding-left: 2%;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.home-private-notice {
    margin-top: 1rem;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 160px;
}

.home-private-notice-text {
    
    margin-top: 1rem;
}

.home-private-msg h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #29292E;
}

.home-private-others-products-title h1 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 31px;
    /* line-height: 61px; */
    text-transform: capitalize;
    color: #29292E;
}

.cardmyproducts-sesion {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    margin: 0 auto;
}

.cardmyproducts-sesion h1 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 31px;
    line-height: 35px;
    text-transform: uppercase;
    color: #29292E;
    margin: 3rem 0rem;
    text-align: left;
}

.home-private-notice-text button {
    background-color: #bad0a200;
    border: 1px solid #29292E;
    border-radius: 5px;
    width: 250px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #29292E;
    padding: 1rem 0rem;
    margin-top: 3rem;
}
@media (max-width: 1300px){
    .home-private-others-products {
        /* margin-left: 1rem !important; */
        margin-top: 5rem;
        width: 80%;
    }
    .home-private-others-products-title{
line-height: 35px;    }
}
@media (max-width: 768px) {

    .content-container {
        width: 100%;
    }
    .is-couple img{
        width: 28px;
    
    }
    .home-private-others-products-title {
        margin-left: 0rem;
    }
    .home-private-msg {
        width: 95%;
        margin-left: 3%;
    }
    .home-private-others-products-title h1 {
        font-family: 'Recline Medium';
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.2px;
        /* line-height: 61px; */
        text-transform: capitalize;
        color: #29292E;
    }
.home-private-notice {
    min-height: 150px;
}

.home-private-others-products {
    margin: 2%;
    margin-top: 32px;
    width: 95%;
}

.interesting-card-only {
    max-width: 100% !important;
}
    .home-private-notice {
        /* padding-top: 2rem;
        padding-bottom: 1.5rem;  */
        flex-direction: row;
        /* align-items: center;
        justify-content: center; */
    }

    .notification-content {
            padding: 12px;
        width: 68%;
        padding-left: 2%;
    }

    .notification-content-title {
        font-size: 20px !important;
        margin-bottom: 5.57px;
        /* font-weight:  !900; */
                line-height: 24px;
    }

    .notification-content-subtitle {
        font-size: 10px;
        line-height: 14px;
    }

    #lightrouge img{
        height: 3rem;
    }
    .product-cards-containers img{
        display: none;
    }

    .circular-bell-bg img{
        height: 30px;
    }
    .circular-bell-bg img{
        padding: 5px;
    }
    #lightrouge img{
        height: 30px;
    }
    #lightrouge img{
        padding: 5px;
    }
}
@media (max-width:600px){
    .circular-bell-bg {
        max-width: 45px;
    }
    .notification-content {
    padding-left:10px;
    margin-left: 0px;

    width: 85%;

        }
        .notification-content {
            padding-left:10px;
            margin-left: 0px;
        
            width: 85%;
                }
 

   
}
@media (max-width: 370px) {

    .notification-content {
  
        padding-left:10px;
    
        margin-left: 0px;
  
        width: 80%;
 
    }
 
    .notification-content-title {
        font-size: 18px !important;
        margin-bottom: 5.57px;
        /* font-weight:  !900; */
        line-height: 24px;
    }
}

@media (max-width: 420px) {

    .notification-content{
        padding-top: 35px;
    }
  
}
@media (max-width: 600px){
    .suscription_psycho_msg{
        margin-left: 0px;
    }
}