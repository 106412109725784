.footer {
  background-color: #314037;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  width: 100%;
  font-weight: 600;
  font-style: normal;
  padding-left: 4rem;
}

.footer-col-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 1rem;
  padding-top: 2rem;
  padding-right: 4rem;
}

.footer-col {
  width: fit-content;
  text-align: left;
  margin-left: 3rem;
}

.footer-copyright {
  text-align: center;
  padding: 1rem 0;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  font-family: 'Recline Regular';
  color: #BAD0A2;
  margin-top: 2rem;
}

.copyrigth{
  color: #BAD0A2 !important;
}


.spacer-1 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.logo-footer {
  height: 75px;
  width: 182px;
  padding-left: 0;
}

.col-1-spacer {
  margin-left: 1rem;
}

.img-youtube {
  margin-right: 0.5rem;

}

.iconos-row {
  display: flex;
  align-content: center;
}

.title-help {
  font-family: 'Recline Regular';
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
}

.title-info {
  font-family: 'Recline Regular';
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
}
.footer-link-underlined{
  text-decoration: underline;
}
.spacer-2 {
  margin-top: 1.2rem;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 16px;
  color: #FFFFFF;

}

.spacer-3 {
  margin-top: 4rem;
  font-family: 'Recline Regular';
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
}
.footer-input{
  color: #858585;
font-family: Montserrat;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 5px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
width: 231.098px !important;
height: 24px;
display: flex;
justify-content: space-around;
flex-wrap: wrap;
align-content: space-between;
flex-direction: column-reverse;
flex-shrink: 0;
}
.footer-input p{
  margin-left: 13px;
}
/* .footer input[type="text"],
.footer textarea {
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-weight: 600;
  margin-top: 0;
  padding: 0.25rem;
  border-radius: 0.35rem 0 0 0.35rem;
  background-color: #FFFFFF;
  border-width: 0;
  line-height: 1.5rem;
  width: 12.5rem
} */

.footer-input {
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}

/* .btn-footer {
  width: fit-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-top: 0 !important;
  border-radius: 0 0.35rem 0.35rem 0;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
} */
.btn-footer img{
  height: 100%;
  margin-right: -10px;
}

.footer-col-centered {
  display: flex;
  justify-content: space-between;
}

.footer-col-2 {
  margin-left: 3rem;

}

.spacer-4 {
  margin-right: 6rem;
}

.suscribe-desktop-view {
  display: block;
}

.suscribe-mobile-view {
  display: none;
}

@media (max-width: 768px) {
  .footer-col-container {
    flex-direction: column;
  }

  .footer-col-centered {
    display: flex;
    flex-direction: column;
  }

  .suscribe-mobile-view {
    margin-top: 18px;
    display: block;
  }

  .suscribe-desktop-view {
    display: none;
  }

  .col-1-spacer {
    margin: 0 auto;
  }

  .footer-col-1 {
    margin-left: 0;
  }

  .footer-col-2 {
    margin-left: 0;
    padding-top: 2rem;
  }

  .footer {
    padding-left: 1.5rem;
  }

  .footer-col-centered {
    margin-top: 2rem;
  }

  .spacer-3 {
    margin-top: 2rem;
  }

  .footer-copyright {
    width: 70%;
  }

  .footer input[type="text"],
  textarea {
    font-weight: 400;
    font-size: 10px;

  }

  .title-help,
  .spacer-3,
  .title-info {
    font-size: 16px;
    line-height: 25px;
  }

}

.footer-hidden {
  display: none;
}
