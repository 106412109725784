.App {
  
}

.modal-success-container {
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  color: #29292E;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.testimonial-name {
  font-family: 'Montserrat SemiBold' !important;
  font-size: 20px;
  line-height: 26px;
  color: #29292E;
}

.modal-success-container .modal-success {
  padding: 2rem 6rem;
  border-radius: 5px;
  position: fixed;
  top: 20%;
  left: 15%;
  background: #fff;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-success-container .modal-success .modal-success-text {
  font-family: 'Montserrat';
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 1.5rem;
}

.modal-success-container .modal-success .modal-success-image {
  width: 80px;
  height: auto;
  margin-bottom: 1rem;
}

.modal-success-container .modal-success .modal-success-button {
  width: auto;
  background: #BAD0A2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Montserrat SemiBold';
  padding: 12px 30px;
  text-align: center;
}

.custom-modal-title-unified {
  font-size: 40px;
  font-family: 'Recline Regular';
  line-height: 50px;
  color: #29292E;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 1090px) and (max-width: 1366px){
  .modal-success-container .modal-success .modal-success-button {
    font-size: 12px;
  }

  .custom-modal-title-unified {
    font-size: 38px;
    line-height: 46px;
  }
}

@media screen and (min-width: 780px) and (max-width: 1090px){
  .modal-success-container .modal-success {
    padding: 2rem 3rem;
    top: 20%;
    left: 10%;
    width: 80%;
  }

  .testimonial-name {
    font-size: 18px;
    line-height: 26px;
  }

  .modal-success-container .modal-success .modal-success-image {
    width: 70px;
    margin-bottom: 0.9rem;
  }

  .custom-modal-title-unified {
    font-size: 32px;
    line-height: 40px;
  }

  .modal-success-container .modal-success .modal-success-button {
    font-size: 12px;
  }
}

@media screen and (min-width: 520px) and (max-width: 780px){
  .modal-success-container .modal-success {
    padding: 2rem 1.5rem;
    top: 15%;
    left: 4%;
    width: 92%;
  }

  .testimonial-name {
    font-size: 12.8px;
    line-height: 26px;
    margin-top: -1rem;
  }

  .modal-success-container .modal-success .modal-success-image {
    width: 60px;
    margin-bottom: 0.8rem;
  }

  .custom-modal-title-unified {
    font-size: 26px;
    line-height: 32px;
  }

  .modal-success-container .modal-success .modal-success-button {
    font-size: 12px;
  }

  .modal-success-container .modal-success .modal-success-text {
    font-size: 17px;
    line-height: 26px;
    text-align: center;
  }
}

@media screen and (max-width: 520px) {
  .modal-success-container .modal-success {
    padding: 2rem 1.5rem;
    top: 15%;
    left: 2%;
    width: 96%;
  }


  .testimonial-name {
    font-size: 12.8px;
    line-height: 26px;
    margin-top: -1rem;
  }

  .modal-success-container .modal-success .modal-success-image {
    width: 50px;
    margin-bottom: 0.7rem;
  }

  .custom-modal-title-unified {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    width: 100%;
  }

  .modal-success-container .modal-success .modal-success-text {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .modal-success-container .modal-success .modal-success-button {
    font-size: 12px;
  }

}

button{
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Montserrat SemiBold';
  color: #29292E;
}

button:disabled {
  color: rgba(16, 16, 16, 0.5);
  cursor: not-allowed;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
