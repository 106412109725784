.chat {
  background: #ffffff;
  box-shadow: 0px 4px 8px -2px #f2f4f5, 0px 2px 4px -2px rgba(17, 19, 34, 0.06);
  border-radius: 10px;
  margin: auto;
  margin-bottom: 2rem;
}

.chat-content {
  padding: 35px 35px 0;
}

.send-icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.input-content {
  display: flex;
  height: 51px;
  width: 100%;
  align-items: center;
  border: 1px solid #F5F6F7;
  border-radius: 0px 0px 10px 10px;
}

.input-message {
  width: 90%;
  border: none;
  height: 49px;
  margin-left: 20px;
  color: #b1b2b2;
}

.input-message::placeholder {
  color: #b1b2b2;
}

@media screen and (max-width: 768px) {
  .chat-content {
    padding: 15px 15px 0;
  }

  .chat {
    margin-bottom: 0;
  }
}
