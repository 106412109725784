.testimonials-container {
    background-color: white;
    width: 100%;
    height: auto;
    padding-top: 2rem;
}

.title-section-testimonials h1 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 39px;
    line-height: 50px;
    color: #29292E;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
}

.title-section-testimonials p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #29292E;
}

.plans-type-select {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
}

.plans-type-select button {
    padding: 0 3rem;
    background-color: #FFFFFF;

    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 31px;
    line-height: 48px;
    text-align: center;
    color: #29292E;
    margin-left: 1rem;
    margin-right: 1rem;
}

.testimonial-complete-container {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    background: rgba(255, 246, 240, 0.7);
    mix-blend-mode: normal;
    height: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.testimoniasl-list {
    margin: 7rem 0;
}

.testimonial-container-complete-diet {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    background: rgba(255, 246, 240, 0.7);
    mix-blend-mode: normal;
    height: auto;
    margin-top: 5vh;
    margin-bottom: 3vh;
}

.testimonial-container-complete-psico {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    background: rgb(246, 255, 234, 0.7);
    mix-blend-mode: normal;
    height: auto;
    margin-top: 5vh;
    margin-bottom: 3vh;
}

.testimonial-complete-img {
    width: 35%;
    padding-left: 8%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.description-complete-container {
    padding-left: 3%;
    padding-right: 7%;
    text-align: left;
    width: 100%
}

.testimonials-container .image-complete-post {
    width: 210px;
    height: 155px;
}

.description-complete-container h1 {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 25px;
    line-height: 26px;
    color: #29292E;
}

.description-complete-container h2 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #29292E;
}

.description-complete-container h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #29292E;
    margin: 1rem 0
}

.description-complete-container h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #29292E;
}

.subtitle-change {
     margin-left: 2rem;
 }

.description-complete-container p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #29292E;
}

.subtitle span {
    font-weight: 400;
}

.subtitle-complete {
    font-weight: bold;
}

.description-complete-container button {
    background-color: #bad0a200;
    margin-top: 0.5rem;
}

.complete-testimonial-button p {
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #29292E;
}

.icon-collapse {
    margin-left: 0.5rem;
}

.testimonials-container .active-button {
    color: #29292E;
    border-bottom: 2px solid #BAD0A2;
    border-radius: 0 0 0 0;
    display: inline-block;
}

.inactive-button {
    color: #ccc !important;
    border-bottom: none;
    border-radius: 0 0 0 0;
    display: inline-block;
}

@media (min-width: 575px) {
    .subtitle-change-goDown br {
        display: none;
    }
}

@media (max-width: 768px) {
    .title-section-testimonials h1 {
        font-size: 24px;
        line-height: 28px;
    }

    .title-section-testimonials p {
        font-size: 12.5px;
        line-height: 18px;
    }

    .testimonials-container .plans-type-select button {
        font-size: 20px;
        line-height: 20px;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        width: 48%;
    }

    .testimonials-container .active-button, .testimonials-container .inactive-button {
        padding: 0 0rem;
    }

    .subtitle-change {
        margin-left: 1rem;
    }

    .description-complete-container h1 {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0.5rem;
    }

    .description-complete-container h2,
    .description-complete-container h3 {
        font-size: 12.8px;
        line-height: 26px;
        margin: 0;
        margin-top: 0 !important;
    }

    .description-complete-container .subtitle-change {
        margin-left: 0rem;
    }

    .description-complete-container p {
        font-size: 10.24px;
        line-height: 18px;
    }

    .description-complete-container .flex-container-title-button1 {
        margin-top: 0rem;
    }

    .testimonials-container .image-complete-post {
        width: 116px;
        height: 85.02px;
    }

    .testimonial-complete-img {
        padding-left: 1rem;
    }

    .description-complete-container {
        padding-right: 3%;
    }

}

@media (min-width: 1253px) {
    .padding-image-home {
        padding-top: 3.5%;
    }
}

@media (min-width: 992px) and (max-width: 1253px)  {
    .padding-image-home {
        padding-top: 5%;
    }
}

@media (max-width: 575px) {
    .subtitle-change-goDown {
        margin-left: 0 !important;
    }
}

@media (max-width: 321px) {
    .testimonials-container .active-button {
        padding: 0 0.2rem;
    }

    .plans-type-select {
        display: flex;
        justify-content: space-around;
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    .testimonials-container .plans-type-select button {
        margin-left: 0;
        margin-right: 0;
    }

    .testimonials-container .plans-type-select button {
        font-size: 16px;
    }
}
