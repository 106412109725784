.bf-marquee-container {
  max-width: 100%;
  overflow: hidden;
  height: 2.5rem;
  background-color: transparent;
  font-family: "Montserrat";
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: rgba(207, 151, 88, 1);
  display: flex;
  align-items: center;
  white-space: nowrap;
  z-index: 1;
}

.bf-marquee-text-scrolling {
  display: inline-block;
  padding-inline: 0.5rem;
  animation: bfScroll 10s linear infinite;
  will-change: transform;
}
.bf-marquee-text-scrolling::after {
  content: "•";
  margin-left: 1rem;
}

@keyframes bfScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (width > 37.5rem) {
  .bf-marquee-container {
    height: 4rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

@media (width > 62.5rem) {
  .bf-marquee-container {
    height: 5rem;

    font-size: 1.75rem;
    line-height: 2.125rem;
  }
}
