.sport-activity{
    padding: 20px;
}

#img-fill-doubt{
    text-decoration:underline;
    display: inline-block;
}

#img-fill-doubt>img{
    cursor: pointer; 
}

.img-fill-doubt-arrow{
    width:11px !important;
    margin-left:7px !important;
}

.sport-activity h4{
    margin-bottom: 35px;
    line-height: 1.5rem;
    font-weight: 400;
}
.sport-activity p{
    font-family:Montserrat SemiBold;
    font-weight: 600;
    margin-bottom: 15px;
    padding-top: 20px;
}
.sport-activity .sport-activity-first-form{
    margin-bottom: 15px;
}
#eres_fumador p{
    font-size: 14px;
    padding-top:0px;
    margin-bottom:0px;
}
.sport-activity .sport-activity-first-form label{
    margin-left: 20px;
    font-size: 16px;
    width:32px;
    display: inline-block;
    height:32px;
    text-align: center;
    border-radius: 50%;
    padding-top: 5px;
border: 1px solid #B1B2B2;
    cursor: pointer;
}
.sport-activity .sport-activity-first-form input[type=radio]{
display: none;    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    /* Hide default radio button */
}
.sport-activity .sport-activity-first-form input[type=radio]:checked + label{
    /* Style for the checked radio button */
    background-color: #BAD0A2; /* Background color when selected */
  }
  .page-manager{
    margin-top: 24px;
    text-align: center;
  }
  .page-manager button{
    background-color: transparent;
  }
  .not-current{
    display: inline-block;
    width: 5px;
    height: 6px;
    background-color: #BAD0A2;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 10px;
    margin-bottom: 3px;
  }
  .page-manager .current{
    display: inline-block;
    border:1px solid #BAD0A2;
    width: 25px;
    height: 25px;
    line-height: 1.6rem;
    color: #BAD0A2;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 50%;
  }
  .page-manager .current-done{
    display: inline-block;
    border:1px solid #BAD0A2;
    width: 25px;
    height: 25px;
    line-height: 1.6rem;
    color: #ffff;
    background-color:  #BAD0A2;
    border-radius: 50%;
    margin-right: 15px;
    margin-left: 15px;
  }
  .page-manager .rotate-arrow{
    -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg);        /* FF */
    -o-transform: rotate(180deg);          /* Opera */
    -ms-transform: rotate(180deg);         /* IE9 */
    transform: rotate(180deg);             /* W3C compliant browsers */
  
    /* IE8 and below */
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=-1, M12=0, M21=0, M22=-1, DX=0, DY=0, SizingMethod='auto expand'); 
}

.sport-activity .prev-form-button{
    font-family: Montserrat;
    font-size:20px;
    font-weight:500;
    line-height:20px;
    background-color: transparent;
    text-transform: capitalize;
    
}
.sport-activity .nxt-form-button{
    font-family: Montserrat;
    font-size:20px;
    font-weight:500;
    line-height:20px;
    float: right;
    background-color: transparent;
    text-transform: capitalize;

}
.sport-activity  select{
    height: 47px !important;
    border-radius: 2px;
    margin-bottom: 35px;
    color:  #050505;
;
}
.sport-activity input[type="text"]{
    height: 47px !important;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 35px;
    width: 100%;
}

.sport-activity input[type="radio"]{
    margin-left: 10px;
    margin-right: 10px;
    accent-color:#BAD0A2 ;
}

.sport-activity .diet-train-h{
    font-weight: 600;
    font-family: 'Montserrat SemiBold';
    font-size: 20px;
}

.sport-activity .diet-train-p{
    font-weight: 200;
    font-family: 'Montserrat';
}

.sport-activity .diet-train-p-bold{
    font-weight: 700;
}

.sport-activity strong{
    text-decoration: underline;
    font-weight: 900;
}

.sport-activity strong > img{
        width:20px;
        margin-left:10px;
}
.day-activity{
    border: 1px solid ;
    display: flex;
    border-radius: 4px;
    border: 0.2px solid #D9D9D9;
    padding: 3px;
}
.day-activity *{
    display: flex;
    margin:5px!important;
    align-items: center;
    justify-content: center;
    color:#E5E5E5;
}
.checked-day{
    background:#f6ffea;
}
.checked-day * {
    background:#f6ffea;
    color:#29292E;
}
.sport-activity label{
    font-family: 'Montserrat SemiBold';
    font-size: 14px;
}
.day-activity label{
    display:flex;
    justify-content:left;
    min-width: 66px;
    /* Montserrat/regular/10px */
    font-family: Montserrat;
    /* font-size: 14px; */
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding-left:5px;
    color:  #050505;
}


.day-activity input[type=text]{
    border-radius: 4px;
    height: 22px !important;
    font-size: 14px;
}
.day-activity input[type=text]::placeholder{
    color:#E5E5E5;
}
.day-activity input[type=time]{
    border-radius: 4px;
    height: 22px;
    min-width: 50px;
    /* font-size: 10px; */
    padding-left: 5px;
}

.day-activity input[type=time]::-webkit-calendar-picker-indicator {
    display: none;
}

#img-doubton{
    margin-top: 35px;
    width: 100%;
}
@media(max-width:768px){

    .sport-activity .sport-activity-first-form{
        margin-bottom:0px;

    }
    .sport-activity .sport-activity-first-form label{
        font-size: 10px;
        width: 21px;
        height: 21px;
        margin-left: 8px;
        padding-top:3px;
    }
    .sport-activity label{
        font-size: 12px;
        margin-bottom:12px;
    }

    .sport-activity p{
        padding-top: 10px;
    }

    .sport-activity .diet-train-h{
        font-weight: 600;
        font-family: 'Montserrat SemiBold';
        font-size: 14px;
    }

    .sport-activity-form-two{
        margin-bottom:20px !important;
    }

    .begin-form input[type=text]{
        height: 28px !important;
        font-size: 12px !important;
    }
    .cooking-fields{
        display: flex !important;
    }
    .cook-option:last-child{
        margin-left: 20px;
    }
    #eres_fumador p{
        font-size: 12px;
    }

    .begin-form h4{
       font-size: 12px;
       line-height: 18px;
       font-weight: 500;
       margin-bottom: 0px;
       font-family: 'Montserrat';
    }
    .sport-activity p{
        font-size: 12px;
        margin-bottom: 2px;
    }
    .sport-activity select{
        height: 28px !important;
    }

    .page-manager button{
        padding:0rem;
    }

    .sport-activity .prev-form-button{
        font-size:15px;
   }
   .sport-activity .nxt-form-button{
       font-size:15px;
   }

}

@media(max-width:760px){
    .sport-activity{
        padding: 20px;
    }
}