.faqs-container {
    background-color: white;
    width: 100%;
    height: auto;
    padding-bottom: 5rem;
    padding-top: 2rem;
}

.title-section-faqs h2 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 39.06px;
    line-height: 50px;
    color: #29292E;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
}

.title-section-faqs p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #29292E;
}

.header-section-faqs h1{
    margin-top: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #29292E;    
}

.bg-dieta {
    background: #FFF9F5;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
}

.bg-dieta img {
    padding: 1rem;
    width: 65px;
}

.bg-taller {
    background: rgba(255, 242, 227, 0.7);
    border-radius: 50%;
}

div.scrollmenu {
    overflow-x: auto;
    white-space: nowrap;
}

div.scrollmenu button {
    display: inline-block;
    color: #29292E;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}

.bg-taller img {
    padding: 1rem;
}

.bg-psicologia{
    background: #FCFFF8;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
}

.bg-psicologia img {
    padding: 1rem;
    width: 65px;
}

.card-container {
    padding: 1rem 0rem;
    border-bottom: 2px solid #95A585;
    border-radius: 0 0 0 0;
    margin-bottom: 1rem !important;
    width: 70%;
    margin: 0 auto;
}

.container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-icon-container h2 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 31.25px;
    line-height: 48px;
    color: #29292E;
    margin-left: 2rem;
}

.title-icon-container img {
    margin-right: 0rem;
}

.div-container-description {
    margin-top: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #29292E;
    margin: 1rem 4rem 1rem 7rem;
}

.faq-icon-collasep {
    padding: 0.5rem;
    background-color: #ffffff00 !important;
    margin-left: 0.5rem;
    margin-right: 2rem;
}

.faqs-container .active-button {
    color: #29292E;
    border-bottom: 2px solid #BAD0A2;
    border-radius: 0 0 0 0;
    display: inline-block;
}

.inactive-button {
    color: #ccc !important;
    border-bottom: none;
    border-radius: 0 0 0 0;
    display: inline-block;
}

@media screen and (max-width: 321px){
    .faqs-container .plans-type-select button {
        font-size: 16px!important;
    }

    div.scrollmenu button {
        width: 130px;
    }
}

@media only screen and (max-width: 475px) {

    div.scrollmenu button {
        width: 170px;
    }

    .faq-icon-collasep {
        margin-right: 0rem;
    }
    .title-icon-container h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        margin-left: 1rem;
    }

    .card-container {
        width: 80%;
    }

    .div-container-description {
        font-weight: 400;
        font-size: 10.24px;
        line-height: 16px;
        margin: 0.5rem 2.5rem 0.5rem 4rem;
    }
    .bg-taller img {
        padding: 0.5rem;
        width: 40px;
        height: 38px;
    }
    .bg-psicologia img {
        padding: 0.5rem;
        width: 40px;
        height: 38px;
    }
    .bg-dieta img {
        padding: 0.5rem;
        width: 40px;
        height: 38px;
    }

    .title-section-faqs h2 {
        font-size: 24px;
    }

    .header-section-faqs h1{
        margin-top:0px;
        margin-left:10px;
        margin-right:10px;
        font-size:14px;
    }

    .card-container {
        padding: 0.5rem 0rem;
    }

    .faqs-container .plans-type-select button {
        padding: 0rem 0rem;
        font-size: 18px;
        line-height: 20px;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

@media (min-width: 476px) and (max-width: 990px) {

    div.scrollmenu button {
        width: 220px;
    }

    .plans-type-select button {
        padding: 0rem 0rem;
        font-size: 18px;
        line-height: 20px;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .faq-icon-collasep {
        margin-right: 0rem;
    }
    .title-icon-container h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        margin-left: 1rem;
    }

    .header-section-faqs h1{
        font-size:16px;
    }

    .card-container {
        width: 80%;
    }

    .div-container-description {
        font-weight: 400;
        font-size: 10.24px;
        line-height: 16px;
        margin: 0.5rem 2.5rem 0.5rem 4rem;
    }
    .bg-taller img {
        padding: 0.5rem;
        width: 40px;
        height: 38px;
    }
    .bg-psicologia img {
        padding: 0.5rem;
        width: 40px;
        height: 38px;
    }
    .bg-dieta img {
        padding: 0.5rem;
        width: 40px;
        height: 38px;
    }

    .title-section-faqs h2 {
        font-size: 24px;
    }

    .card-container {
        padding: 0.5rem 0rem;
    }    
}

@media (min-width: 991px) and (max-width: 1099px) {
    .card-container {        
        width: 70%;
        margin: 0 auto;
    }

    .plans-type-select button {
        padding: 0rem 2rem;
    }
}
