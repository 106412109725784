.succes-container-security{
    min-height: 50px;
    position: relative;
    max-width: 766px;
    background-color: #58D68D;
    margin: auto; 
       animation: fadeIn 0.5s;
       /* margin-bottom: 20px; */

}
.main-success-container-security{
           z-index: 2147483;
           top: 80px;
           margin:21px;
           position: sticky;
}
.success-text-message{
    text-align: center;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
}
.cross-sign-security{
    text-align: right;
    font-size:12px;
    top: 50%;
    position: absolute;
    left: 95%;
    transform: translate(-100%, -50%);
    margin-right: 10px;
    color: white;

}
.cross-sign-security img{
    width: 10.486000061035156px;
    height: 10.486000061035156px;
    top: 6.7570037841796875px;
    left: 6.7578125px;
    border: 1.5px;
    cursor: pointer;
    }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
@media (max-width:500px){
    .success-text-message{
        font-family: Montserrat;
        font-size:6.56px;
        font-weight: 600;
        line-height: 10px;
        white-space: nowrap;
        letter-spacing: 0.5px;

    }
    .succes-container-security{
        min-height: 32px;
        position: relative;
        max-width: 766px;
        background-color: #58D68D;
        margin: auto; 
           animation: fadeIn 0.5s;
           /* margin-bottom: 20px; */
    
    }
    .cross-sign-security img{
        width: 9.838120460510254px;
height: 9.838120460510254px;
top: 11px;
left: 296.373291015625px;
padding: 2.7698423862457275px 2.7694079875946045px 2.7698395252227783px 2.7702739238739014px;

    }

}