.bf-testimonial-content {
  width: 100%;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 3.5rem 1.25rem 4rem;
  background-color: rgba(10, 13, 11, 1);
  max-width: none;
  min-height: auto;
  color: initial;
  border: none;
  border-radius: 0;
}

.bf-testimonial-title-wrapper {
  width: 100%;
  max-width: 80rem;
  text-align: left;
}

.bf-testimonial-title {
  font-family: 'Recline Medium';
  font-size: 2.625rem;
  font-weight: 500;
  line-height: 2.625rem;
  color: #f9fafa;
}

.bf-testimonial-slider {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.bf-testimonial-slider .slick-prev,
.bf-testimonial-slider .slick-next {
  display: none !important;
}

.bf-testimonial-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bf-testimonial-button {
  width: 100%;
  padding: 16px 24px;
  border: 2px solid #f9fafa;
  border-radius: 6.25rem;
  background-color: transparent;
  font-family: 'Montserrat SemiBold';
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: #f9fafa;
  text-align: center;
}

@media (width > 37.5rem) {
  .bf-testimonial-content {
    padding: 4rem 4rem 5rem;
  }

  .bf-testimonial-title {
    font-size: 4rem;
    line-height: 4rem;
  }
  .bf-testimonial-button-wrapper {
    width: 18.3125rem;
  }
}

@media (width > 62.5rem) {
  .bf-testimonial-content {
    padding: 5rem 5rem 6rem;
  }

  .bf-testimonial-title {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}
