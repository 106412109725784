.bf-logo-img {
  width: 5.4375rem;
}

@media (width > 37.5rem) {
  .bf-logo-img {
    width: 7.25rem;
  }
}

@media (width > 62.5rem) {
  .bf-logo-img {
    width: 8.4375rem;
  }
}
