.product-testimonials .testimonial-complete-img {
    width: 30%;
    padding-left: 0%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2%;
    margin-right: 3%;
}

.team-other-products-row .our-testimonials {
    margin-left: -1rem;
}

.product-testimonials .description-complete-container {
    padding-left: 0%;
    padding-right: 5%;
    text-align: left;
    width: 65%;
}

.product-testimonials .image-complete-post {
    width: auto;
    height: 155px;
}

.custom-dot-testimonios {
    width: 1rem;
    height: 1rem;
    background-color: white;
    border: 0.03rem solid #BAD0A2;
    margin: 0 5px;
    border-radius: 50%;
}

.slick-active .custom-dot-testimonios {
    background-color: #BAD0A2;
}

.custom-dot-testimonios {
    width: 1rem;
    height: 1rem;
    background-color: white;
    border: 0.03rem solid #BAD0A2;
    margin: 0 5px;
    border-radius: 50%;
}

.slick-active .custom-dot-testimonios {
    background-color: #BAD0A2;
}

.slidertestimonials-product-dots li {
    position: relative;
    display: inline-block;
    width: 5px;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    cursor: pointer;
    top: 0rem !important;
}

.slidertestimonials-product-dots {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .product-testimonials .description-complete-container {
        padding-left: 5%;
        padding-right: 4%;
        text-align: left;
        width: 96%;
    }
}
