.title_hours {
  font-family: "Recline Medium";
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
  color: #29292e;
}

.subtile_hours {
  font-family: "Montserrat SemiBold";
  font-size: 16px;
  line-height: 24px;
  width: 92%;
  margin: auto auto 2rem;
  color: #29292e;
}

.text_hours {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  width: 90%;
  margin: auto;
  height: 35px;
}

.button_hours {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.button_hours button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 242px;
  height:50px;
}

.hour-item-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 92%;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  margin: auto auto 20px;
  cursor: pointer;
  color: #29292e;
}

.icon-down {
  width: 24px;
  height: 24px;
  margin-left: 46%;
  margin-top: 12px;
}

@media (max-width: 768px) {
  .hour-item-div {
    font-size: 16px;
  }
  .container_text_hours{
    height: 95% !important;
  } 

}
