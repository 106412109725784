.vikika-suscription-forms{
    color: #29292E;
    padding: 15%;
    padding-top: 200px;
    padding-bottom:10%;
}

.vikika-suscription-forms h2{
    position: absolute;
    font-family: 'Recline Medium';
    margin-left: auto; 
    left: 0; 
    right: 0; 
    margin-right: auto; 
    top: -70px;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    
}

.begin-form{
    position: relative;
    max-width: 920px;
    margin: auto;
    padding: 68px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px 3px #F2F4F5;
}

.begin-form-end-review{
    position: relative;
    max-width: 920px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 68px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px 3px #F2F4F5;
}

.begin-form .line-progress{
    max-width: 64.5px;
    width:5vw;
    height: 2px;
    margin-top: 20px;
    margin-left: 15px;
}

.begin-form span.line-progress-active{
    background: linear-gradient(to right, #BAD0A2 50%, #B1B2B2 50%);
}

.begin-form span.line-progress-active-full{
    background: linear-gradient(to right, #BAD0A2 50%, #BAD0A2 50%);

}

.begin-form h3 img{
    width: 25px;
    margin-left: 8px;
    margin-right: 8px;
}

.begin-form h3{
    font-family: 'Montserrat SemiBold';
    font-size: 20px;
    margin: 5px;
    margin-bottom: 20px;
    font-weight: 900;
}

.begin-form a{
    margin-top: 15px;
}

.begin-form h4{
    font-family: 'Montserrat SemiBold';
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 35px;
}

.begin-form input:focus {
    border: 2.83px solid #0B49EB;
}

.begin-form input[type=text] {
    height: 47px;
    padding: 10px;
    width: 100%;
    margin-bottom: 35px;
    margin-top: 10px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border: 0.83px solid #E5E5E5;
    border-radius: 4px;
}

.begin-form .photo-change{
    width:433px ;
    margin-bottom: 20px;
}

.begin-form .accept-box{
    margin-top: 15px;
}

.begin-form .accept-box a{
    color: #0B49EB;
    text-decoration: none;
}

.begin-form .questionnaire-button-left{
    font-family: Montserrat;
    font-size:20px;
    font-weight:500;
    line-height:20px;
    text-transform: capitalize;
    background-color: transparent;
}

.begin-form .questionnaire-button-right{
    width:242px;
    height:48px;
    float: right;
    text-transform: capitalize;
    background-color: #BAD0A2;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family:Montserrat;
    line-height:14.63px;
}

.begin-form-end-review .questionnaire-button-center{
    width:242px;
    height:48px;
    text-transform: capitalize;
    background-color: #BAD0A2;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family:Montserrat;
    line-height:14.63px;
}

.send-button{
    font-family:Montserrat !important;
    font-weight:600;
}

.prev-form-button{
    font-family: Montserrat;
    font-size:20px;
    font-weight:500;
    line-height:20px;
    background-color: transparent;
    text-transform: capitalize;
    
}
.nxt-form-button{
    font-family: Montserrat;
    font-size:20px;
    font-weight:500;
    line-height:20px;
    float: right;
    background-color: transparent;
    text-transform: capitalize;

}

.data-form-titles{
    height: 68px;
    width: inherit;
    display: flex;
    margin: auto;
    margin-bottom: 0px;
    justify-content: center;
    
}

.data-form-titles p{
    margin-top: 10px;
}

.data-form-titles span{
    color:white;
    height: 36px;
    width: 36px;
    text-align:center;
    margin-right: 10px;
    border-radius: 50%;
    line-height: 35px;
    margin-bottom: 10px;
    background-color: #B1B2B2;
}

.data-form-titles-checks{
    display: flex;
    justify-content: center;
    line-height: 18px;
}

.data-form-titles-checks p{
    color: #B1B2B2;
}

.data-form-titles-checks span{
    height: 36px;
    width: 36px; 
}

span.success-step{
    background-color:#BAD0A2 ;
}

.success-step-p1{
    color: #29292E !important;
    width: inherit;
}

.success-step-p2{
    color: #29292E !important;
    width: inherit;
}

.success-step-p3{
    color: #29292E !important;
    width: inherit;
}

.accept-box label{
    font-family:'Montserrat SemiBold';
    font-weight:600;
    font-size:14px;
    line-height:20px;
}

#your-photos-title{
    font-family: Montserrat SemiBold;
    font-style: normal;
    font-weight: 600;
    text-align: left;
}

.page3-form-container{
    position: relative;
    color: #29292E;  
    margin-bottom: 2rem;
}

.photos-description{
    line-height:20px;
}

.photos-description-bold{
    font-weight:700;
}

.image-file-container{
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
    border-width: 2px;
    border-radius: 10px;
    border-style: dashed;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 20px;
    padding-bottom:20px;
    box-shadow: 0px 7.493447780609131px 33.720516204833984px 0px #072A440A;
    border-color: #BAD0A2;
}

.image-file-container-error{
    border-color: #F70808 !important;
}

.image-file-container .img-file-section{
    margin: 20px;
}

.image-file-container p{
    text-align: center;
    font-weight: bolder; 
    font-family: 'Montserrat SemiBold';
    padding-top: 10px;
}

.image-file-container input[type="file"] {
    display: none;
}

.instructions-images{
    font-size:10px;
}

.instructions-images>img{
    position:relative;
    top:3px;
}

.instructions-images-error{
    color:#F70808;
}

.custom-file-upload {
    background-color: #D9D9D9;
    width: 126px;
    height: 153px;
    border-radius: 10px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

#support-form-contact{
    font-family:'Montserrat SemiBold';
    font-weight:600;
    font-size:10px;
    line-height:16px;
    margin-bottom: 15px;
    margin-top: 30px;
    color: #29292E;
} 

#support-form-contact-text{
    font-family:'Montserrat';
    font-weight:400;
    font-size:10px;
    line-height:16px;
    margin-left:2.5%;
} 

#support-form-contact-text a{
    color:#0B49EB;
}

.page-manager{
    margin-top: 24px;
    text-align: center;
    margin-right: 30px;
}

.page-manager button{
    background-color: transparent;
}

.not-current{
    display: inline-block;
    width: 5px;
    height: 6px;
    background-color: #BAD0A2;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 10px;
    margin-bottom: 3px;
}

.page-manager .current{
    display: inline-block;
    border:1px solid #BAD0A2;
    width: 25px;
    height: 25px;
    line-height: 1.6rem;
    color: #BAD0A2;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 50%;
}

.page-manager .current-done{
    display: inline-block;
    border:1px solid #BAD0A2;
    width: 25px;
    height: 25px;
    line-height: 1.6rem;
    color: #ffff;
    background-color:  #BAD0A2;
    border-radius: 50%;
    margin-right: 15px;
    margin-left: 15px;
}

.page-manager .rotate-arrow{
    -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg);        /* FF */
    -o-transform: rotate(180deg);          /* Opera */
    -ms-transform: rotate(180deg);         /* IE9 */
    transform: rotate(180deg);             /* W3C compliant browsers */
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=-1, M12=0, M21=0, M22=-1, DX=0, DY=0, SizingMethod='auto expand'); 
}

.data-label{
    font-family: Montserrat SemiBold;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 0rem;
}

.final-form{
    padding:18px 28px !important;
}

.final-container{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.final-header{
    font-family: 'Recline Medium' !important;
    font-size: 29.06px !important;
    font-weight: 500 !important;
    line-height: 60.54px !important;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

.final-text{
    text-align:center;
    font-family: 'Montserrat';
    font-size: 16px;
}

.final-icon{
    background-color: #BAD0A2;
    color: white;
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    font-family: 'Montserrat', sans-serif;
    font-size: 44px;
}

.final-button{
    width: 242px;
    height: 48px;
    float: right;
    text-transform: capitalize;
    background-color: #BAD0A2;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family: Montserrat;
    line-height: 14.63px;
    margin-top:30px;
    margin-bottom:20px;
}

.instructions-send-form{
    color: #29292E;
    font-size:12px;
    font-weight:600;
    margin-top: 10px;
    margin-bottom: 10px;
}

.header-end-review{
    position:  relative !important;
    font-family: 'Recline Medium';
    margin-left: auto;
    left: 0;
    right: 0;
    margin-right: auto;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
}

.image-end-review{
    margin-bottom: 1rem;
    width: 80px;
    height: 80px;
}

.text-end-review{
    font-family: Montserrat;
    font-size:20px;
    font-weight:400;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px; 
}

@media (max-width:1120px){

    .begin-form .line-progress{
        width:3vw;
    }
}

@media (max-width:970px){

    .data-form-titles-checks p{
        font-size: 13px;
    }

    .begin-form .line-progress {
        margin-left:0px;
    }

    .data-form-titles span {
        margin-right:0px;
    }
}


@media (max-width:834px){

    .data-form-titles-checks p{
        font-size: 12px;
    }

    .image-file-container .img-file-section {
        margin: 5px;
    }
}

@media (max-width:768px){

    .vikika-suscription-forms{
        padding-top:150px;
        padding-left: 12px;
        padding-right: 12px;
        min-height: 500px;
    }
    .vikika-suscription-forms h2{
        font-size: 20px;
        top: -48px;
    }

    .begin-form{
        height:auto;
        min-height: 490px;
        position: relative;
        padding:10px !important;
    }

    .begin-form .questionnaire-button-right{
        width: 160px;
        height: 25px;
        font-size: 12px;
    }

    .begin-form-end-review .questionnaire-button-center{
        width: 160px;
        height: 25px;
        font-size: 12px;
    }

    .begin-form .line-progress{
        width:43.94px;
        margin-left:0px;
    }

    .begin-form h4{
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .begin-form input[type=text] {
        height: 28px !important;
        font-size: 10px;
        margin-bottom: 20px;
    }

    .begin-form hr {
        margin-top: 30px;
    }

    .data-form-titles{
        height: 51px;
        width: 111px;
        margin: auto;
    }

    .data-form-titles span{
        margin-right: 0px;
        margin-top: 11px;
    }

    .data-form-titles p{
        display: none;
    }

    .sucess-step-icon{
        height: 22px !important;
        width: 22px !important;
        line-height:17.07px !important;
        padding-top:2px;
    }

    #support-form-contact {
        margin-top: 20px;
        font-size: 10px !important;
    }

    #support-form-contact-text {
        font-size: 10px !important;
    }

    .photos-description{
        line-height:15px;
    }

    .page-manager button{
        padding:0rem;
    }

    .send-button{
        width:107.5px;
        height:25px;
    }

    .prev-form-button{
        font-size:15px;
    }

    .nxt-form-button{
       font-size:15px;
    }

    .data-label{
        font-size:12px;
        margin-top: 1rem;
    }

    .instructions-send-form{
        font-size:10px;
    }

    .final-form{
        padding:18px 28px !important;
        min-height: auto !important;
    }

    .final-icon{
        height: 50px;
        width: 50px;
        font-size: 34px;
    }

    .final-text{
        font-size:14px;
        margin-top:10px;
    }

    .final-header{
        font-size:20px !important;
        text-align:center;
        line-height:30px !important;
    }

    .final-button{
        font-size: 12px;
        width: 160px;
        height: 35px;
    }

}

@media(max-width:760px){

    .begin-form .questionnaire-button-right{
        font-size: 10px;
    }

    .begin-form-end-review .questionnaire-button-center{
        font-size: 10px;
    }

    .custom-file-upload{
        width: 119.434px !important;
        height: 142.17px !important;
    } 

    .questionnaire-button-left {
        width: max-content !important;
        font-size: 15px !important;
        margin-top: 5px;
    }

    .questionnaire-button-left img{
        width: 18%;
    }

    .nxt-form-button,.prev-form-button{
        font-size: 14px;
    }
    .nxt-form-button img,.prev-form-button img{
        width: 20%;
    }
}

@media (max-width:500px){

    .begin-form label{
        font-size: 12px;
    }

    .begin-form p{
        font-size: 12px !important;
    }

    .begin-form h3{
        text-align: left;
        font-size: 14px !important;
    }

    .begin-form h3>img{
        width:12px;
        height:15px;
    }

    .begin-form a{
        margin-top: 5px !important;
        line-height: 18px;
        font-size: 12px;
    }

    .begin-form .photo-change{
        width: 252px !important;
    }

    .page3-form-container{
        margin-bottom: 0px;
    }

    .image-file-container{
        padding-top: 0px;
        padding-bottom:0px;
    }

    .custom-file-upload{
        width: 89.434px !important;
        height: 112.17px !important;
    } 

    .send-button{
        font-size: 10px !important;
    }

    .image-end-review{
        margin-bottom: 0.5rem;
        width: 50px;
        height: 50px;
    }

    .text-end-review{
        font-size:18px;
        font-weight:400;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px; 
    }

    .header-end-review{
        font-size: 25px;
    }
}

@media(max-width:420px){

    .custom-file-upload{
        width: 69.434px !important;
        height: 92.17px !important;
    } 
}

@media(max-width:398px){
    
    .begin-form .questionnaire-button-right{
        font-size: 12px;
        text-transform: uppercase;
    }

    .begin-form-end-review .questionnaire-button-center{
        font-size: 10px;
    }

    .begin-form .questionnaire-button-left{
        font-size: 10px;
    }
}

@media(max-width:360px){

    .image-file-container{
        display:grid;
        grid-template-columns:100%;
        grid-template-rows: 33.3% 33.3% 33.3%;
        justify-items: center;
    }

    .image-file-container .img-file-section{
        margin:0px;
    }

    .img-section-1 label,.img-section-2 label,.img-section-3 label{
        width: 110px !important;
        height: 130px !important;
    }
}

@media(max-width:328px){

    .vikika-suscription-forms h2{
        line-height: inherit;
    }

    .begin-form .photo-change{
        width:200px !important;
    }

    .send-button{
        font-size: 10px !important;
    }

}