.privacy-container p,
.privacy-container h2 {
    color: #29292E;
}

.privacy-container h2 {
    font-family: 'Montserrat';
    font-size: 28px;
    line-height: 32px;
    text-align: left;
}

.privacy-container p {
    font-family: 'Montserrat';
    font-size: 15px;
    line-height: 24px;
    text-align: left;
}

.privacy-container p.strong {
    margin-bottom: -1rem;
}

.privacy-container p strong {
    font-family: 'Montserrat Bold';
    font-size: 16px;
}

.privacy-container li strong {
    font-family: 'Montserrat Bold';
}

.privacy-container ul {
    color: #29292E !important;
    padding-left: 16px;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
}

.privacy-container {
    margin-top: 8%;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2.5rem;
    padding-bottom: 6%;
}

@media (max-width: 768px) {
    .privacy-container {
        margin-top: 80px
    }
}
