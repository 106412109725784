.card-content-product-container {
    width: 49.5%;
    margin-left: 10px;
}


.card-price-container {
    font-family: 'Recline Regular';
    font-weight: 600;
    font-size: 3.71rem;
    line-height: 5.75rem;
    color: #29292E;
    margin-top: -20px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 65%;
    justify-content: center;
}
.interesting-card-only {
    max-height: 169px;
}
.card-price-quantity {
    font-size: 35px;
    margin-right: 0.5rem;

}

.card-button-taller{
    margin-top: 43px !important;
}

.opacity{
    opacity: 0.3;
}

.card-button {
    margin-left: 5px;
    width: 116.16px;
    height: 23.09px;
    background: #BAD0A2;
    border-radius: 5px;
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 8.66px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #29292E;
    margin-top: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.letter-type-interesting-center{
    height: 30px;
}
.card-price-frecuency {
    font-size: 1.71rem;
    font-family: 'Recline Regular';
    font-weight: 400;
    line-height: 2.65rem;
    color: #29292E;
}



.card-content-product {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}



.letter-type-interesting {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    color: #29292E;
}

.description-product-interesting {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 9.24px;
    line-height: 16px;
    text-align: center;
    color: #29292E;
    /* height: 80px; */
    display: flex;
}
.card-content-card{
    padding-top: 1px !important;
}
.description-product-interesting p {
    min-height: 30.9px;
    margin-top: auto;
    line-height: 11.54px;
    margin-bottom: auto;
    font-size: 9.24px;
}

/* .divider-color {
    border: 0.08rem solid #95A585 !important;
    margin: 0 2rem !important;
} */

.normal-plan {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 12.99px;
    /* line-height: 28px; */
    text-align: left;
    color: #29292E;
    margin-top: 0.5rem;
}

.container-price-plan {
    padding: 0 0rem;
}

.flex-container-title-button-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.btn-product-others-interesting {
    margin-top: -20px;
    width: 35%
}

@media (max-width: 1300px){
    .card-price-container {
        font-family: 'Recline Regular';
        font-weight: 600;
        font-size: 31px;
   
    }
    .description-product-interesting {
        font-size: 11.54px;
    }
    .normal-plan {
  
        font-size: 12.99px;
      
    }
    .card-button {
        width: 116.16px;
        height: 23.09px;
        font-size: 8.66px;
    
    }
    .card-content-product-container {
        width: 49.5%;
        margin-left: 10px;
    }
    .interesting-card-only {
        max-height: 189px;
    }
    
    
}
@media (max-width: 1200px) {
    /* .card-price-frecuency {
        font-size: 1.42rem;
        line-height: 2.37rem;
    }

    .card-price-container {
        font-size: 3.35rem;
        line-height: 5.4rem;
    } */
}

@media (max-width: 768px) {
    .product-cards-containers {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        margin-top: 27px;

    }

    .card-content-product-container {
        width: 100%;
    }
    .card-content-product-container:first-child{
        margin-bottom: 2rem
    }
    .card-content-product-container {
        margin-left: 0px;
    }
    .card-button {
        margin-bottom: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .description-product-interesting {
        font-size: 10px;
        line-height: 12px;
        height: 38px;
    }
    .card-content-interesting{
        max-height: 142px;
    }
    .letter-type-interesting {
font-size: 18px;
line-height: 2px;
letter-spacing: 0em;
text-align: center;

    }
    .description-product-interesting p {
        font-size: 8px;
        line-height: 11.54px;
    }

}

@media (max-width: 495px) {
    .card-price-frecuency {
        font-size: 1rem;
        line-height: 1.55rem;
    }

    .card-price-container {
        font-size: 31px;
        line-height: 4.32rem;
        width:40%;
        padding-left: 1rem;
    }

    .divider-color {
        border: 0.08rem solid #95A585 !important;
        margin: 0 3.5rem !important;
    }
    .card-button {
        width: 116.16px !important;
        height: 23.09px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 8.66px;
        line-height: 0.71rem;
        margin-top: 0;
        width: 60%;
        margin-top: -10px;
        /* padding-top: 0.8rem   */
    }

    .normal-plan {
        font-size: 12.8px;
        line-height: 19.84px;
        margin-top: 0;
        margin-bottom: 0.5rem;
        /* padding-left: 2.5rem; */
    }
    .description-product-interesting p {
        margin: 0.6rem auto;
        padding-left: 2rem;
        padding-right: 2rem;
        
    }
    .card-content-product {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        
    }
    .flex-container-title-button-price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 97%;
        margin-bottom: 0.3rem;
        margin-right: 0 !important;
        align-items: flex-start;
    }
    
}

@media (max-width: 330px) {
    .css-46bh2p-MuiCardContent-root {
        padding-top: 16px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        padding-bottom: 0 !important;
    }
    .card-button {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .css-o69gx8-MuiCardMedia-root {        
        height: 45px;
    }
    .description-product-interesting p {
        margin: 0.5rem auto;
        padding-left: 1rem;
        padding-right: 1rem;
        
    }
    .others-products {
        padding: 0 1rem;
    }

    .card-price-container {
       
        width:70%;
        padding-left: 0;
    }

    
    .card-button {        
        font-size: 0.58rem;  
    }
    
    
    .card-price-quantity {
        margin-right: 0;
    
    }

}
