.work-container {
    display: flex;
    flex-direction: column;
    margin-top: 8%;
    margin-bottom: 6%;
  }
  
  .work-container h1 {
    color: #29292E;
    text-align: center;
    font-size: 2.2rem;
    line-height: 1.2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    margin-bottom: 3%;
    letter-spacing: 1px;
  }
  
  .work-container h2 {
    color: #29292E;
    text-align: center;
    font-weight: 700;
    line-height: 1.2rem;
    font-family: "Poppins", sans-serif;
    font-size: 2.1rem;
  }
  
  .work-container h4 {
    color: #29292E;
    position: relative;
    left: 5%;
    margin-top: 3%;
    font-size: 1.6rem;
    line-height: 1.2em;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1.5rem;
  }
  
  .work-container ul {
    color: #29292E;
    position: relative;
    left: 5%;
    margin-bottom: 20px;
  }
  
  .work-container li {
    color: #29292E;
    line-height: 26px;
    list-style-type: disc;
    font-weight: 300;
    font-size: 15px;
  }
  
  .work-container p {
    color: #29292E;
    position: relative;
    left: 5%;
    margin-top: 1%;
    width: 72%;
    line-height: 1.8rem;
    font-size: 1.1rem;
    font-family: Montserrat, sans-serif;
  }

@media (max-width: 768px) {
  .work-container {
    margin-top: 80px
  }

  .work-container h1 {
    margin-bottom: 30px;
  }

  .work-container h2 {
    margin-bottom: 20px;
    line-height: 40px;
  }
}

