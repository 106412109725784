.bf-promotions-toggle-wrapper {
  width: 100%;
  border-radius: 6.25rem;
  padding: 0.5rem;
  border-width: 2px;
  border-style: solid;
  z-index: 1;
}

.bf-promotions-toggle-wrapper.nutrition {
  background-color: rgba(49, 64, 55, 1);
  border-color: rgba(186, 208, 162, 1);
}

.bf-promotions-toggle-wrapper.psychology {
  background-color: rgba(41, 30, 18, 1);
  border-color: rgba(155, 132, 100, 1);
}

.bf-checkbox {
  display: none;
}

.bf-promotions-toggle-wrapper .toggle-label {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 12px;
  border-radius: 6.25rem;
  font-family: "Montserrat SemiBold";
  text-transform: uppercase;
  text-align: center;
  text-wrap: nowrap;
  font-size: 1rem;
  line-height: 1rem;
  color: rgba(10, 13, 11, 1);
  cursor: pointer;
  overflow: hidden;
  margin: 0;
}

.bf-promotions-toggle-wrapper .toggle-label.nutrition {
  background-color: rgba(49, 64, 55, 1);
}

.bf-promotions-toggle-wrapper .toggle-label.psychology {
  background-color: rgba(41, 30, 18, 1);
}

.bf-promotions-toggle-wrapper .bf-individual,
.bf-promotions-toggle-wrapper .bf-couple {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6.25rem;
  padding: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Colores de estado INACTIVO (INDIVIDUAL seleccionado) */
.bf-promotions-toggle-wrapper .bf-individual.nutrition,
.bf-promotions-toggle-wrapper .toggle-label.active .bf-couple.nutrition {
  color: rgba(10, 13, 11, 1);
  background-color: rgba(186, 208, 162, 1);
}

.bf-promotions-toggle-wrapper .bf-individual.psychology,
.bf-promotions-toggle-wrapper .toggle-label.active .bf-couple.psychology {
  color: rgba(10, 13, 11, 1);
  background-color: rgba(155, 132, 100, 1);
}

/* Color del texto de estado ACTIVO (EN PAREJA seleccionado) */
.bf-promotions-toggle-wrapper .toggle-label.active .bf-individual,
.bf-promotions-toggle-wrapper .bf-couple {
  color: rgba(241, 246, 236, 1);
}

/* Color de fondo de estado ACTIVO (EN PAREJA seleccionado) */
.bf-promotions-toggle-wrapper .toggle-label.active .bf-individual {
  background-color: transparent;
}

@media (width > 37.5rem) {
  .bf-promotions-toggle-wrapper .toggle-label {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
}
