.mydata-content{
   width: 60%; 
   margin: 0 auto;
   margin-bottom: 15rem;
  
}
.mydata-title{
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 31px;
    line-height: 35px;
    text-align: left;
    color: #111111;
    margin-top: 6rem;
    margin-bottom: 2.14rem;
}
.mydata-form{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    
}
.mydata-row{
    display: flex;
    flex-direction: row;

}
.mydata-input-complete{
    width: 70%;
}
.mydata-input{
    display: flex;
    flex-direction: column;
}
.mydata-input-simple{
    display: flex;
    flex-direction: column;
}
.mydata-buttons{
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    align-items:flex-end;

    
}

.mydata-buttons-address{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items:flex-end;
    padding-bottom: 16px;    
}
.mydata-buttons-address button{
    width: 94px;
    height: 27px;    
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: justify;
    color: #111111;
    text-align: center;
}
.mydata-buttons button{
    width: 94px;
    height: 27px;    
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: justify;
    color: #111111;
    text-align: center;
}
.mydata-buttons-edit button{
    width: 94px;
    height: 27px;    
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: justify;
    color: #111111;
    text-align: center;
}

.mydata-input-simple input {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;   
    background-color: white !important;
    width: 97%;
    box-sizing: border-box;
    border: 0.1rem solid white;
    border-radius: 0.35rem;
    height: 1.92rem;
    padding-left: 0;
    margin-top: 0.99rem;
    margin-bottom: 0;
    outline: none;
    
}

.mydata-input input {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;   
    background-color: white !important;
    width: 97%;
    box-sizing: border-box;
    border: 0.1rem solid #D9D9D9;
    border-radius: 0.35rem;
    height: 1.92rem;
    padding-left: 0;
    margin-top: 0.99rem;
    margin-bottom: 0;
    outline: none;
    
}


.mydata-content ::placeholder {
    color: #111111;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.mydata-input-radio input[type="radio"] {
    margin-right: 0.5rem;
    width: 22px;
    height: 22px;    
  }


.mydata-input-radio label {
    margin-right: 2rem;
    align-items: center;
}


.mydata-cancel{
    margin-right: 0.57rem;
    background-color: #D9D9D9;
}

.mydata-save{    
    background-color: #F6FFEA ;
    border:1px solid #BAD0A2 ;
}
.mydata-edit{    
    background-color: #FFFF;
    border:1px solid #BAD0A2 ;
}
.mydata-edit-address{    
    background-color: #FFFF;
    border:1px solid #BAD0A2 ;
}
.mydata-fake{    
    background-color: #FFFF;
    border:1px solid #FFFF ;
    color: #FFFF;
}

.mydata-input-radio {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
}
.mydata-spacer{
    margin-top: 1.5rem;
}
.radio-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}

.mydata-address-details{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
    justify-content: space-between;
}

.mydata-address-details input{
    box-sizing: border-box;
    border: 0.1rem solid #D9D9D9;
    border-radius: 0.35rem; 
    width: 94px;
    height: 27px;  
    text-align: center;
}

.mydata-address-details-row{
    display: flex;
    flex-direction: row;    
    align-content: center;
}


@media (max-width: 1300px) {
    .mydata-content{
        width: 70%;         
     }
}

@media (max-width: 1200px) {
    .mydata-content{
        width: 90%;         
     }
}
@media (max-width: 968px) {
    .mydata-input-complete{
        width: 60%;
    }
    
    .mydata-buttons{
        width: 40%;       
    }
    .mydata-buttons-address{
        width: 40%;       
    }
}

