.bf-countdown-container {
  position: relative;
  text-align: center;
  z-index: 2;
}

.bf-countdown-icon {
  position: absolute;
  top: -1.5rem;
  left: 25%;
  transform: translateX(-50%);
  width: 8.7094rem;
  height: auto;
  z-index: 3;
}

.bf-countdown-wrapper {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.bf-countdown-time-box {
  width: 4.9375rem;
  height: 6.5625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0.75rem 1.25rem;
  border-radius: 1rem;
  gap: 0.75rem;
  background-color: rgba(0, 0, 0, 0.48);
  z-index: 2;
}

.bf-countdown-time-label {
  font-size: 1rem;
  line-height: 1.2rem;
  color: #bad0a2;
}

.bf-countdown-time-number {
  font-family: "Montserrat Bold";
  font-size: 2.625rem;
  line-height: 2.625rem;
  color: #9b8464;
}

@media (width > 37.5rem) {
  .bf-countdown-icon {
    width: 11.1181rem;
    top: -2rem;
    left: 22%;
    transform: translateX(-50%);
    font-size: 3rem;
    line-height: 3rem;
  }
  .bf-countdown-time-box {
    width: 7.1875rem;
    height: 8.875rem;
  }

  .bf-countdown-time-label {
    font-size: 1.125rem;
    line-height: 1.35rem;
  }
  .bf-countdown-time-number {
    font-size: 4rem;
    line-height: 4rem;
  }
}

@media (width > 62.5rem) {
  .bf-countdown-icon {
    width: 13.7312rem;
    font-size: 3.5rem;
    line-height: 4.2rem;
  }

  .bf-countdown-time-box {
    width: 125px;
    height: 150px;
  }

  .bf-countdown-time-number {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}
