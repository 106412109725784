/* Price section */
.bf-vital-promo-price {
  display: flex;
  align-items: start;
  gap: 0.25rem;
}

.bf-vital-promo-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: rgba(255, 255, 255, 1);
  padding: 1.5rem;
  border-radius: 0.25rem;
}

.bf-vital-promo-price-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bf-vital-promo-price-wrapper {
  display: flex;
  flex-direction: column;
}

.bf-vital-promo-price-number {
  font-family: 'Montserrat Bold';
  font-size: 2.625rem;
  line-height: 2.625rem;
  color: rgba(16, 37, 46, 1);
}

.bf-vital-promo-price-promo {
  font-family: 'Montserrat Bold';
  font-size: 1rem;
  line-height: 1rem;
  color: rgba(207, 151, 88, 1);
}

.bf-vital-promo-price-simbol {
  font-family: 'Montserrat Bold';
  font-size: 1.75rem;
  line-height: 1.75rem;
  color: rgba(16, 37, 46, 1);
  margin: 0;
}

.bf-vital-promo-prev-price {
  font-family: 'Montserrat';
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: rgba(16, 37, 46, 1);
  text-decoration: line-through;
  text-decoration-thickness: 0.125rem;
  text-decoration-color: rgba(244, 113, 105, 1);
  margin: 0;
}

.bf-vital-promo-logo {
  width: auto;
  height: 4rem;
  align-self: flex-start;
}

/* Info section */
.bf-vital-promo-info-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.bf-vital-promo-info-text {
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: 1.2rem;
  color: rgba(16, 37, 46, 1);
  margin: 0;
}

.bf-vital-promo-info-text strong {
  font-family: 'Montserrat Bold';
}

.bf-vital-promo-info-extra {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
}

.bf-vital-promo-info-icon {
  width: 1.5rem;
  height: auto;
}

.bf-vital-promo-info-anchor {
  font-family: 'Montserrat';
  font-size: 13px;
  line-height: 15.6px;
  color: rgba(115, 133, 140, 1);
  margin: 0;
}

.bf-vital-promo-info-anchor a {
  font-family: 'Montserrat Bold';
  text-decoration: underline;
  color: rgba(112, 125, 97, 1);
}

/* Button section */
.bf-vital-promo-button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (width > 37.5rem) {
  .bf-vital-promo-wrapper {
    padding: 2.5rem;
    gap: 2.5rem;
  }

  /* Price section */
  .bf-vital-promo-price {
    align-items: flex-end;
  }

  .bf-vital-promo-price-number {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }

  .bf-vital-promo-price-promo {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  .bf-vital-promo-price-simbol {
    font-size: 2rem;
    line-height: 2rem;
  }
  .bf-vital-promo-prev-price {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  /* Info section */
  .bf-vital-promo-info-text {
    font-size: 1.125rem;
    line-height: 1.35rem;
  }

  .bf-vital-promo-plan-selector {
    padding: 1rem 0.5rem;
  }

  .bf-vital-promo-plan-selector label {
    font-size: 1.125rem;
    line-height: 1.35rem;
  }

  .bf-vital-promo-radio-wrapper label {
    font-size: 1.125rem;
    line-height: 1.35rem;
  }

  .bf-vital-promo-info-anchor {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  /* Button section */
}

@media (width > 62.5rem) {
  .bf-vital-promo-wrapper {
    gap: 1.5rem;
  }
}
