.transform_container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6rem;
}

.transform_container h1 {
  margin-bottom: 1.5rem;
  font-family: "Recline Regular";
  font-size: 31.25px;
  line-height: 36px;
  text-align: center;
  color: #29292e;
}

.transform_container p {
  margin-bottom: 3rem !important;
  font-family: "Montserrat";
  max-width: 700px;
  margin: auto;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #29292e;
}

.images-container {
  max-width: 700px;
  margin: auto;
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2.5rem;
}

.image_title {
  position: relative;
  margin-bottom: 2rem;
  font-family: "Montserrat SemiBold";
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #29292e;
}

.images img {
  margin-bottom: 2rem;
  width: 188px;
  height: 228px;
  object-fit: cover;
  border-radius: 20px;
}

.images h3 {
  font-family: "Montserrat Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  color: #29292e;
  text-align: center;
}

@media (max-width: 992px) {
  .transform_container {
    width: 80%;
  }

  .transform_container h1 {
    margin-bottom: 1.25rem;
    font-family: "Recline Medium";
    font-size: 20px;
    line-height: 24px;
  }

  .transform_container p {
    margin-bottom: 2rem !important;
    font-family: "Montserrat";
    max-width: 100%;
    font-size: 16px;
    line-height: 18px;
  }

  .images-container {
    max-width: 480px;
  }

  .images {
    justify-content: unset;
    max-width: 480px;
    overflow-x: auto;
  }

  .images img {
    margin: 0 0.5rem;
  }
}

@media (max-width: 768px) {
  .transform_container {
    width: 90%;
    font-size: 12px;
  }

  .images img {
    width: 140px;
    height: 172px;
  }
}
