.confirm-payment-card {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    padding-bottom: 1rem;
    margin: 0 auto;
    color: #29292E;
    box-shadow: 0 4px 4px rgba(7, 42, 68, 0.15);
    border-radius: 11px;
}

.confirm-payment-card-header {
    background-color: #E5E5E5;
    height: 5rem;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
}

.modal-login-container {
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}

.modal-login-container .modal-login {
    padding: 2rem 6rem;
    border-radius: 5px;
    position: fixed;
    top: 20%;
    left: 15%;
    background: #fff;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-login-container .modal-login .modal-login-text {
    font-family: 'Montserrat';
    font-size: 20px;
    line-height: 28px;
    text-align: center;
}

.modal-login-container .modal-login .modal-login-button {
    width: 220px;
    background: #BAD0A2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-size: 14px;
    font-family: 'Montserrat SemiBold';
    text-align: center;
}

.confirm-payment-button {
    text-align: center;
    padding: 1rem
}

.confirm-payment-button button {
    padding: 1rem 5rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;
}

.confirm-payment-final {
    padding: 2rem 0rem;
    margin-bottom: 0.95rem;
}

.confirm-payment-card-title {
    color: #29292E;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: 'Recline Regular';
    padding-left: 1rem;
    padding-top: 2rem;

}

.confirm-payment-body {
    color: #29292E;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: 'Recline Regular';
    padding-left: 1rem;
    padding-top: 2rem;

}

.confirm-payment-total {
    display: flex;
    flex-direction: column;
    color: #29292E;

    font-weight: 600;
    font-family: 'Recline Regular';
    font-size: 13px;
    line-height: 20px;

}

.confirm-payment-subtotal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.2rem;
}

.confirm-payment-tasas {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.2rem;
    margin-top: 0.5rem;
}

.confirm-payment-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 3rem;
}

.confirm-payment-promotions {
    padding: 2rem 3rem 1rem;
    margin-bottom: 0.95rem;
    font-weight: 400;
    font-family: 'Recline Regular';
}

.confirm-payment-promotions button {
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    margin-left: 1.5rem;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0.5rem;
    line-height: 1.1rem;
}

.confirm-payment-results {
    padding: 1rem 3rem;
    margin-bottom: 0.95rem;
    font-weight: 400;
    font-family: 'Recline Regular';
    color: #979797;
}

.confirm-payment-card input {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    background-color: white !important;
    box-sizing: border-box;
    border: 0.07rem solid #E5E5E5;
    border-radius: 0.5rem;
    height: 2rem;
}

.promotional-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    max-height: fit-content;
    margin-top: 1rem;
}

.promotional-section input {
    width: 60%;
    outline: none;
    padding-left: 1rem;
    padding-right: 30px;
}

.promotional-section button {
    width: 35%;
    text-align: center;
    margin-top: 0;
}

.total-price {
    font-family: 'Recline Regular';
    font-weight: 600;
    font-size: 25px;
    line-height: 39px;
    text-align: justify;
    text-transform: uppercase;
    color: #29292E;
}

@media only screen and (max-width: 496px) {
    .confirm-payment-card {
        width: 100%;
    }
    .confirm-payment-card-header {        
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
      .confirm-payment-card-title {
        color: #29292E;
        font-size: 1.2rem;
        font-weight: 600;
        font-family: 'Recline SemiBold';
        padding-left: 1rem;
        padding-top: 2rem;
        border-top-left-radius: none;
        border-top-right-radius: none;
    }

    .modal-login-container .modal-login {
        padding: 2rem 2rem;
        left: 10%;
        width: 80%;
    }

    .modal-login-container .modal-login .modal-login-text {
        font-size: 18px;
        line-height: 22px;
    }

    .modal-login-container .modal-login .modal-login-button {
        width: 210px;
        margin-top: 1.75rem;
        padding: 0.75rem;
        background: #BAD0A2;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        font-size: 12px;
        font-family: 'Montserrat SemiBold';
        text-align: center;
    }
}

.short-divider {
    margin-left: 2rem;
    margin-right: 2rem;
}
