
.profile-body-title {
  font-family: 'Recline Medium';    
  font-weight: 500;
  font-size: 31.25px;
  line-height: 48px;
  text-transform: capitalize;    
  color: #29292E;
  text-align: center; 
  margin-top: 4rem;
  margin-bottom: 3.7rem;   
}
.dots{
  font-size: 40px !important;
}
.nodotos{
  font-size: 14px !important;
}
/* #newPassword{
  font-size: 40px;
} */
#confPassword{
  font-size: 40px;
}

.data-form {    
    margin: 0 auto;
    margin-bottom: 100px;
}
.data-form form {
    width: 70%;
    margin: 0 auto;    
}

.data-button {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 52px;
    
}

.data-form button {
    width: 242px;
    height: 48px;
    background: #BAD0A2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
       
}
.data-form button :active {
  color: #29292E; 
}

  .password-security{
    font-size: 40px !important;
    color: #29292E;
  }
  .data-row-double-input input{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42rem;
    background-color: white !important;
    color: #29292E;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    height: 4rem;
    padding-left: 1rem;
    margin-top: 0.6rem;
    margin-bottom: 30px;
  }
  .data-row-double-input .colored input{
    background-color: #E5E5E5 !important;
  }

  .data-row-double-input label {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #29292E;
  } 
  .data-row-double-input option{
    padding: 100px;
  }
  .data-row-double-input select{
    height: 56px !important;
    font-size: 16px;
}
  .data-row-double-input{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .personal-data-forms-form{
    max-width: 884px;
  }
  .data-input-short1 {
    display:flex;
    flex-direction: column;
    width: 49.5%;
  }
  .data-input-short2 {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49.5%;
    
  }
  .data-input-long{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  
  
  
    @media only screen and (max-width: 768px) {
      .data-row-double-input{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .data-input-long{
        margin-bottom: 24px;
      }
      .data-input-short1 {      
        margin-bottom: 24px;
        width: 100%;
      }
      .data-input-short2 {    
        margin-bottom: 24px;  
        width: 100%;
      }
      .personal-data-forms-form{
        width: 90% !important;
      }
      .data-form {    
        margin: 0 auto;
        margin: 0 0 0 0 !important;
      }
      
    }
  
    @media only screen and (max-width: 496px) {
      .form-container {
        
        width: 90%;
        margin :0 auto;
      }
      .data-row-double-input label {
        font-size: 14px;
      }
      .data-row-double-input input{
        font-size: 12px;
        font-weight: 400;
        line-height: 1.42rem;
        border: 1px solid #E5E5E5;
        height: 37px;
        padding-left: 1rem;
        margin-top: 0.2rem;
        margin-bottom: 0rem;
      }
      .nodotos{
        font-size: 12px !important;
      }
      .data-row-double-input select{
        font-size:12px !important;
      }
      
    }
  
    .without-label{
      vertical-align: middle;
    }
  
    .instructions{
      background-color: antiquewhite;
      color: #29292E;
    }
    .invalidInput input{
      border: 0.07rem solid red;
      border-radius: 0.5rem;
    }
