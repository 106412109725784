.product-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  padding: 0 1.5rem;
  justify-content: center;
}

.cards-containers {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 5rem;
}

@media (max-width: 768px) {
  .product-container {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 1rem;
    grid-row-gap: 5rem;
    margin: 0 auto;
  }

  .cards-containers {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
  }

}

@media (min-width: 769px) and (max-width: 1200px) {
  .product-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1rem;
    grid-row-gap: 2rem;
    margin: 0 auto;
  }

  .cards-containers {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
  }

}