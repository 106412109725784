.profile-body-title-plansRecord {
    font-family: 'Recline Regular';    
    font-weight: 500;
    font-size: 31.25px;
    line-height: 36px;
    text-transform: capitalize;    
    color: #29292E;
    text-align: center; 
    margin-top: 4rem;
    margin-bottom: 3.7rem;   
  }

  .plans-record-container {
    background-color: white;
    width: 90%;
    height: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    
}

  .plans-record-downloader {
    display: flex;
    align-items: center;
    background-color: white;
    font-family: 'Montserrat'!important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
    text-align: left;
    color: #0B49EB;
    margin: 0 !important;
    padding: 5px !important;
    
    
  }

  .plans-record-downloader img {
    padding-right: 5px;
  }

  .not-plan-text-container{
    margin-top: 42px;
    display:flex;
    justify-content: center;
  }

  .not-plan-text{
    padding-top:24px;
    padding-bottom:24px;
    padding-left:74px;
    padding-right:74px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-size: 20px;
    font-family:Montserrat;
    font-weight:400;
    line-height:28px;
    text-align:center;
  }
  

  @media screen and (max-width: 768px) {
    .plans-subscription-table-only-desktop{
      display:none !important;
    }
    .plans-record-table-card-mobile>tr>td{
      padding-left: 1rem;
      border-bottom: none;
    }
    .tdBottom{
      border-bottom: 1px solid #B1B2B2 !important;
    }
    .plans-record-downloader {      
      font-size: 12px !important;
      margin: 0 !important; 
      padding: 0 !important;    
    }
    .plans-record-table-card-mobile {
      box-sizing: border-box;
      padding: 8px;
      height: 170px;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #B1B2B2;
    }
    .not-plan-text{
      padding-top:1rem;
      padding-bottom:1rem;
      padding-left:3rem;
      padding-right:3rem;
      font-size: 16px;
      font-family:Montserrat;
      font-weight:500;
      line-height:24.8px;
    }
  }
  

