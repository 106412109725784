.testimonial-container {
    display: flex !important;
    flex-direction: row;
    align-items: flex-start;
    mix-blend-mode: normal;
}

.testimonial-img {
    width: 50%;
    padding-left: 15%;
}

.description-container {
    padding-right: 7%;
    text-align: left;
    width: 50%
}

.description-container h1 {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 40px;
    line-height: 26px;
    color: #29292E;
}

.description-container{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #29292E;
    padding-top: 1rem;
}

.description-container h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #29292E;
    margin: 1rem 0
}

.subtitle {
    font-weight: bold;
}

.description-container span {
    font-weight: bold;
}

.read-more {
    background: #FFF6F0;
    border: 1px solid #29292E;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;

    padding: 1rem 2rem;
    border: 1px solid #29292E;
    text-align: left
}

.description-product-container .slick-list {
    overflow: hidden;
}

.slick-list {
    margin-bottom: 24px !important;
}

.read-more-product {
    border: 1px solid #29292E;
    border-radius: 5px;

    padding: 0.5rem 1rem;
    text-align: left
}

.flex-container-title-button {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 26px;
}

.flex-container-title-button1 {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 22px;
}

.flex-container-title-button1 .subtitle {
    font-family: 'Montserrat SemiBold';
    font-size: 16px;
    line-height: 26px;
    color: #29292E;
}

.flex-container-title-button h2{
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 25px;
    line-height: 26px;
    color: #29292E;
    margin-top:0;
    margin-bottom: 0;
}

.read-more p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #29292E;
}

.custom-dot-testimonios {
    width: 1rem;
    height: 1rem;
    background-color: white;
    border: 0.03rem solid #BAD0A2;
    margin: 0 5px;
    border-radius: 50%;
}

.slick-active .custom-dot-testimonios {
    background-color: #BAD0A2;
}

.slidertestimonials-home-dots li {
    position: relative;
    display: inline-block;
    width: 5px;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    cursor: pointer;
    top: 0px !important;
}

.image-post {
    width: 350px;
    height: 270px;
    position: relative;
}

@media (max-width: 475px) {
    .testimonial-container {
        flex-direction: column;
    }

    .flex-container-title-button1 h2 {
        font-size: 13px;
    }

    .description-container {
        padding-right: 3%;
        width: 90%
    }

    .description-container h1 {
        font-size: 20px;
    }

    .description-container h3 {
        font-size: 12.8px;
    }

    .testimonials-home-content .slick-next {
        top: 15% !important;
        right: 1rem !important;
        height: 25.5px !important;
        width: 25px !important;
    }

    .testimonials-home-content .slick-prev {
        top: 15% !important;
        left: 1rem !important;
        height: 25.5px !important;
        width: 25px !important
    }
}

@media (min-width: 475px) and (max-width: 768px) {
    .testimonials-home-content .slick-next {
        top: 15% !important;
        right: 1rem !important;
        height: 25.5px !important;
        width: 25px !important;
    }

    .testimonials-home-content .slick-prev {
        top: 15% !important;
        left: 1rem !important;
        height: 25.5px !important;
        width: 25px !important
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .flex-container-title-button1 {
        margin-top: 10px;
    }

    .flex-container-title-button h1{
        margin-bottom: 0 !important;
    }

    .testimonials-home-content .slick-next {
        top: 15% !important;
        right: 1rem !important;
        height: 25.5px !important;
        width: 25px !important;
    }

    .testimonials-home-content .slick-prev {
        top: 15% !important;
        left: 1rem !important;
        height: 25.5px !important;
        width: 25px !important
    }
}
