.product-details-container {
    background-color: white;
    width: 100%;
    height: auto;
}

.trainer-instagram-logo {
    width: 2rem;
    height: auto;
}

.header-img img {
    height: 265px;
    width: 100%;
}

a.here {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

.header-img .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #29292E;
    mix-blend-mode: multiply;
    opacity: 0.3;
    z-index: 9;
}

.header-product-container {
    position: relative;
}

.product-details-header-container {
    position: relative;
}

.product-details-header-text {
    position: absolute;
    top: 45%;
    left: 2%;
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 48.83px;
    line-height: 50px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 14;
    width:70%;
}

.product-details-header-text-workshop {
    position: absolute;
    top: 20%;
    left: 25%;
    right: 25%;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 14;
}

.workshop-title {
    font-family: 'Recline Medium';
    font-size: 48px;
    line-height: 52px;
    padding-left: 6rem;
}

.workshop-subtitle {
    font-family: 'Recline Medium';
    font-size: 31px;
    line-height: 35px;
    padding-left: 6rem;
}

.workshop-text {
    font-family: 'Recline Medium';
    font-size: 20px;
    line-height: 30px;
}

.header-text-small {
    width: 100%;
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
    color: #FFFFFF;
    text-align:left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top:7px;
}

.header-product-text {
    position: absolute;
    top: 25%;
    left: 5%;
    font-family: 'Recline Regular';
    text-align: left;
    font-weight: 500;
    font-size: 48.83px;
    line-height: 50px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header-product-text-small {
    font-weight: 400;
    font-size: 25px;
    line-height: 39px;
}

.header-img img {
    display: block;
    background-size: contain;
    width: 100%;
}

.team-other-products-row h2 {
    font-family: 'Recline Medium';
    font-weight: 400;
    font-size: 31.25px;
    line-height: 48px;
    color: #29292E;
}

.description-variants-content h2 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 25px;
    line-height: 39px;
    color: #29292E;
    padding-top: 3rem;
}

.description-variants-content h1 {
    width:90%;
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 25px;
    line-height: 39px;
    color: #29292E;
    margin-top: 0.25rem;
}

.description-row {
    display: flex;
    flex-wrap: wrap;
}

.team-other-products-row {
    margin-left: 3rem;
    margin-bottom: 3rem;
    margin-right: 3rem;
    width: 68%;
}

.description-sesion {
    margin-left: 3rem;
    margin-bottom: 3rem;
    margin-right: 3rem;
    width: 68%;
}

.description-variants-content {
    background: rgba(249, 255, 240, 0.7);
}

.description-variants-content-2 {
    display: flex;
    flex-direction: row;
    width: 70%;
    margin-left: 1rem;
}

.description-variants-content-1 {
    display: flex;
    flex-direction: column;
    width: 69%;
    justify-content: center;
    text-align: center;
    margin-left: 1rem;
}

.product-sesion {
    max-width: 475px;
    min-width: 295px;
    position: fixed; 
    overflow-y : auto;
    top: 5.5rem;
    margin-bottom: 3rem;
    width: 30%;
    right: 0;
    padding-left: 1rem;
    order: 1;
    z-index: 20;
    max-height: 90vh;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}

.product-sesion .card-content {
    background: #FFFFFF;
    border: 1px solid rgba(177, 178, 178, 0.7);
    box-shadow: 0px 4px 4px rgba(7, 42, 68, 0.15);
    border-radius: 10.9746px;
    display: flex;
    flex-direction: column;
    width: 95%;
    height: auto;
}
.product-sesion::-webkit-scrollbar {
    display: none;
  }

.description-sesion h1 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 31.25px;
    line-height: 48px;
    color: #29292E;
}

.description-sesion h6 {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 20px;
    line-height: 31px;
    color: #29292E;
    margin-top: 3rem;
}

.variations-container {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 20px;
    line-height: 31px;
    color: #29292E;
    margin-bottom: 3rem;
    margin-top: 2rem;
    width: 100%;
}

.custom-flex-container {
    display: flex;
    flex-direction: row;
}

.custom-flex-container div {
    flex-grow: 1;
}

.accordion-details {
    padding: 3rem;
    width: 70%;
    order: 2;
}

.accordion-details h2 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 31.25px;
    line-height: 48px;
    color: #29292E;
    text-align: center;
    margin-bottom: 3rem;
}

.title {
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 25px;
    line-height: 39px;
    text-align: center;
    color: #29292E;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.button-title {
    font-size: 16px;
    font-weight: 400;
    border-radius: 0.3rem;
    margin-bottom: 1.5rem;
    width: 30%;

}

.small-price {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 24px;
    line-height: 37px;
    color: #29292E;
}

.container-training-add-check {
    margin: 0;
}
.container-training-add-check-disabled span:nth-child(2){
    color: #a29a9a;
}

.container-training-add .MuiFormControlLabel-root .Mui-checked {
    color: #BAD0A2;
}

.container-training-add .MuiFormControlLabel-label {
    font-family: 'Montserrat Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: #29292E;

}

.subscription-price-details {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #29292E;
    text-align: right;
    margin-top: -26px;
}

.price-plus {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10.24px;
    line-height: 12px;
    text-align: justify;
    text-transform: uppercase;
    color: rgba(17, 17, 17, 0.5);
}

#demo-multiple-checkbox-label {
    background-color: #E5E5E5;
    font-size: 16px;
    font-weight: 400;
}

.card-content-product-details {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
}

.training-container h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
}
.training-container fieldset {
    margin-top: 0.5rem;
    padding: 0 0.6rem;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    opacity: 1;
    transform: translateY(0);
}

.training-container.fieldset-fade-out {
    opacity: 0;
    transform: translateY(20px);
}
.training-container fieldset span {
    display: flex;
    align-items: center;
}

.training-container img {
    height: 15px;
    margin-left: 0.5rem;
}

.training-group-button .Mui-checked {
    color: #BAD0A2 !important;
}

.training-option-label .MuiFormControlLabel-label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #29292E;
}

.training-option-label {
    margin-top: 0;
}

.help {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
}

.help h6 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10.24px;
    line-height: 14px;
    align-items: center;
    color: #29292E;
    margin-left: 0.5rem;
}

.divider-green {
    border-top: 1px solid #314037;
    margin: 1rem 0;
}
.divider-green-options {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid rgba(49, 64, 55, 1); 
    line-height: 0.1em;
    margin: 15px 0 15px; 
}
.divider-green-options span{
    background:#fff; 
    padding:0 10px;
    color: #29292E;
    font-family: 'Montserrat Semibold';
}

.large-myproduct {
    font-family: 'Recline Regular';
    font-weight: 600;
    line-height: 80px;
    color: #29292E;
    text-align: center;
    font-size: 61px;
}

.dashed-line {
    border-top: 1px dashed #D8D8D8;
    margin-bottom: 1rem;
}

.btn-myproduct {
    color: #29292E;
    text-align: center;
    background-color: #BAD0A2;
    margin: 0 auto 0.75rem;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: center;
    color: #29292E;
}

.help a:visited {
    color: blue;
}

.training-container span {
    color: #B1B2B2;
}
.training-container span .GYM {
    font-family: Montserrat;
font-weight: 600;
font-size: 12px;
letter-spacing: 0em;
text-align: justified;
    color: rgba(177, 178, 178, 1);
}
.container-training-add {
    background: #FFFFFF;
    border: 1px solid rgba(233, 233, 233, 0.5);
    box-shadow: 0px 8px 36px rgba(7, 42, 68, 0.04);
    border-radius: 10.9746px;
    padding: 0.5rem 0.5rem;
}

.couple-plan span {
    color: #B1B2B2;
}

.couple-plan {
    background: #FFFFFF;
    border: 1px solid rgba(233, 233, 233, 0.5);
    box-shadow: 0px 8px 36px rgba(7, 42, 68, 0.04);
    border-radius: 11px;
    padding: 0.5rem 1rem;
}

.couple-plan .MuiFormControlLabel-label {
    font-family: 'Montserrat Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #29292E;
}

.couple-plan .MuiFormControlLabel-root {
    margin-top: 0rem;
}

.help-you {
    background: #FBFFF5;
    padding: 1rem 2rem;
    order: 2;
    margin: 24px 0 0 0;
}

.help-you h3 {
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    text-transform: uppercase;
    color: #29292E;
    margin: 0.5rem 0;
}

.help-you h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #29292E;
    margin: 0.6rem 0 1rem 0;
}
.product-sesion .product-menu-title-card{
    text-align: center;
    margin: 13px 0 13px 0;
}
.product-sesion .product-menu-title-card span{
    color: rgba(41, 41, 46, 1);
    text-align: center;
    font-family: 'Montserrat Semibold';
    font-size: 16px;
    font-weight: 600;
    line-height: 10px; /* 122.222% */
    margin-bottom: 20px;
}

.help-you h5 {
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #29292E;
    text-align: center;
    margin: 0.5rem 0;
}

.gift {
    background: #FBFFF5;
    border: 1px solid #95A585;
    box-shadow: 0px 8px 36px rgba(7, 42, 68, 0.04);
    border-radius: 10.9746px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 1.5rem 1rem;
    margin: 3rem 1rem 3rem 1rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gift-mobile {
    background: #FBFFF5;
    border: 1px solid #95A585;
    box-shadow: 0px 8px 36px rgba(7, 42, 68, 0.04);
    border-radius: 10.9746px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 1.5rem 1rem;
    margin: 3rem 1rem 3rem 1rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    order: 1;
    display: none;
}

.gift-link {
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #29292E;
    backdrop-filter: blur(2px);
    margin-left: 0.5rem;
}

.Mui-checked span {
    color: #BAD0A2;
}

.Mui-checked {
    color: #BAD0A2;
}

.couple-plan .MuiFormControlLabel-root .Mui-checked {
    color: #BAD0A2;
}

.help {
    display: flex;
}

.title-sesion {
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 25px;
    line-height: 39px;
    color: #29292E;
    text-align: center;
    margin-top: 5rem;
}

.details-description-plan p {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #29292E;
    text-transform: none;
}

.details-description-plan .MuiButton-text {
    justify-content: left;
    text-align: left;
}

.desciption-button p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    color: #29292E;

}

.progress-bar-description {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100%;
}

.our-team {
    width: 65%;
    margin-left: 4rem;
    margin-bottom: 3rem;
    margin-right: 3rem;
}

.our-team-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3rem;
}

.image-trainer {
    padding: 1rem 0;
    margin-right: 1rem;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: 1;
    color: #CFDEBE;
    margin-left: -3.5px;
    margin-top: 0px;
    -webkit-font-smoothing: antialiased;
}

.our-team-card {
    background-color: rgba(255, 255, 255, 0.7);
    mix-blend-mode: normal;
    border: 1px solid #DDE8D1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 2rem 2rem;
    margin-top: 0.5rem;
    display: flex !important;
    flex-direction: row;
    margin-right: 0 2rem;
}

.ourteamcard {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row-trainer {
    display: flex;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.7);
    mix-blend-mode: normal;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #DDE8D1;
    border-radius: 20px;
    padding: 0rem 0.5rem;
    justify-content: center;
    height: 105px;
    width: 380px;
    margin: 0 auto;
    cursor: pointer;
}


.image-trainer img {
    width: 60px;
    height: auto;
    max-height: 75px;
    margin-left: 6px;
    border-radius: 16px;
}

.instagram-trainer {
    padding-top: 1rem;
    width: 10%;
}

.name-position-trainer {
    padding-top: 24px;
    padding: 1rem 0rem;
    width: 70%;
}

.name-position-trainer h3 {
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 25px;
    line-height: 26px;
    color: #29292E;
}

.name-position-trainer h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #29292E;
}

.complete-testimonial-button img {
    display: inline-block;
}

.slidertestimonials-product-dots li {
    position: relative;
    display: inline-block;
    width: 5px;
    height: 20px;
    margin: 0 20px;
    padding: 0;
    cursor: pointer;
    top: 2rem !important;
}

.slidertestimonials-product-dots {
    position: relative;
    cursor: pointer;
    top: -50px !important;
    text-align: center;
}

.product-cards-containers {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
    /* margin-left: 2rem; */
    justify-content: center;
}

.card-content-product-container {
    background: #FFFFFF;
    box-shadow: 0px 8px 36px rgba(7, 42, 68, 0.15) !important;
    border-radius: 10.9746px !important;
}

.description-product-interesting {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10.24px;
    line-height: 16px;
    text-align: center;
    color: #29292E;
}

.letter-type-interesting-center {
    text-align: center;
}

.letter-type-interesting {
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 25px;
    line-height: 39px;
    color: #29292E;
}

.btn-product-interesting {
    color: #29292E;
    text-align: center;
    background-color: #BAD0A2;
    margin: 0 auto;
    border-radius: 0.5rem;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: center;
    color: #29292E;
    padding: 0;
}

.spacer-interesting {
    padding-left: 2rem;
    padding-right: 2rem;
    color: #29292E;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.variants-options {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12.1px;
    line-height: 26px;
    color: #29292E;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.circular-variants-icon img {
    border-radius: 50%;
    width: 10px;
}

.no-underline {
    text-decoration: none;
}

.underline {
    text-decoration: underline;
}

.spacer-prod {
    color: #29292E;
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    margin: 0.5rem 4rem;
}

.slidertestimonials-product-dots li {
    position: relative;
    display: inline-block;
    width: 5px;
    height: 20px;
    margin: 0 20px;
    padding: 0;
    cursor: pointer;
    top: 2rem !important;
}

.slidertestimonials-product-dots {
    position: relative;
    top: -40px !important;
    text-align: center;
}

.our-testimonials .testimonial-complete-container {
    background: rgba(249, 255, 240, 0.7);
}

.our-testimonials .description-complete-container .read-more-product p {
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #29292E;
}

.our-testimonials .description-complete-container p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #29292E;
}

.our-testimonials .description-complete-container span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #29292E;
}

.our-testimonials .description-complete-container .complete-testimonial-button p {
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 700;
    font-size: 12.8px;
    line-height: 20px;
}

.read-more-product-end-flex {
    display: none;
}

.our-team h2 {
    font-family: 'Recline Medium';
    font-weight: 400;
    font-size: 31.25px;
    line-height: 48px;
    color: #29292E;
}

.our-team-content .slick-next {
    top: 43% !important;
}

.our-team-content .slick-prev {
    top: 43% !important;
}

.workshop-container{
    border: 0px solid #B1B2B2;
    background: #FFFFFF;
    border-top: 4px solid #BAD0A2;
    box-shadow: 0px 4px 4px #BAD0A2 !important;
    border-radius: 11px !important;
    margin: 3rem 5rem !important;
    padding: 1rem 1rem 1rem !important;
    color: #29292E;
}

.workshop-container p{
    color: #29292e;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -.03em;
    line-height: 28px;
    margin-right: 1rem;
}

.workshop-container a{
    color:#0B49EB;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.03em;
    line-height: 28px;
    margin-right: 1rem;
}

.workshop-container h2{
    text-align: center;
    color: #29292e;
    font-family: Montserrat SemiBold;
    font-weight: 700;
    font-size: 24px;
    font-style: normal;
    letter-spacing: -.03em;
    line-height: 28px;   
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.workshop-container-title{
    display: flex;
    margin-bottom: 1.5rem;
}

.workshop-container-title h6{
    font-family: Recline Regular;
    font-size: 30px;
    font-weight: 400;
    line-height: 39px;
    margin-left: 1rem;
    text-align: justify;
}

.workshop-container-title img{
    min-height: 32px;
}


@media only screen and (min-width: 768px) and (max-width: 992px) {

    .spacer-prod {
        font-size: 12px;
        line-height: 14px;
    }

    .custom-flex-container {
        display: flex;
        flex-direction: column;
    }

    .custom-flex-container {
        padding-left: 12px;
    }

    .custom-flex-item {
        margin-bottom: 8px;
    }

    .our-team {
        width: 68%;
        padding-right: 4%;
        padding-left: 0%;
        margin-left: 2rem;
    }

    .product-details-header-text-workshop {
        top: 15%;
        left: 20%;
        right: 20%;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 14;
    }

    .workshop-title {
        font-size: 46px;
        line-height: 50px;
    }

    .workshop-subtitle {
        font-size: 30px;
        line-height: 34px;
    }

    .workshop-text {
        font-size: 19px;
        line-height: 28px;
    }

}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
    .megafono {
        position: absolute;
        top: -1rem;
        left: 0;
        height: 120px;
        width: 120px;
    }

    .our-team {
        width: 69%;
        margin-left: 1rem;
        margin-right: 2rem;
    }

    .others-products {
        width: 92%;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .variations-container {
        font-family: 'Recline Regular';
        font-weight: 400;
        font-size: 16px;
        line-height: 31px;
        color: #29292E;
        margin-bottom: 3rem;
        margin-top: 2rem;
        width: 100%;
    }

    .variants-options {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 11.4px;
        line-height: 22px;
        color: #29292E;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .header-img {
        height: 250px;
        background-size: cover;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .header-img {
        height: 220px;
        background-size: cover;
    }

    .others-products {
        width: 99%;
        margin-left: -2rem;
        margin-right: 1rem;
    }

    .workshop-title {
        font-family: 'Recline Medium';
        font-size: 40px;
        line-height: 48px;
        padding-left: 6rem;
    }

    .workshop-subtitle {
        font-family: 'Recline Medium';
        font-size: 28px;
        line-height: 32px;
        padding-left: 6rem;
    }

    .megafono {
        position: absolute;
        top: -1rem;
        left: 0;
        height: 120px;
        width: 120px;
    }

    .product-details-header-text {
        top: 35%;
    }
}
@media (max-width: 1580px) {
    .training-option-label .MuiFormControlLabel-label {
        /* font-size: 13px !important; */
    } 
    .psycho-bono-label-reduced-price {
        font-size: 10px !important;
    }
}
@media (max-width: 1880px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 12px !important;
    } 
    .psycho-bono-labels{
        font-size: 12px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 8px !important;
    }
    .training-container span .GYM {
        font-size: 10px !important;
    }
    .psycho-bono-label-reduced-prices{
        font-size: 12px !important;
    }
    
}
@media (max-width: 1425px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 13px !important;
    } 
    .psycho-bono-labels{
        font-size: 13px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 10px !important;
    }
    .training-container span .GYM {
        font-size: 11px !important;
    }
    .psycho-bono-label-reduced-prices{
        font-size: 11px !important;
    }
}
@media (max-width: 1375px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 11.5px !important;
    } 
    .psycho-bono-labels{
        font-size: 11.5px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 9px !important;
    }
    .training-container span .GYM {
        font-size: 9.5px !important;
    }
    .psycho-bono-label-reduced-prices{
        font-size: 9.5px !important;
    }
}
@media (max-width: 1228px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 10.5px !important;
    } 
    .psycho-bono-labels{
        font-size: 10.5px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 8.5px !important;
    }
}
@media (max-width: 1170px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 9.5px !important;
    } 
    .psycho-bono-labels{
        font-size: 9.5px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 8.5px !important;
    }
}
@media (max-width: 1105px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 8.5px !important;
    } 
    .psycho-bono-labels{
        font-size: 8.5px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 7.5px !important;
    }
    .training-container span .GYM {
        font-size: 7.5px !important;
    }
}

.css-9l3uo3 {
    letter-spacing: 0px !important;
}
@media (max-width: 1495px) {
    .training-option-label .MuiFormControlLabel-label {
        /* font-size: 9px !important; */
    } 
    .large-myproduct{
        font-size: 61px;
    }
    .FromMenuProduct{
        margin-left: -10px;
    }
}
@media (max-width: 1100px) {
    .training-option-label .MuiFormControlLabel-label {
        /* font-size: 12px !important; */
    } 
    .FromMenuProduct{
        margin-left: -15px;
    }
    .training-option-label .MuiFormControlLabel-label {
        font-size: 8.5px !important;
    } 
    .psycho-bono-labels{
        font-size: 8.5px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 7.5px !important;
    }
    .training-container span .GYM {
        font-size: 7.5px !important;
    }
}
@media (max-width: 1020px) {
    .training-option-label .MuiFormControlLabel-label {
        /* font-size: 12px !important; */
    } 
    .FromMenuProduct{
        margin-left: -15px;
    }
    .training-option-label .MuiFormControlLabel-label {
        font-size: 8px !important;
    } 
    .psycho-bono-labels{
        font-size: 8px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 7px !important;
    }
    .training-container span .GYM {
        font-size: 7px !important;
    }
}

@media (max-width: 760px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 14px !important;
    } 
    .psycho-bono-labels{
        font-size: 14px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 10px !important;
    }
    .training-container span .GYM {
        font-size: 12px !important;
    }
    .psycho-bono-label-reduced-prices{
        font-size: 12px !important;
    }
    .help-you {
        margin: 0px 0 0 0;
    }
    .product-sesion {
        max-width: 475px;
        min-width: 295px;
        position: fixed; 
        overflow-y : auto;
        top: 5.5rem;
        margin-bottom: 3rem;
        width: 30%;
        right: 0;
        padding-left: 1rem;
        order: 1;
        z-index: 20;
        max-height: none;
        -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none; 
    }
}

@media (max-width: 400px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 12px !important;
    } 
    .psycho-bono-labels{
        font-size: 13px !important;
    } 
    .training-container span .GYM {
        font-size: 11px !important;
    }
    .psycho-bono-label-reduced-prices{
        font-size: 11px !important;
    }
}
@media (max-width: 383px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 12px !important;
    } 
    .psycho-bono-labels{
        font-size: 13px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 6.8px !important;
    }
    .psycho-bono-label-reduced-prices{
        font-size: 11px !important;
    }
    .training-container span .GYM {
        font-size: 11px !important;
    }
}
@media (max-width: 348px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 11px !important;
    } 
    .psycho-bono-labels{
        font-size: 11px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 6.5px !important;
    }
    .psycho-bono-label-reduced-prices{
        font-size: 9px !important;
    }
    .training-container span .GYM {
        font-size: 9px !important;
    }
}
@media (max-width: 332px) {
    .training-option-label .MuiFormControlLabel-label {
        font-size: 10px !important;
    } 
    .psycho-bono-labels{
        font-size: 10px !important;
    } 
    .psycho-bono-label-reduced-price{
        font-size: 6.2px !important;
    }
    .psycho-bono-label-reduced-prices{
        font-size: 8px !important;
    }
    .training-container span .GYM {
        font-size: 8px !important;
    }
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .megafono {
        position: absolute;
        top: -1rem;
        left: 6rem;
        height: 120px;
        width: 120px;
    }
   
    .header-img {
        height: 290px;
        background-size: cover;
    }

    .accordion-details {
        width: 68%;
        padding-left: 6%;
    }

    .our-team {
        width: 68%;
        padding-right: 4%;
        padding-left: 0%;
        margin-left: 3rem;
    }

    .others-products {
        width: 78%;
        margin-left: 8%;
        margin-right: 1rem;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1900px) {
    .megafono {
        position: absolute;
        top: -1rem;
        left: 8rem;
        height: 150px;
        width: 150px;
    }

    .workshop-title {
        font-family: 'Recline Medium';
        font-size: 48px;
        line-height: 50px;
        padding-left: 6rem;
        margin-bottom: 2rem;
    }

    .workshop-subtitle {
        font-family: 'Recline Medium';
        font-size: 34px;
        line-height: 36px;
        padding-left: 6rem;
        margin-bottom: 1.5rem;
    }

    .header-img {
        height: 340px;
        background-size: cover;
    }

    .accordion-details {
        width: 66%;
        padding-left: 8%;
    }

    .our-team {
        width: 66%;
        padding-right: 6%;
        padding-left: 6%;
    }

    .others-products {
        width: 70%;
        margin-left: 12%;
        margin-right: 1rem;
    }
}

@media only screen and (min-width: 1900px){
    .megafono {
        position: absolute;
        top: -1rem;
        left: 8rem;
        height: 150px;
        width: 150px;
    }

    .workshop-title {
        font-family: 'Recline Medium';
        font-size: 48px;
        line-height: 50px;
        padding-left: 6rem;
        margin-bottom: 2rem;
    }

    .workshop-subtitle {
        font-family: 'Recline Medium';
        font-size: 34px;
        line-height: 36px;
        padding-left: 6rem;
        margin-bottom: 1.5rem;
    }

    .header-img {
        height: 400px;
        background-size: cover;
    }

    .accordion-details, .others-products {
        width: 66%;
        padding-left: 8%;
    }

    .others-products {
        width: 68%;
        margin-left: 11%;
        margin-right: 1rem;
    }
}

@media only screen and (max-width: 768px) {

    .accordion-details li {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .accordion-details p {
        margin-bottom: 4px !important;
    }

    .accordion-details ol{
        padding-left: 22px !important;
    }

    .workshop-title {
        font-family: 'Recline Medium';
        font-size: 36px;
        line-height: 38px;
        padding-left: 6rem;
    }

    .workshop-subtitle {
        font-family: 'Recline Medium';
        font-size: 22px;
        line-height: 24px;
        padding-left: 6rem;
    }

    .megafono {
        position: absolute;
        top: -1rem;
        left: 0;
        height: 120px;
        width: 120px;
    }

    .team-other-products-row div.title-sesion {
        margin-top: 1rem;
    }

    .product-details-header-text-workshop {
        top: 5%;
        left: 6%;
        right: 4%;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 14;
    }

    .workshop-text {
        font-size: 17px;
        line-height: 22px;
    }

    .spacer-prod {
        font-size: 12px !important;
        line-height: 14px !important;
    }

    .product-testimonials {
        width: 100% !important;
    }

    .custom-flex-container {
        display: flex;
        flex-direction: column;
    }

    .custom-flex-container {
        padding-left: 12px;
    }

    .custom-flex-item {
        margin-bottom: 8px;
    }

    .small-price {
        font-size: 16px;
        line-height: 25px;
    }

    .subscription-price-details {
        font-size: 14px;
        line-height: 22px;
        margin-right: 4rem;
    }

    .spacer-prod {
        font-size: 8.19px;
        line-height: 10px;
        margin: 0.5rem 3rem;
    }

    .gift-mobile {
        display: flex;
        padding: 1rem 1rem;
    }

    .gift-mobile a {
        color: #29292E;
        font-size: 14px;
        line-height: 22px;
    }

    .gift {
        display: none;
    }

    .product-details-header-text {
        top: 3rem;
        left: 2%;
        font-size: 25px;
        line-height: 16px;
        padding: 0 1rem;
        width:100%;
    }

    .product-details-header-text-workshop p {
        margin-bottom: 1rem;
    }

    .header-text-title{
        padding-bottom:1rem;
    }

    .header-text-small {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        display:inline;
    }

    .header-img {
        height: 160px;
        background-size: cover;
    }

    .header-img img {
        display: none;
        height: 165px;
        width: 100%;
    }

    .header-img .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #29292E;
        mix-blend-mode: multiply;
        opacity: 0.3;
    }

    .description-variants-content-1 {
        width: 100%;
        margin: 0rem;
    }

    .description-variants-content h2 {
        font-family: 'Recline Medium';
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #29292E;
        margin-left: 0rem;
        padding: 0 1rem;
        margin-top: 0.75rem;
    }

    .description-variants-content h1 {
        width:100%;
        font-family: 'Recline Medium';
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #29292E;
        margin-left: 0rem;
        padding: 0 1rem;
        margin-top: 0.25rem;
        margin-bottom: 1rem;
    }

    .our-team h2 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        font-family: 'Recline Medium';
    }

    .variations-container {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 1rem;
        margin-top: 0rem;
    }

    .product-sesion {
        max-width: unset;
        width: 100%;
        margin-left: 0;
        position: unset;
        padding-left: 0rem;
    }

    .product-sesion .card-content {
        background: #FFFFFF;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .card-content {
        margin-left: 0rem;
        margin-right: 0em;
        background: #FFFFFF;
        box-shadow: none;
        border-radius: 0;
    }

    .card-content-product-details {
        margin-bottom: 2rem;
    }

    .help-you h3 {
        font-family: 'Montserrat SemiBold';
        font-size: 14px;
        line-height: 20px;
        color: #29292E;
    }

    .help-you h4 {
        font-size: 12.8px;
        color: #29292E;
    }

    .help-you h5 {
        font-size: 10.55px;
        line-height: 13px;
        color: #29292E;
    }

    .help h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }

    .details-description-plan p {
        font-size: 16px;
        line-height: 25px;
    }

    .training-option-label .MuiFormControlLabel-label {
        font-size: 12.8px;
        line-height: 16px;
    }

    .details-description-plan {
        width: 100%;
    }

    .desciption-button p {
        font-size: 12.8px;
        line-height: 26px;
    }

    .team-other-products-row {
        margin-left: 0rem;
        margin-bottom: 2rem;
        margin-right: 0rem;
        width: 100%;
    }

    .team-other-products-row h2 {
        font-size: 20px;
        line-height: 30px;
    }

    .team-other-products-row .others-products h2 {
        font-size: 20px;
        line-height: 30px;
        margin-top: -50px;
    }

    .read-more-product {
        display: none;
    }

    .others-products {
        padding: 0 2rem;
    }

    .read-more-product-end {
        padding: 0.5rem 1rem;
        text-align: left;
        margin-bottom: 4rem;
        border: 1px solid #29292E;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }

    .slidertestimonials-product-dots {
        top: -85px !important;
    }

    .slidertestimonials-product-dots li {
        position: relative;
        display: inline-block;
        width: auto;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        top: 2rem !important;
    }

    .slidertestimonials-product-dots .custom-dot-testimonios {
        width: 0.5rem;
        height: 0.5rem;
    }

    .our-team-content {
        width: 100%;
    }

    .our-team-content .slick-next {
        right: 0.5rem !important;
        top: 42% !important;
    }

    .our-team-content .slick-prev {
        left: 0.5rem !important;
        top: 42% !important;
    }

    .our-team {
        width: 94%;
        margin-left: 2rem;
        margin-bottom: 2rem;
        padding-right: 2rem;
        margin-right: 2rem;
    }

    .teamcard-container {
        margin: 0 auto;
        width: 100%;
        padding-bottom: 0px;
    }

    .works-container .slick-list {
        overflow: none;
    }

    .our-testimonials .testimonial-complete-container {
        flex-direction: column;
    }

    .testimonial-complete-container .description-complete-container {
        width: 100%;
    }

    .testimonial-complete-container .description-complete-container h3 {
        font-weight: 700;
        font-size: 12.8px;
        line-height: 26px;
    }

    .testimonial-complete-container .subtitle-change {
        margin-left: 1rem;
    }

    .our-testimonials .testimonial-complete-img {
        width: 45%;
        padding-left: 0;
        padding-top: 2rem;
        padding-bottom: 2rem;
        display: flex;
        justify-content: center;
    }

    .image-complete-post {
        width: 150px;
        height: 140px;
    }

    .read-more-product-end-flex {
        display: flex;
        justify-content: center;
    }

    .image-trainer img {
        width: 61px;
        height: 69px;
        border-radius: 20px;
        padding: 0;
        margin-left: 0.5rem;
    }

    .name-position-trainer h3 {
        font-family: 'Recline Medium';
        font-size: 16px;
        line-height: 26px;
        margin-left: 0em;
        text-align: left;
    }

    .name-position-trainer h5 {
        font-family: 'Montserrat Medium';
        font-size: 12.8px;
        line-height: 16px;
        margin-left: 0em;
        text-align: left;
    }

    .name-position-trainer {
        padding-top: 1rem;
    }

    .our-team-card-container {
        margin: 0 auto;
        width: 100%;
    }

    .our-team-card-container .slick-list {
        overflow: visible;
    }

    .name-position-trainer {
        padding: 1rem 0rem;
    }


    .row-trainer {
        height: auto;
        width: auto;
        margin: 0 2rem;
    }

    .workshop-container {
        margin: 2rem 3rem !important;
    }
    
    .workshop-container p {
        font-size: 12px;
        line-height:18px;       
    }

    .workshop-container h2 {
        font-size: 20px;      
    }

    .workshop-container-title h6 {
        text-align:center;
        font-size: 20px;
        line-height:initial;
    }

}

@media only screen and (max-width: 475px) {

    .workshop-title {
        font-size: 30px;
        line-height: 32px;
        padding-left: 5rem;
        margin-bottom: 0.75rem !important;
    }

    .workshop-subtitle {
        font-size: 18px;
        line-height: 22px !important;
        padding-left: 5rem;
        margin-bottom: 0.5rem !important;
    }

    .megafono {
        position: absolute;
        top: -1rem;
        left: -1rem;
        height: 100px;
        width: 100px;
    }


    .product-details-header-text-workshop {
        top: 6%;
        left: 2%;
        right: 1%;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 14;
    }

    .workshop-text {
        font-size: 14px;
        line-height: 18px;
    }

    .header-img img {
        height: 140px;
        width: 100%;
    }
}
