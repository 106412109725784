.slider {
    background-size: contain;
    width: 100%
}

.slider-container {
    position: relative;
    height: 350px;
    /*background-size: cover;*/
    /*background-position: center;*/
}

.slider-text {
    position: absolute;
    top: 25%;
    left: 7%;
    color: white;    
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 9999;
}

.slider-text h1 {
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 48.83px;
    line-height: 50px;
}

.slider-text-small h3 {
    font-size: 24px;
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 25px;
    line-height: 39px;
}

.slider-text button {
    background-color: #BAD0A2;
    padding: 1rem 4rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #29292E;
}

.slider-text a {
    text-decoration: none;
}

.slick-dots {
    bottom: -35px !important;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 20px;
    margin: 0 20px;
    padding: 0;
    cursor: pointer;
}

.custom-dot {
    width: 150px;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 20px;
    border-radius: 2px;
}

.slick-active .custom-dot {
    background-color: #fff;
}

.slider-img .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #29292E;
    mix-blend-mode: multiply;
    opacity: 0.3;
    z-index: 999;
}

@media only screen and (max-width: 380px) {
    .custom-dot {
        width: 20px;
        margin: 0 5px;
    }

    .slick-dots li {
        width: 20px;
        margin: 0 5px;
    }

    .slider-text a {
        display: ruby-base;
    }

    .slick-dots {
        bottom: -40px !important;
    }

    .slider-text button {
        font-weight: 600;
        font-size: 8.19px;
        padding: 0rem 3rem;
        margin-top: 0.75rem;
        width: 195px;
        height: 30px;
        font-family: 'Montserrat SemiBold';
    }

    .slider-text h1 {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .slider-text-small h3 {
        font-weight: 400;
        font-size: 12.5px;
        line-height: 16px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 253px;
        height: 27px;
    }

    .slider-text {
        top: 0.75rem;
        left: 1rem;
    }

    .slider-container {
        height: 140px;
    }

    .slider-img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .slider-img img {
        width: 100%;
        height: 100%;
    }

    .slider-img .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #29292E;
        mix-blend-mode: multiply;
        opacity: 0.3;
    }
}

@media only screen and (min-width: 380px) and (max-width: 475px) {
    .custom-dot {
        width: 20px;
        margin: 0 5px;
    }

    .slick-dots li {
        width: 20px;
        margin: 0 5px;
    }

    .slider-text a {
        display: ruby-base;
    }

    .slider-text button {
        font-weight: 600;
        font-size: 8.19px;
        padding: 0rem 3rem;
        margin-top: 0.75rem;
        width: 195px;
        height: 30px;
        font-family: 'Montserrat SemiBold';
    }

    .slider-text h1 {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .slider-text-small h3 {
        font-weight: 400;
        font-size: 12.5px;
        line-height: 16px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 260px;
        height: 27px;
    }

    .slider-text {
        top: 0.5rem;
        left: 1rem;
    }

    .slider-container {
        height: 145px;
    }

    .slider-img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .slider-img img {
        width: 100%;
        height: 100%;
    }

    .slider-img .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #29292E;
        mix-blend-mode: multiply;
        opacity: 0.3;
    }
}

@media only screen and (min-width: 475px) and (max-width: 768px) {
    .custom-dot {
        width: 20px;
        margin: 0 5px;
    }

    .slick-dots li {
        width: 20px;
        margin: 0 5px;
    }

    .slider-text a {
        display: ruby-base;
    }

    .slider-text button {
        font-weight: 600;
        font-size: 8.19px;
        padding: 0rem 3rem;
        margin-top: 2rem;
        width: 195px;
        height: 30px;
        font-family: 'Montserrat SemiBold';
    }

    .slider-text h1 {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .slider-text-small h3 {
        font-weight: 400;
        font-size: 12.5px;
        line-height: 16px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 260px;
        height: 27px;
    }

    .slider-text {
        top: 3rem;
        left: 1rem;
    }

    .slider-container {
        height: 210px;
    }

    .slider-img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .slider-img img {
        width: 100%;
        height: 100%;
    }

    .slider-img .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #29292E;
        mix-blend-mode: multiply;
        opacity: 0.3;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .custom-dot {
        width: 20px;
        margin: 0 5px;
    }

    .slick-dots li {
        width: 20px;
        margin: 0 5px;
    }

    .slider-text a {
        display: ruby-base;
    }

    .slider-text button {
        font-weight: 600;
        font-size: 9px;
        padding: 0rem 3rem;
        margin-top: 2rem;
        width: 195px;
        height: 34px;
        font-family: 'Montserrat SemiBold';
    }

    .slider-text h1 {
        font-weight: 500;
        font-size: 26px;
        line-height: 38px;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .slider-text-small h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 280px;
        height: 27px;
    }

    .slider-text {
        top: 3rem;
        left: 1rem;
    }

    .slider-container {
        height: 260px;
    }

    .slider-img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .slider-img img {
        width: 100%;
        height: 100%;
    }

    .slider-img .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #29292E;
        mix-blend-mode: multiply;
        opacity: 0.3;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
    .slider-container {
        height: 350px;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .slider-container {
        height: 400px;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1900px) {
    .slider-container {
        height: 450px;
    }
}

@media only screen and (min-width: 1900px) {
    .slider-container {
        height: 500px;
    }
}
