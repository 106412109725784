.cart-container {
    width: 100%;
    border-top: 0.1rem solid #C6C6C6;
    border-bottom: 0.1rem solid #C6C6C6;
    margin-bottom: 3rem;
    padding-bottom: 0.5rem;
}

.cart-product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;
    color: #29292E;
    height: 90px;
}

.cart-price-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25%;
}

.cart-product-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.cart-product-name {
    margin-left: 1rem;
    margin-top: 0.5rem;
    height: 75px;
    
}

.cart-product-name p {
    font-family: 'Recline Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.cart-product-quantity p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: #979797;
}

.product-price {
    font-family: 'Recline Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1rem;
    padding-left: 2rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.product-price-description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1rem;
    padding-left: 2rem;
    margin-bottom: 0.8rem;
}



.cart-image {
    width: 140px;
    height: 98px;
    padding-top: 10px;
}

.cart {
    width: 180px;
    height: 70px;
    clip: rect(0px,122px,70px,30px);
    border-radius: 20px;
    position: absolute;
}
@media only screen and (max-width: 496px) { 
    .cart-container {        
        padding-bottom: 0;
    }

    .cart-price-container {
        display: flex;
        flex-direction: column;        
        justify-content: flex-end;
        margin-top: -0.5rem;
        margin-bottom: 1.3rem;
        margin-right: 12px;
        width: 40%;
    }
    .cart-price-container p {
        text-align: right;
    }
    .product-price {
        padding-left: 0;
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .cart-image {
        width: 32px;
        height: 32px !important;
        margin-left: 12px;
    }
    
    .cart-image img{
        display: none;
    }
    .cart-product-name {
        padding-top: 14px;
        margin-left: -1.5rem;
        margin-top: -0.2rem;
    }
    .cart-product-name p {        
        font-size: 16px;
        line-height: 25px;
        
    }
    .cart-product-quantity p {        
        font-size: 14px;
        line-height: 17px;
        margin-top: -0.5rem;
    }
}

@media only screen and (max-width: 496px) { 
    .withoutPrice{
        justify-content: flex-start;
        margin-top: 0rem;
    }
}

@media only screen and (max-width: 428px) { 
    .cart-product{
        /* padding-bottom:30px; */
        margin-bottom: 10px;
    }
    .cart-product-quantity  {
        margin-bottom: 10px;
    }
    .cart-product-quantity p {
        font-size: 12px !important;
    }

    .cart-price-container {
        justify-content: space-evenly;
        margin-bottom:0;
    }

    .withoutPrice{
        justify-content: flex-start;
        margin-top: 0rem;
    }
}

@media only screen and (max-width: 428px) {
    .cart-product-quantity  {
        margin-bottom: 10px;
    } 
    cart-price-container{
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 326px) {
    .cart-product  {
        margin-bottom: 20px;
    } 
}

@media only screen and (max-width: 325px) { 
    .cart-product{
        margin-bottom: 30px;
    }
}
