.your_training_container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6rem;
}
.your_training_container a{
  color: #0B49EB;
  cursor:pointer;
  }
  .your_training_container a:visited{
    color: #0B49EB;
    }
    .your_training_container h1 {
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-family: "Recline Regular";
  font-size: 31px;
  line-height: 36px;
  text-align: center;
  color: #29292e;
}

.your_training_container p {
  margin: auto;
  margin-bottom: 3.5rem !important;
  font-family: "Montserrat";
  max-width: 700px;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #29292e;
  text-align: center;
}

.your_training_download {
  box-sizing: border-box;
  width: 414px;
  background: #ffffff;
  border-radius: 10px;
  margin: auto;
  max-width: 100%;
  height: 78px;
  box-shadow: 0px 4px 8px -2px #f2f4f5, 0px 2px 4px -2px rgba(17, 19, 34, 0.06);
  border-radius: 10px;
  border: 0.2px solid #B1B2B2;
}

.hint-training {
  display: flex;
  width: 420px;
  margin: 1rem auto;
  max-width: 100%;
  font-size: 10px;
}

.hint-training p {
  margin-left: 5px !important;
  font-family: "Montserrat";
  width: 100%;
  font-size: 10px;
  line-height: 14px;
  text-align: left;
  color: #29292e;
}

.hint-training img {
  width: 12px;
  height: 12px;
}

.your_training_download span {
  font-family: "Montserrat SemiBold";
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: -0.05em;
  color: #29292e;
  position: relative;
  left: 5%;
  top: -15px;
}

.img_your_training {
  background-blend-mode: multiply;
  border-radius: 4px;
  width: 70px;
  height: 58px;
  padding: 5px;
  position: relative;
  top: 8px;
  left: 8px;
  border: 1px dashed #bad0a2;
}

.icon_your_training {
  position: relative;
  left: 25px;
  top: 4%;
  bottom: 8.33%;
  width: 45px;
  height: 45px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .your_training_container {
    width: 95%;
  }

  .img_your_training {
    width: 42px;
    height: 38px;
    padding: 3px;
    top: 11px;
    left: 5px;
  }

  .your_training_container h1 {
    margin-bottom: 1.25rem;
    font-family: "Recline Medium";
    font-size: 20px;
    line-height: 24px;
  }

  .your_training_container p {
    margin-bottom: 2rem !important;
    font-family: "Montserrat";
    max-width: 100%;
    font-size: 12px;
    line-height: 18px;
  }

  .your_training_download {
    width: 319px;
    margin: auto;
    max-width: 100%;
    height: 60px;
    box-shadow: 0px 4px 8px -2px #f2f4f5,
      0px 2px 4px -2px rgba(17, 19, 34, 0.06);
    border-radius: 10px;
  }

  .your_training_download span {
    font-size: 12px;
    line-height: 20px;
    position: relative;
    left: 2%;
    top: -3px;
  }

  .icon_your_training {
    left: 40px;
    top: 10px;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }

  .hint-training {
    width: 320px;
  }

  .hint-training p {
    font-size: 8px;
    line-height: 12px;
  }
}

@media (max-width: 678px) {
  .icon_your_training {
    left: 40px;
  }
}

@media (max-width: 550px) {
  .icon_your_training {
    left: 40px;
  }
}

@media (max-width: 440px) {
  .icon_your_training {
    left: 40px;
  }
}

@media (max-width: 350px) {
  .icon_your_training {
    left: 60px;
    bottom: 2%;
    width: 32px;
    height: 32px;
  }

  .your_training_download span {
    font-size: 11px;
    line-height: 15px;
    left: 4%;
  }
}

@media (max-width: 340px) {
  .icon_your_training {
    left: 50px;
  }
}

@media (max-width: 330px) {
  .icon_your_training {
    left: 45px;
  }
}


@media (max-width: 320px) {
  .icon_your_training {
    left: 30px;
  }
}

@media (max-width: 315px) {
  .icon_your_training {
    left: 30px;
  }
  
}

@media (max-width: 310px) {
  .icon_your_training {
    left: 23px;
  }
  
}

@media (max-width: 295px) {
  .icon_your_training {
    left: 14px;
  }
  .your_training_download span {    
    left: 2%;
  }
}

@media (max-width: 283px) {
  .icon_your_training {
    left: 9px;
  }
  .your_training_download span {    
    left: 2%;
  }
}
