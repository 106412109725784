.contact-container {
    background-color: white;
    width: 100%;
    height: auto;
    text-align: center;
    padding-top: 2rem;
}

.contact-title {
    /* padding-bottom: 2.5rem; */
    padding-bottom: 84px;
}

.contact-title h1 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 39.06px;
    line-height: 50px;
    color: #29292E;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 2rem;
}
.contact-redirection-container{
    margin-left: 31px;
    margin-right: 31px;
    margin-bottom: 120px;
    display: flex;
    justify-content: center;
}
.contact-redirection-box{
    display: flex;
width: 728px;
padding: 20px;
flex-direction: column;
align-items: center;
gap: 23px;
border-radius: 6px;
border: 1px solid var(--color-base-lineas, #B1B2B2);
background: #FFF;
box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
}
.contact-redirection-box p{
    color: #29292E;
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 140% */
flex: 1 0 0;
}
.contact-title p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #29292E;
    width: 70%;
    margin: 0 auto;
}
.contact-redirection-box button{
    width: 242px;
height: 48px;
border-radius: 5px;
background: #BAD0A2;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
/* .contact-form-container {
    background-color: rgb(246, 255, 234, 0.7);
} */

.contact-form {
    display: flex;
    justify-content: center;
    width: 35%;
    margin: 0 auto;
    margin-bottom: 10rem !important;
}

.centered-btn-contact {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.contact-form label {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #29292E;
    margin-top: 3rem;
    margin-bottom: 0.5rem;
}

.contact-form input {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #29292E;
    border-radius: 0 0 0 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: rgba(41, 41, 46, 0.5);
    margin-right: 0.5rem;
    outline: none;
}

.contact-form textarea {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #29292E;
    border-radius: 0 0 0 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: rgba(41, 41, 46, 0.5);
    width: 100%;
}

.btn-send {
    padding: 1rem 5rem;
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    background: #BAD0A2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: #29292E;
    margin-bottom: 3rem;
}

.checkbox-contact-container {
    text-align: left;
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .contact-container{
        padding-top: 43px !important;
    }
    .contact-form {
        padding: 0rem 1rem;
        width: 100%;
    }

    .contact-form label {
        font-size: 14px;
        line-height: 17px;
    }

    .contact-title h1 {
        width: 70%;
        margin: auto;
        font-size: 24px;
        line-height: 28px;
    }

    .contact-title p {
        margin-top: 16px;
        font-size: 12.5px;
        line-height: 18px;
        width: auto;
    }
    .contact-form input {
        font-size: 14px;
        line-height: 17px;
        
    }

}
