.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  font-family: "Arial", sans-serif;
  text-align: center;
}

.maintenance-content {
  max-width: 600px;
  padding: 20px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.maintenance-content h1 {
  font-size: 2.5rem;
  color: #ff6b6b;
}

.maintenance-content p {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 20px;
}

.maintenance-content .kitten-image img {
  border-radius: 10px;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.maintenance-content .footer-text {
  font-size: 1rem;
  color: #777;
}
