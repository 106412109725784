.bf-team-card {
  position: relative;
  height: auto;
  background-color: #9b8464;
  border-radius: 1.5rem;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 211px; /* Fila para la imagen y fila para el contenido */
  grid-template-areas:
    'photo'
    'content';
}

.slick-slide .bf-team-card {
  margin-inline: 12px;
  cursor: grab;
}

.bf-team-card-instagram-logo-wrapper {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.48);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.bf-team-card-instagram-logo {
  width: 1.25rem;
  height: 1.25rem;
}

.bf-team-card-photo {
  grid-area: photo;
  display: block;
  width: 100%;
  max-width: 100%;
  aspect-ratio: 15/16;
  border-radius: 1.5rem;
  z-index: 1;
  object-fit: cover;
}

.bf-team-card-content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 40px 20px 20px;
}

.bf-team-card.overlay-active .bf-team-card-content {
  background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro */
  grid-row-start: photo;
  z-index: 100;
}

.bf-team-card-name {
  font-family: 'Recline Medium';
  font-size: 2rem;
  color: #f9fafa;
  margin: 0;
}

.bf-team-card-role {
  font-family: 'Montserrat Bold';
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #f9fafa;
  margin: 0;
}

.bf-team-card-description {
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: 19.2px;
  color: #f9fafa;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.bf-team-card-description p {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

.bf-team-card.overlay-active .bf-team-card-description {
  -webkit-line-clamp: unset;
  overflow: visible;
  text-overflow: unset;
}

.bf-team-card-button {
  width: 100%;
  background-color: transparent;
  font-family: 'Montserrat Bold';
  font-size: 0.8125rem;
  line-height: 0.975rem;
  text-align: center;
  color: #f9fafa;
  cursor: pointer;
  padding: 0.5rem 1rem;
  z-index: 5;
  margin-top: auto;
}
