/* Price section */
.bf-promo-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: rgba(255, 255, 255, 1);
  padding: 1.5rem;
  border-radius: 0.25rem;
}

.bf-promo-price-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bf-promo-price-wrapper {
  display: flex;
  flex-direction: column;
}

.bf-promo-price {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

.bf-promo-price-number {
  font-family: "Montserrat Bold";
  font-size: 2.625rem;
  line-height: 2.625rem;
  color: rgba(16, 37, 46, 1);
}

.bf-promo-price-promo {
  font-family: "Montserrat Bold";
  font-size: 1rem;
  line-height: 1rem;
  color: rgba(207, 151, 88, 1);
}

.bf-promo-price-simbol {
  font-family: "Montserrat Bold";
  font-size: 1.75rem;
  line-height: 1.75rem;
  color: rgba(16, 37, 46, 1);
  margin: 0;
}

.bf-promo-prev-price {
  font-family: "Montserrat";
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: rgba(16, 37, 46, 1);
  text-decoration: line-through;
  text-decoration-thickness: 0.125rem;
  text-decoration-color: rgba(244, 113, 105, 1);
  margin: 0;
}

/* Info section */
.bf-promo-info-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.bf-promo-info-text {
  font-family: "Montserrat";
  font-size: 1rem;
  line-height: 1.2rem;
  color: rgba(16, 37, 46, 1);
  margin: 0;
}

.bf-promo-plan-selector {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem;
  gap: 0.75rem;
  border: 1px solid rgba(241, 246, 236, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.bf-promo-radio-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.bf-promo-radio-wrapper label {
  font-family: "Montserrat";
  font-size: 1rem;
  line-height: 1.2rem;
  color: rgba(0, 0, 0, 1);
}

.bf-promo-divider-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 56px;
  gap: 0.5rem;
}

.bf-promo-divider {
  width: 100%;
  font-family: "Montserrat";
  font-size: 13px;
  line-height: 15.6px;
  color: rgba(49, 64, 55, 1);
  margin: 0;
  display: flex;
  align-items: center;
}

.bf-promo-divider::before,
.bf-promo-divider::after {
  content: "";
  flex: 1;
  height: 1px;
  min-width: 1.9688rem;
  background-color: rgba(186, 208, 162, 1);
}

.bf-promo-divider::before {
  margin-right: 0.5rem;
}

.bf-promo-divider::after {
  margin-left: 0.5rem;
}

.bf-promo-info-extra {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.25rem;
}

.bf-promo-info-icon {
  width: 1.5rem;
  height: auto;
  margin-top: calc(1ex - 1cap);
}

.bf-promo-info-anchor {
  font-family: "Montserrat";
  font-size: 13px;
  line-height: 15.6px;
  color: rgba(115, 133, 140, 1);
  margin: 0;
}

.bf-promo-info-anchor a {
  font-family: "Montserrat Bold";
  text-decoration: underline;
  color: rgba(112, 125, 97, 1);
}

/* Button section */

@media (width > 37.5rem) {
  .bf-promo-wrapper {
    padding: 2.5rem;
    gap: 2.5rem;
  }

  /* Price section */
  .bf-promo-price {
    align-items: flex-end;
  }

  .bf-promo-price-number {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }

  .bf-promo-price-promo {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  .bf-promo-price-simbol {
    font-size: 2rem;
    line-height: 2rem;
  }
  .bf-promo-prev-price {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  /* Info section */
  .bf-promo-info-text {
    font-size: 1.125rem;
    line-height: 1.35rem;
  }

  .bf-promo-plan-selector {
    padding: 1rem 0.5rem;
  }

  .bf-promo-plan-selector label {
    font-size: 1.125rem;
    line-height: 1.35rem;
  }

  .bf-promo-radio-wrapper label {
    font-size: 1.125rem;
    line-height: 1.35rem;
  }

  .bf-promo-divider-wrapper {
    padding-inline: 64px;
  }

  .bf-promo-divider {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .bf-promo-info-anchor {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  /* Button section */
}

@media (width > 62.5rem) {
  .bf-promo-wrapper {
    gap: 1.5rem;
  }

  .bf-promo-divider-wrapper {
    padding-inline: 5rem;
  }
}
