 .container_specialist{
    width: 70%;
    max-width: 700px;
    color: #29292E;
    margin: auto auto 6rem;
 }

 .text_specialist {
     font-family: 'Montserrat';
     margin-top: 3rem;
     margin-bottom: 12px;
     font-size: 16px;
 }

 .title_specialist {
     text-align: center;
     font-family: "Recline Regular";
     font-size: 31px;
     margin-bottom: 51px;
 }

 .hints-only-desktop {
     display: block;
 }

 .hints-only-mobile {
     display: none;
 }

 .not_chat_container{
    display:flex;
    align-content:center;
 }

 .not_chat_text{
    width:80%;
    margin:auto;
    text-align:center;
    font-size: 16px;
    font-family:Montserrat;
    font-weight:400;
    line-height:28px;
 }

 @media screen and (max-width: 768px) {
     .container_specialist {
         width: 94%;
     }

     .title_specialist {
         font-size: 20px;
         line-height: 24px;
     }

     .text_specialist {
         font-family: 'Montserrat';
         margin-top: 2rem;
         margin-bottom: 12px;
         font-size: 14px;
     }

     .hints-only-desktop {
         display: none;
     }

     .hints-only-mobile {
         display: block;
     }

     .input-message {
         height: 49px;
     }
 }

 @media (max-width: 575px) {
     .container_specialist {
         width: 100%;
     }

     .text_specialist, .hints-only-mobile {
         margin-left: 1rem;
         margin-right: 1rem;
     }
 }
