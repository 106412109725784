.card_calendar_psychology {
    width: 70%;
    height: 20% !important;
  }
  
  .arrow_text_ps {
    width: 8px;
    height: 4px;
  }
  .videocall-type-info-vW{
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 400;
    width: 92%;
    margin: auto auto 2rem;
    color: #29292e;
  }
  .type-info-container{
    display: flex;
    margin-bottom: 10px;
  }
  .span-psychology-videocall-long {
    margin-right: 10px;
    width: 12px;
    height: 12px;
    border: 2px solid rgba(186, 208, 162, 1);
    border-radius: 50%;
    background-color: rgba(246, 255, 234, 1);
  }
  .span-psychology-videocall-short {
    margin-right: 10px;
    width: 12px;
    height: 12px;
    border: 2px solid rgba(243, 228, 220, 1);
    border-radius: 50%;
    background-color: rgba(255, 246, 240, 1);
  }
  .text_ps {
    width: 12px;
    height: 12px;
  }
  
  .text_dates_psychology {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 2%;
    background: #f5f5f5;
  }
  
  .text_dates_div_psychology {
    display: flex;
  }
  
  .text_dates_img_container_psychology {
    display: flex;
    justify-content: end;
  }
  
  .title_calendar_psychology {
    max-width: 388px;
    font-size: 31px;
    line-height: 36px;
    font-family: "Recline Regular";
    color: #29292e;
    text-align: center;
    margin: 1rem auto 1.1rem;
  }
  
  .subtitle_calendar_psychology {
    width: 65%;
    font-family: "Montserrat";
    max-width: 600px;
    font-size: 16px;
    line-height: 28px;
    margin: auto auto 3rem;
    font-weight: 400;
    text-align: center;
  }
  
  .sessions-avaliable{
    cursor:unset !important;
    width:100% !important;
    font-family: "Montserrat" !important;
    font-size: 16px !important;
    color: #29292E !important;
    text-align: right !important;
    opacity: 1 !important;
  }
  
  .sessions-avaliable>span{
    font-weight:600;
  }
 .MuiPickersLayout-contentWrapper .css-5oi4td{
    max-height: fit-content !important;
    height: auto !important;
  }
  .calendar-content_psychology {
    margin: auto;
    width: 70%;
  }
  
  .info_dates_psychology {
    position: relative;
    left: 1rem;
  }
  
  .info_dates_psychology b {
    font-family: "Montserrat SemiBold";
    font-size: 12px;
    line-height: 20px;
    color: #29292e;
  }
  
  .avatar_psychology {
    width: 46px;
    height: 46px;
  }
  
  .info_dates_psychology span {
    font-size: 12px;
    line-height: 20px;
    color: #29292e;
  }
  
  .text_dates_psychology p {
    width: 100% !important;
    text-align: left !important;
    opacity: 1 !important;
    border-bottom: none;
    font-size: 11px !important;
    line-height: 18px !important;
    color: #29292e;
    margin-bottom: 1rem;
    font-family: "Montserrat" !important;
  }
  
  .dates_container_psychology {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-top: 6%;
  }
  
  .container_calendar_general_psychology {
    width: 90%;
    margin: auto;
  }
  
  .info_text_calendar_psychology {
    font-size: 10px;
    width: 98%;
    margin: 2% auto auto;
    padding-bottom: 5%;
  }
  
  .arrow_calendar_psychology {
    margin-left: 27px;
  }
  
  .text_dates_calendar_psychology {
    display: flex;
  }
  
  .cancel_calendar_psychology {
    position: relative;
    top: 88%;
    width: 12px;
    height: 12px;
  }
  
  .card-mi-cita_psychology {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 1.5rem;
    height: 125px;
    box-shadow: 0px 8px 36px #e8e7e7;
    border-radius: 10px 10px 0 0;
  }
  
  .MuiPickersToolbar-root,
  .MuiDialogActions-root {
    display: none !important;
  }
  
  .MuiPickersCalendarHeader-label {
    font-family: "Montserrat SemiBold" !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #29292e !important;
  }
  
  .MuiDrawer-root,
  .MuiBackdrop-root,
  .MuiDrawer-paper {
    top: 60px !important;
  }
  
  .MuiDayCalendar-weekDayLabel {
    font-family: "Montserrat SemiBold" !important;
    font-size: 12px;
  }
  
  .MuiPickersDay-dayWithMargin {
    border: 0.5px solid #cccccc !important;
    border-radius: 0 !important;
  }
  
  .MuiDayCalendar-weekContainer {
    margin: 0 !important;
  }
  
  .MuiPickersDay-dayWithMargin.Mui-selected {
    background: #b3b3b3 !important;
    color: #fff !important;
    font-family: "Montserrat SemiBold" !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  
  .MuiPickersDay-dayWithMargin,
  .MuiDayCalendar-weekDayLabel {
    font-family: "Montserrat SemiBold" !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #29292E !important;
  }
  
  .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition{
    overflow-y:auto;
  }
  
  .MuiDrawer-paper {
    width: 350px;
  }
  
  @media (min-width: 768px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 365px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 365px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 52px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  }
  
  @media (min-width: 870px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 420px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 420px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 60px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  }
  
  @media (min-width: 940px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 455px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 455px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 65px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  }
  
  @media (min-width: 1010px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 495px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 495px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 70px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  }
  
  @media (min-width: 1010px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 495px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 495px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 70px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  }
  
  @media (min-width: 1080px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 530px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 530px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 75px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  }
  
  @media (min-width: 1150px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 565px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 565px !important;
    }
  
    .MuiPickersLayout-root {
      height: 365px !important;
    }
  
    .MuiDayCalendar-slideTransition {
      height: 250px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 80px !important;
      height: 48px !important;
      margin: 0 !important;
    }
  }
  
  @media (min-width: 1215px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 595px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 595px !important;
    }
  
    .MuiPickersLayout-root {
      height: 365px !important;
    }
  
    .MuiDayCalendar-slideTransition {
      height: 250px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 85px !important;
      height: 48px !important;
      margin: 0 !important;
    }
  }
  
  @media (min-width: 1290px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 635px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 635px !important;
    }
  
    .MuiPickersLayout-root {
      height: 375px !important;
    }
  
    .MuiDayCalendar-slideTransition {
      height: 250px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      color: #29292E !important;
      width: 90px !important;
      height: 50px !important;
      margin: 0 !important;
    }
  }
  
  @media (min-width: 1360px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 670px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 670px !important;
    }
  
    .MuiPickersLayout-root {
      height: 375px !important;
    }
  
    .MuiDayCalendar-slideTransition {
      height: 250px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      color: #29292E !important;
      width: 95px !important;
      height: 50px !important;
      margin: 0 !important;
    }
  }
  
  @media (min-width: 1450px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 720px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 720px !important;
    }
  
    .MuiPickersLayout-root {
      height: 375px !important;
    }
  
    .MuiDayCalendar-slideTransition {
      height: 250px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      color: #29292E !important;
      width: 100px !important;
      height: 50px !important;
      margin: 0 !important;
    }
  }
  
  @media (max-width: 768px) {
    .title_calendar_psychology {
      font-size: 20px;
      line-height: 24px;
    }
  
    .MuiDayCalendar-weekDayLabel {
      font-size: 10px !important; 
    }
  
    .MuiPickersCalendarHeader-label {    
      font-size: 14px !important;    
    }
  
    .subtitle_calendar_psychology {
      width: 96%;
      font-size: 12px;
      line-height: 18px;
      margin: auto auto 2rem;
    }
  
    .sessions-avaliable{
      font-size:12px !important;
    }
  
    .card_calendar_psychology {
      width: 100%;
    }
  
    .text_dates_psychology {
      width: 100%;
    }
  
    .calendar-content_psychology {
      width: 92%;
    }
  
    .calendar-options_psychology {
      margin-bottom: 0;
    }
  
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 550px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 550px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 70px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  }
  
  @media (max-width: 660px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 455px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 455px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 65px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  }
  
  @media (max-width: 565px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 420px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 420px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 60px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  
    .info_dates_psychology span {
      font-size: 9px !important;
    }
  
    .info_dates_psychology b {
      font-size: 9px !important;
      }
      
      .text_dates_psychology p {
       font-size: 9px !important;
       }
  }
  
  @media (max-width: 525px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 385px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 385px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 55px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  
    .MuiDayCalendar-weekDayLabel {
      font-size: 9px !important;
    }
  }
  
  @media (max-width: 480px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 350px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 350px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 50px !important;
      height: 45px !important;
      margin: 0 !important;
    }
  
    .MuiDayCalendar-weekDayLabel {
      font-size: 9px !important;
    }
  }
  
  @media (max-width: 445px) {
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 315px !important;
    }
  
    .MuiPickersLayout-root {
      width: 315px !important;
      min-width: 300px !important;
      max-height: 320px;
    }
  
    .MuiPickersSlideTransition-root {
      width: 315px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 43px !important;
      height: 40px !important;
      margin: 0 !important;
    }
  
    .MuiDayCalendar-weekDayLabel {
      font-family: "Montserrat SemiBold" !important;
      font-size: 12px;
    }
  }
  
  @media (max-width: 395px) {
    .MuiDrawer-paper {
      width: 99vw;
    }
  
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 280px !important;
    }
  
    .MuiPickersLayout-root {
      width: 280px !important;
      min-width: 280px !important;
    }
  
    .MuiPickersSlideTransition-root {
      width: 280px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 40px !important;
      height: 35px !important;
      margin: 0 !important;
    }
  }
  
  @media (max-width: 355px) {
    .MuiDrawer-paper {
      width: 310px;
    }
  
    .MuiDayCalendar-monthContainer,
    .MuiDayCalendar-header,
    .MuiPickersLayout-contentWrapper,
    .MuiDateCalendar-root {
      width: 100% !important;
      max-width: 255px !important;
    }
  
    .MuiPickersLayout-root {
      width: 255px !important;
      min-width: 250px !important;
      max-height: 285px;
    }
  
    .MuiPickersSlideTransition-root {
      width: 250px !important;
    }
  
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      width: 35px !important;
      height: 35px !important;
      margin: 0 !important;
    }
  }
  
  .MuiPickersDay-dayOutsideMonth {
    background: rgba(218, 218, 218, 0.24) !important;
    color: #b3b3b3 !important;
  }
  