/* Contenedor principal del checkbox y el texto */
.checkbox-container.bf-checkbox {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  cursor: pointer;
  margin: 0;
}

/* Input checkbox oculto */
.bf-checkbox .checkbox-input {
  display: none;
}

/* Estilos del cuadrado personalizado del checkbox */
.bf-checkbox .checkbox-custom {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.25rem;
  border: 2px solid rgba(115, 133, 140, 1);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Estilo para el checkbox cuando está seleccionado */
.bf-checkbox .checkbox-input:checked + .checkbox-custom {
  background-color: rgba(186, 208, 162, 1);
  border-color: rgba(186, 208, 162, 1);
}

/* Marca de verificación que se mostrará cuando el checkbox esté seleccionado */
.bf-checkbox .checkbox-input:checked + .checkbox-custom::after {
  content: "✓";
  color: rgba(241, 246, 236, 1);
  font-size: 15px;
}

/* Estilo del texto */
.bf-checkbox .checkbox-label {
  font-family: "Montserrat Bold";
  font-size: 1rem;
  line-height: 1.2rem;
  color: rgba(0, 0, 0, 1);
}

@media (width > 37.5rem) {
  .bf-checkbox .checkbox-label {
    font-size: 1.125rem;
    line-height: 1.35rem;
  }
}
