.hint-diet {
    display: flex;
    width: 400px;
    margin: 0.6rem auto;
    max-width: 100%;
    font-size: 10px;
}

.hint-diet p {
    margin-left: 5px !important;
    font-family: "Montserrat" !important;
    width: 100% !important;
    font-size: 10px !important;
    line-height: 14px !important;
    text-align: left !important;
    color: #29292E !important;
    opacity: 1 !important
}

.hint-diet p a {
    color: #0B49EB !important;
}

.hint-diet img {
    width: 12px;
    height: 12px;
}

@media (max-width: 768px){
    .hint-diet {
        width: 320px;
    }

    .hint-diet p {
        font-size: 8px;
        line-height: 12px;
        margin-bottom: 0 !important;
    }
}
