.team-container {
  background-color: white;
  padding-bottom: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #29292E;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-bottom: 8rem
}

.team-banner img {
  height: 379px;
  max-width: 100%;
  max-height: 100%;
  width: 100vw;
}

.team-banner .mask {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 379px;
  background: #29292E;
  mix-blend-mode: multiply;
  opacity: 0.3;
  z-index: 13;
}

.card-description{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #29292E;
}

.header-team-section{
  position: absolute;
  top: 35%;
  left: 2%;
  text-align:left;
  font-family: 'Recline Medium';
  font-weight: 500;
  font-size: 48.83px;
  line-height: 50px;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 14;
}

.header-team-section-subtitle{
  position: absolute;
  top: 42%;
  left: 2%;
  text-align:left;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 22px;
  line-height: 38.75px;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 14;
}

.section {
  width: 100%;
  max-width: 100%;
  /* ajustar según sea necesario */
  margin-top: 3rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 0 20px;
  /* agrega padding a izquierda y derecha */
  justify-content: center;
  /* centrado horizontal de los grids */


}
.card .floating-logo img{
  width: 2vw;
}

.card .floating-logo img {
  width: 2rem;
  height: auto;
}

@media (max-width: 480px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 10px;
    /* ajusta el padding para la vista móvil */
  }

  .card {
    width: 80%;
    margin-left: 10% !important;
    min-height: 430px !important;
  }

  .section {
    margin-top: 10px;
  }

  .team-banner img {
    height: 135px;
    width: 480px;
  }

  .team-banner .mask {
    height: 135px;
  }

  .header-team-section{
    top: 10%;
    left: 2%;
    font-weight: 400;
    font-size: 28px;
  }

  .header-team-section-subtitle{
    top: 16%;
    left: 2%;
    font-weight: 400;
    font-size: 16px;
    line-height: inherit;
  }

  .card-action {
    font-weight: 400;
    font-size: 11px;
    line-height: 26px;
    left: 15%;
  }

  .section-title {
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
  }

  .card-description {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  .card-subtitle {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
  }

  .card-title {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
  }

  .section-title p {
    font-size: 28px;
    line-height: 34px !important;
  }
  

}

@media (max-width: 425px) {
  .card {
    width: 90%;
    margin-left: 5% !important;
    min-height: 430px !important;
  }

  .card .floating-logo img {
    width: 30px;
  }
}

@media (max-width: 380px) {
  .card {
    width: 98%;
    margin-left: 1% !important;
    min-height: 450px !important;
  }
}

@media (max-width: 330px) {
  .card {
    width: 102%;
    margin-left: -1% !important;
    min-height: 490px !important;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;
    /* ajusta el padding para la vista móvil */
  }

  .section {
    margin-top: 10px;
  }

  .team-banner img {
    /* display: none; */
  }

  .team-banner .mask {
    /* display: none; */
  }

  .card-action {
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    left: 15%;
  }

  .section-title {
    padding-top: 3rem;
    font-weight: 500;
    font-size: 26px;
    line-height: 28px;
  }

  .card-description {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }

  .card-subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .card-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
  }

  .section-title p {
    font-size: 28px;
    line-height: 34px !important;
  }

  .card {
    min-height: 440px !important;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .header-team-section{
    top: 25%;
    left: 2%;
  }

  .header-team-section-subtitle{
    top: 35%;
    left: 2%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 10px;
    /* ajusta el padding para la vista móvil */
  }
  
  .section {
    margin-top: 10px;
  }

  .team-banner img {
    height: 330px;
  }

  .team-banner .mask {
    height: 330px;
  }

}

@media (min-width: 1450px) and (max-width: 1600px) {
  .team-banner img {
    height: 430px;
  }

  .team-banner .mask {
    height: 430px;
  }
}

@media (min-width: 1600px) and (max-width: 1750px) {
  .team-banner img {
    height: 450px;
  }

  .team-banner .mask {
    height: 450px;
  }
}

@media (min-width: 1750px) and (max-width: 1900px) {
  .team-banner img {
    height: 470px;
  }

  .team-banner .mask {
    height: 470px;
  }
}

@media (min-width: 1900px) and (max-width: 2100px) {
  .team-banner img {
    height: 495px;
  }

  .team-banner .mask {
    height: 495px;
  }
}

@media (min-width: 2100px) and (max-width: 2350px) {
  .team-banner img {
    height: 525px;
  }

  .team-banner .mask {
    height: 525px;
  }
}

@media (min-width: 2350px) and (max-width: 2600px) {
  .team-banner img {
    height: 555px;
  }

  .team-banner .mask {
    height: 555px;
  }
}

@media (min-width: 2600px) and (max-width: 3000px) {
  .team-banner img {
    height: 590px;
  }

  .team-banner .mask {
    height: 590px;
  }
}

@media (min-width: 3000px) {
  .team-banner img {
    height: 630px;
  }

  .team-banner .mask {
    height: 630px;
  }
}

.card {
  position: relative;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 0;
  border-radius: 2rem;
  text-align: left;
  height: 100%;
  border: 0.1rem solid #D9D9D9;
  min-height: 520px;
  overflow: hidden;
}

.card-info-complete {
  padding: 1rem;
  position: absolute;
  z-index: 2;
  background-color: rgb(255, 255, 255, 0.9);
  top: 0;
  height: 100%;
  border: 0.07rem solid #D9D9D9;
  border-radius: 1.5rem;
}

.card-info-brief {

  padding: 1rem;

}

.card-title {
  font-family: 'Recline Medium';
  font-weight: 500;
  font-size: 31.25px;
  line-height: 26px;
  color: #29292E;
  padding-bottom: 0.3rem;
}

.card-subtitle {
  font-family: 'Montserrat Medium'; 
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #29292E;
  padding-bottom: 0.3rem;
}

.card-descrition {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1rem;
  line-height: 2.1rem;
  color: #111111;
  padding-bottom: 0.3rem;
  margin-bottom: 2rem;
  
  

}

.card-action {
  position: absolute;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12.8px;
  line-height: 26px;
  color: #111111;
  padding-top: 1rem;
  text-align: center;
  bottom: 1rem;
  left: 10%;
  width: 80%;
  z-index: 5;
  display : flex; 
  justify-content: center; 
  align-items: center; 
}

.card-action a {
  text-decoration: underline;
  cursor: pointer;
}

.grid-center {
  display: flex;
  justify-content: center;

}

.card-image {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  z-index: 1;
}


.card-image img {
 border-radius: 20px;
  width: 100%;
 height: 450px;
 object-fit: cover;
}


.section-title {
  font-family: 'Recline Medium';
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.1rem;
  color: #111111;
  margin-bottom: 3rem;
  padding-top: 3rem;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.floating-logo {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 6;
}

.card-footer {
  padding-bottom: 2rem;
}

@media only screen and (max-width: 475px) {
  .team-banner img {
    /* display: none; */
  }

  .team-banner .mask {
    /* display: none; */
  }

  .card-info-complete {
    top: 0;
    height: 100%;
  }
}
