.bf-quote {
  cursor: grab;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}

.bf-testimonial-slider .bf-quote {
  padding-inline: 8px;
}

.bf-testimonial-slider .slick-list {
  padding: 0 !important;
  margin: 0 auto;
  overflow: hidden;
}

.bf-testimonial-slider .slick-slide > div {
  width: 100% !important;
  max-width: none !important;
}

.bf-quote-blockquote {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.bf-quotationIcon {
  width: 1.75rem;
  height: auto;
  margin-top: 0.3rem;
}

.bf-quote-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.bf-quote-details {
  font-family: 'Montserrat';
  font-style: italic;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: rgba(249, 250, 250, 1);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bf-quote-details p {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.bf-quote-name,
.bf-quote-plan {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: rgba(155, 132, 100, 1);
}

.bf-quote-name {
  font-family: 'Montserrat Semibold';
}

.bf-quote-plan {
  font-family: 'Montserrat';
}

@media (width > 37.5rem) {
  .bf-quotationIcon {
    width: 2.1875rem;
  }

  .bf-quote-details,
  .bf-quote-name,
  .bf-quote-plan {
    font-size: 24px;
    line-height: 28.8px;
  }
}

@media (width > 62.5rem) {
  .bf-quotationIcon {
    width: 3.5rem;
  }
}
