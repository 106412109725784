.bf-video {
  position: absolute;
  top: 2.5rem;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

@media (width > 37.5rem) {
  .bf-video {
    top: 4rem;
    height: calc(100vh - 4rem);
  }
}

@media (width > 62.5rem) {
  .bf-video {
    top: 5rem;
    height: calc(100vh - 5rem);
  }
}
