.testimonial-container-diet {
    display: flex !important;
    flex-direction: row;
    background: rgba(255, 246, 240, 0.7);
    mix-blend-mode: normal;
    height: auto;
    padding: 1rem 0;
    z-index: 9999;
}

.testimonial-container-psico {
    display: flex !important;
    flex-direction: row;
    background: rgb(246, 255, 234, 0.7);
    mix-blend-mode: normal;
    height: auto;
    padding: 1rem 0;
}


.testimonial-img {
    padding-left: 10rem;
}

.description-container {
    padding-right: 0;
    text-align: left;
    width: 55%
}

.description-container h1 {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 40px;
    line-height: 26px;
    color: #29292E;
}

.description-container p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #29292E;
}

.description-container h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #29292E;
    margin: 1rem 0
}

.description-container h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #29292E;
}

.read-more {
    background: #FFF6F0;
    border: 1px solid #29292E;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    padding: 1rem 2rem;
    text-align: left;
    width: 240px;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: 1;
    color: #CFDEBE;
    margin-left: -3.5px;
    margin-top: 0px;
    -webkit-font-smoothing: antialiased;
    z-index: 99999
}

.read-more p {
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #29292E;
}

.custom-dot-testimonios {
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: 0.03rem solid #BAD0A2;
    margin: 0 5px;
    border-radius: 50%;
}

.slick-active .custom-dot-testimonios {
    background-color: #BAD0A2;
}

.slidertestimonials-dots li {
    position: relative;
    display: inline-block;
    width: 5px;
    height: 20px;
    margin: 0 20px;
    padding: 0;
    cursor: pointer;
    top: 2rem !important;
}

.slidertestimonials-dots {
    text-align: center;
}

.testimonials-container .slick-prev {
    left: 2rem;
}

.testimonials-container .slick-next {
    right: 2rem;
}

@media (max-width: 768px) {
    .testimonial-container {
        flex-direction: column;
    }

    .read-more {
        display: block;
        margin-right: auto;
        margin-left: auto;
        margin-top: -1rem;
    }

    .description-container {
        padding-right: 0;
        width: 90%;
        margin: 0 auto;
        font-weight: 400;
        font-size: 10.24px;
        line-height: 18px;
    }

    .description-container h1 {
        font-size: 20px;
        margin: 0.5rem 0;
    }

    .description-container h3 {
        font-weight: 700;
        font-size: 11.8px;
        line-height: 26px;
        margin: 0;
    }

    .description-container p {
        font-size: 10px;
        line-height: 18px;
        margin-top: 0.5rem;
    }

    .testimonial-img {
        padding-left: 0rem;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .image-post {
        height: 154px;
        width: 210px;
    }

    .testimonial-container-diet {
        flex-direction: column;
    }

    .testimonial-container-psico {
        flex-direction: column;
    }

    .slidertestimonials-dots {
        text-align: center;
        margin-top: -80px;
    }

    .slidertestimonials-dots li {
        font-size: 10px;
        width: 10px;
        height: 10px;
        margin: 0 5px;
    }

    .testimonial-container-diet,
    .testimonial-container-psico {
        height: 410px;
    }

    .testimonials-container .slick-next {
        top: 25% !important;
        right: 1rem !important;
        height: 25.5px !important;
        width: 25px !important;
    }

    .testimonials-container .slick-prev {
        top: 25% !important;
        left: 1rem !important;
        height: 25.5px !important;
        width: 25px !important
    }

    .testimonial-container .plans-type-select {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 3rem;
        display: flex;
        justify-content: space-around;
    }

}

@media (min-width: 992px) and (max-width: 1150px) {
    .testimonial-img {
        width: 44%;
        padding-left: 6% !important;
    }

    .description-container {
        width: 50%;
    }
}

@media (min-width: 1150px) and (max-width: 1366px) {
    .testimonial-img {
        padding-left: 7% !important;
        width: 40%;
    }
}

@media (min-width: 1366px) {
    .testimonial-img {
        padding-left: 7.5% !important;
        width: 38%;
    }
}

@media (min-width: 1700px) {
    .testimonial-img {
        padding-left: 10% !important;
        width: 38%;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .testimonial-container {
        flex-direction: column;
    }

    .description-container {
        padding-right: 0;
        width: 90%;
        margin: 0 auto;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .description-container h1 {
        font-size: 26px;
        margin: 0.5rem 0;
    }

    .description-container h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        margin: 0;
        margin-bottom: 1rem;
    }

    .description-container p {
        font-size: 12px;
        line-height: 18px;
    }

    .read-more {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }

    .testimonial-img {
        padding-left: 0rem;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .image-post {
        height: 154px;
        width: 210px;
    }

    .testimonial-container-diet {
        flex-direction: column;
    }

    .testimonial-container-psico {
        flex-direction: column;
    }

    .slidertestimonials-dots {
        text-align: center;
        margin-top: -80px;
    }

    .slidertestimonials-dots li {
        font-size: 10px;
        width: 10px;
        height: 10px;
        margin: 0 5px;
    }

    .testimonial-container-diet,
    .testimonial-container-psico {
        height: 410px;
    }

    .testimonials-container .slick-next {
        top: 25% !important;
        right: 1rem !important;
        height: 25.5px !important;
        width: 25px !important;
    }

    .testimonials-container .slick-prev {
        top: 25% !important;
        left: 1rem !important;
        height: 25.5px !important;
        width: 25px !important
    }

    .testimonials-home-content .slick-next {
        top: 25% !important;
        right: 10rem !important;
        height: 25.5px !important;
        width: 25px !important;
    }

    .testimonials-home-content .slick-prev {
        top: 25% !important;
        left: 10rem !important;
        height: 25.5px !important;
        width: 25px !important
    }

    .testimonial-container .plans-type-select {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 3rem;
        display: flex;
        justify-content: space-around;
    }

}

@media (max-width: 320px) {

    .description-container h3 {
        font-weight: 700;
        font-size: 10px;
        line-height: 26px;
        margin: 0;
    }

    
}
