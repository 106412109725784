.btn-product {
    color: #29292E;
    text-align: center;
    background-color: #BAD0A2;
    margin: 0 auto 2rem;
    border-radius: 0.5rem;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: center;
    color: #29292E;
    padding: 0.5rem 4rem;
}

/* .divider-color {
    border: 0.08rem solid #95A585 !important;
    margin: 0 1.5rem !important;
} */

.workshop-card-container {
    opacity: 0.3;
  }

.card-content {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgb(7 42 68 / 15%) !important;
    border-radius: 11px !important;
}

.card-content-product {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.description-product {
    margin-top: 0.2em;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #29292E;
    height: 90px;
    display: flex;
}

.description-product p {
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.large {
    font-family: 'Recline Regular';
    font-weight: 600;
    font-size: 61px;
    line-height: 94px;
    color: #29292E;
    margin-top: -20px;
}

.normal {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    color: #29292E;
    padding-top: 1em;
}

.small {
    font-size: 1.6rem;
    font-family: 'Recline Regular';
    font-weight: 400;
    line-height: 37px;
    color: #29292E;
}

.subscription {
    font-family: 'Recline Regular';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: right;
    color: #29292E;
    margin-top: -30px;
    margin-right: 5%;
}

.letter-type {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 31px;
    line-height: 48px;
    color: #29292E;
}

.spacer {
    color: #29292E;
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    min-width: 110px;
}

.spacer-1 {
    margin-top: 2rem;
    margin-bottom: 3rem;
    color: #BAD0A2;
}

.container-price {
    padding: 0 1rem;
}
.isNotChecked-training{
    color: rgba(41, 41, 46, 1);
}
.container-price-plan-Details {
    padding: 0 1rem;
}

.card-container-area {
    padding: 1rem 0 !important;
}
.FromMenuProduct{
    font-size: 18px;
    font-family: "Recline Medium";
    text-align: center;
    /* margin-right: 120px; */
    /* width: 100%; */
    text-align: left;
    font-weight: 100;
    margin-top: 12px;
    position: absolute;
    color: #29292E;
}
.btn-myproduct-disabled{
    text-align: center;
    margin: 0 auto 0.75rem;
    background-color:rgb(220, 230, 209);
    font-family: Montserrat;
font-size: 12px;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
border-radius: 5px;

}
.btn-myproduct-disabled span{
    color:rgb(148, 148, 150);
}
@media screen and (min-width: 1600px) {
    .container-price {
        padding: 0 5rem;
    }

    .container-price-plan-Details {
        padding: 0 3.5rem;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1600px){
    .container-price {
        padding: 0 4rem;
    }

    .container-price-plan-Details {
        padding: 0 2rem;
    }
   
}

@media screen and (min-width: 1201px) and (max-width: 1300px){
    .container-price {
        padding: 0 3rem;
    }

    .container-price-plan-Details {
        padding: 0 1rem;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200.9px) {
    .container-price {
        padding: 0 2rem;
    }
    .FromMenuProduct{
        font-size: 18px;
    }
    .container-price-plan-Details {
        padding: 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .container-price {
        padding: 0 2rem;
    }
    .FromMenuProduct{
        font-size: 18px;
    }
    .container-price-plan-Details {
        padding: 0;
    }
}

@media screen and (max-width: 475px) {
    .container-price {
        padding: 0 6rem;
    }

    .container-price-plan-Details {
        padding: 0 2rem;
    }
}

@media screen and (min-width: 475px) and (max-width: 610px) {
    .container-price {
        padding: 0 9rem;
    }

    .container-price-plan-Details {
        padding: 0 2rem;
    }
}

@media screen and (min-width: 610px)  and (max-width: 768px) {
    .container-price {
        padding: 0 13rem;
    }

    .container-price-plan-Details {
        padding: 0 2rem;
    }

    .description-product {
        font-size: 20px;
        line-height: 31px;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .description-product {
        height: 70px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .normal {
       font-size: 18px;
       line-height: 20px;
       margin-left: 2rem;
    }

    .large {
        font-weight: 600;
        font-size: 39px;
        line-height: 61px;
        margin-top: 1rem;
    }

    .small {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin-left: 0.2rem;

    }

    .subscription {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-top: -20px;
        margin-right: 2rem;
    }

    .btn-product {
        font-size: 9px;
        line-height: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }

    .spacer {
        font-size: 9px;
        line-height: 10px;
        width: 50px;
    }
}
