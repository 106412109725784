.bf-info-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 5rem 3rem;
  background-color: rgba(10, 13, 11, 1);
  text-align: center;

  max-width: none;
  min-height: auto;
  color: initial;
  border: none;
  border-radius: 0;
}

.bf-info-bold-text,
.bf-info-text {
  max-width: 80rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: rgba(241, 246, 236, 1);
  margin: 0;
}

.bf-info-bold-text {
  font-family: "Montserrat SemiBold";
}

.bf-info-text {
  font-family: "Montserrat";
}

@media (width > 37.5rem) {
  .bf-info-wrapper {
    padding: 3.5rem 4rem 4rem;
  }
}

@media (width > 62.5rem) {
  .bf-info-wrapper {
    padding: 5rem 5rem 6rem;
  }
}
