.bf-promotions-container.bf-nutrition-container::before {
  background-image: url("../../../../../assets/blackfriday/nutrition-mobile.jpg");
}
@media (width > 37.5rem) {
  .bf-promotions-container.bf-nutrition-container::before {
    background-image: url("../../../../../assets/blackfriday/nutrition-desktop.jpg");
  }
}

.bf-promotions-container.bf-nutrition-container .MuiCircularProgress-root {
  color: rgba(186, 208, 162, 1);
}
