.new-product-home{
    position: absolute;
    margin-left: 10px;
    justify-content: space-around; /* Center items horizontally */
    align-items: center;
    padding: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    width: 40%;
    max-width: 130px;
    height: 40px;
    border-radius: 0 0 8px 8px;
    background-color: rgba(218, 165, 32, 1);
}

.new-product-home{
    background-color: rgba(218, 165, 32, 1);
}
.new-product-home span{
    margin: 0px 4px 0px 4px;
    width: 1px;
    background-color: black;
    height: 90%;
}
.new-product-home img{
    width: 16px;
    height: 16px;
}
.new-product-home-title{
    font-family: 'Recline Medium';
    font-size: 1.1em;
    letter-spacing: 0.6px;
    color: rgba(41, 41, 46, 1);
    font-weight: 900;
}

@media (max-width: 355px) {
    .new-product-home-title{
        font-size: 0.9em;
     
    }
}