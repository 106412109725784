.cookie-banner {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    font-family: 'Montserrat';
  }
  
  .main-cookie-banner {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000; 
  }
  
  .cookie-banner .cookie-banner-content {
    width: 60%; /* Adjust width as needed */
    color: rgba(41, 41, 46, 1);
    font-size: 1.25rem;
    line-height: 30px;
    font-weight: 400;
  }
  
  .cookie-banner .cookie-banner-content a {
    color: rgba(11, 73, 235, 0.99);
    font-weight: 500;
  }
  
  .cookie-buttons {
    width: 25%; 
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right:7%;
  }
  
  .cookie-banner .cookie-buttons .accept-cookies {
    margin-right: 31px;
  }
  
  .cookie-banner .cookie-buttons button.accept-cookies {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat Semibold';
    border-radius: 5px;
    height: 48px;
    width: 242px !important;
    color: rgba(41, 41, 46, 1);
    background-color: #BAD0A2;
    width: 120px; /* Adjust width as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .cookie-banner .cookie-buttons button {
    width: 30px; 
    color: white;
    border-radius: 0px;
    font-size: 12px; 
    background-color: rgba(41, 41, 46, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  
  .cookie-banner .cookie-buttons img {
    width: 25px;
    height: 25px;
  }
  .Modal {
    width: 100%;
    position: absolute;
    top: auto;
    bottom: 0%;
    background: rgb(255, 255, 255);
    outline: none;
    padding: 26px;
    z-index: 2000;
  }

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(0,0,0,.5);
  }
  @media (max-width:1150px) {
    .cookie-buttons {
      margin-right:8%;
    }
  }
  @media (max-width:990px) {
    .cookie-buttons {
      margin-right: 10%; 
      margin-bottom: 55px;
    }
  }
 /* @media (max-width:530px) {
    .cookie-buttons {
      width: auto !important;
     justify-content: left !important;
     align-items: center;
    }
    .cookie-banner{
      align-items: start !important;
    }
  }
  @media (max-width:475px) {
    .cookie-banner .cookie-buttons button.accept-cookies{
      width: 200px !important;
    }
  }
  @media (max-width:413px) {
    .cookie-banner .cookie-buttons button.accept-cookies{
      width: 180px !important;
      font-size: 10px;
      height: 40px;

    }
    .cookie-banner .cookie-buttons .accept-cookies{
     margin-right: 20px;
      
    }
  }*/
@media (max-width:780px) {
  .cookie-banner{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    max-width: 768px;
  }
  .cookie-banner .cookie-banner-content {
    font-size: 16px;
  width: 100%;
  }
  .cookie-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}