.container {
  display: flex;
  flex-direction: row;  
  width: 100%;
}

.payment-container label {
  margin-top: 0 !important;
}

.payment-container input {
  height: 32px !important;
}

.cart{
  display:flex;
  flex-direction: row;
  color: #29292E;
  align-items: start;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Montserrat';
}
.cart-title{
  margin-left:1rem;
} 

.container-payment {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-bottom: 3rem;
  margin-left: 8rem;
  margin-right: 2rem;
}

.container-cart {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 3rem;
  margin-left: 2rem;
  margin-right: 8rem;
  background: rgba(246, 255, 234, 0.49);
  border: 1px solid #BAD0A2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.container-cart-product {
  margin: 10px 20px;
}

.facturation-container {
  margin-bottom: 0rem;
  order:3;
}

.product-header {
  margin-left: 1rem;
  margin-right: 1rem;
}

.variantes p {
  text-align: left;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.card-actions {
  border-top: 1px solid #D9D9D9;
}

.product-name-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.product-title p{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  text-transform: uppercase;
  color: #111111;
  margin-bottom: 0px!important;
}

.product-name-container p{
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;  
  text-transform: uppercase;
  color: #111111;
  margin-bottom: 0px!important;
}

.product-img img{
  width: 134px;
  height: 97px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 3px;
}




/**********************************************************/
.payment-product-cards-containers {
  display:flex;
  flex-direction: row;
}
.payment-container{
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 1rem
}
.payment-side1{
  width: 68%;
  order:2  

}
.payment-side2{
  width: 30%;  
  position: fixed;
  top:12rem;
  right:1rem;
  order: 1;
  max-width: 430px;
}

.title-container {
  color: #29292E;
  text-align: center;  
  margin-top: 7rem;
  margin-bottom: 3rem;
  width: 100%;
  font-family: 'Recline Regular';
  font-weight: 500;

}

.div-1 {
  display: flex;
  flex-direction: row;
  width: 68%;
  
  margin: 1.5rem 1rem;
  padding-right: 2rem;
}

@media only screen and (min-width: 1350px) {
  .payment_main_container {
    width: 80% !important;
    margin: auto !important;
  }
}

@media only screen and (min-width: 1200px) {
  .button-only-mobile {
    display: none;
  }

  .button-only-desktop {
    display: block;
  }

  .payment_main_container {
    width: 90% !important;
    margin: auto !important;
  }
}

@media only screen and (max-width: 1200px) {
  .button-only-mobile {
    margin-top: -2rem;
    margin-bottom: 1.5rem;
    display: block;
  }

  .button-only-desktop {
    display: none;
  }

  .div-1 {
    width: 100%;      
    margin: 1rem 0 0 0;
    padding: 0;     
  }
  .payment-side1{
    width: 100%; 
  }
  .payment-side2{
    max-width: unset;
    width: 100%; 
    margin-left: 0; 
    position:unset ;       
  }
  .confirm-payment-card {
    width: 70%;   
    margin-left: 0;
    margin: 0 auto;
    margin-top: 0rem;    
  }
  .cart-container{
    margin-bottom: 0rem;
  }
}


.payment-product-cards-containers {
  width: 100%;
  margin: 0 auto;
}

.payment-title-sesion {
  font-family: 'Recline Regular';
  font-weight: 500;
  font-size: 25px;
  line-height: 39px;
  color: #29292E;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.cart-quantity {
  color: #29292E;
  font-family: 'Montserrat SemiBold';
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3px;
  text-align: justify;
  padding-left: 1.5rem;
}
@media only screen and (max-width: 496px) {
  .confirm-payment-card {   
    width: 100%;       
    border: 0; 
    padding-bottom: 1rem;        
    box-shadow: none;
    border-radius: 0;     
  }
  .payment-container{    
    margin: 2rem 0
  }
  .confirm-payment-final {
    padding: 1rem 0rem;
    margin-bottom: 0;
  }
  .facturation-form-container-header{
    height: 0;   
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .facturation-form-container{    
    box-shadow: none;
    border:0;
    border-radius: 0;    
    margin-bottom: 0rem;    
  }
  .facturation-form-container input{ 
    width: 100%
  }
  .form-container {    
    width: 100%;
    margin :0 auto;
  }
  .facturation-form-subtitle {
    text-align: center;
    margin: 0.5rem 2rem 2rem;
  }
  .title-container h1 {
    color: #29292E;
    font-size: 24px;
    line-height: 28px;
    font-family: 'Recline Medium';
    font-weight: 500;
}

  .confirm-payment-card-title {
    color: #29292E;
    font-size: 16px; 
    padding-left: 40px;   
    border-radius: none;
}

.confirm-payment-promotions {
  padding: 2rem 3rem 1rem;
  margin-bottom: 0.95rem;
  font-weight: 400;
  font-family: 'Recline Regular';
  color: #29292E;
  font-size: 12.8px;
  line-height: 20px;

}
.facturation-form-container form {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem;
}

.payment-title-sesion {
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 2rem;
  font-family: 'Recline Medium';
  font-weight: 500;
  font-size: 24px;
  line-height: 39px;
  color: #29292E;
  text-align: center;
  
}
}
