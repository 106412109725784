.begin-form .fam-groups{
    display: grid;
    position: relative;
    width: 100%;
    padding-bottom:20px;
}

.begin-form .fam-groups:first-of-type{
    margin-right: 10px;

}

.fam-groups label{
    margin-bottom: 12px;
}

.begin-form label{
    /* margin-bottom: 12px; */
}

.begin-form input[type=text]{
    height: 46.51px;
    padding: 10px;
    /* min-width: 380.52px
    ; */
    width: 100%;
    margin-bottom: 10px;
}

.begin-form input{
    font-family: 'Montserrat';
    border: 0.83px solid #E5E5E5;
    border-radius: 4px;
}

.begin-form input:focus{
    border: 2.83px solid #0B49EB;
} 

.begin-form input[type=date]{
    height: 46.51px;
    padding: 10px;
    margin-top: 13px;
    /* min-width: 380.52px
    ; */
    width: 100%;
}

.begin-form input[type=tel]{
    height: 46.51px;
    padding: 10px;
    margin-top: 13px;
    /* min-width: 380.52px
    ; */
    width: 100%;
}

.begin-form .cook-option{
    /* margin-top: 10px; */
    /* margin-left: 15px; */
}

.begin-form p.cook-p{
    font-weight: 800;
    font-family: 'Montserrat Semibold';
}

#cocinarSi{
    margin-right: 10px;
    accent-color: #BAD0A2;
}

#cocinarNo{
    margin-right: 10px;
    accent-color: #BAD0A2;
}

.begin-form input[type=email]{
    height: 46.51px;
    padding: 10px;
    margin-top: 13px;
    /* min-width: 380.52px */
    width: 100%;
}

.begin-form .name-fields{
    display: flex;
    margin-bottom: 20px;
}

.begin-form .email-fields{
    display: flex;
    margin-bottom: 20px;
}

.begin-form .phone-birth-fields{
    display: flex;
   /*  margin-bottom: 20px; */
}

.begin-form .height-weight-fields{
    display: flex;
}

.height-weight-fields .fam-groups{
    padding-bottom:0px;
}

.begin-form .fam-groups label{
    /* font-size: 10px; */
    font-family: 'Montserrat SemiBold';
    font-weight: 600;
    margin-bottom:0px;
}

.sexo-label{
    margin-top:0rem;
}

#sexo-fields input{
    margin: 10px;
    accent-color: #BAD0A2;
}

.next-field{
    font-family: Montserrat;
    font-size:20px;
    font-weight:500;
    line-height:20px;
    max-width: 200px;
    margin-left: auto;
    text-transform: capitalize;
    background-color:transparent;
}

.begin-form hr{
    margin-top: 50px;
}

.sexo-mujer{
    margin-top: 20px;
    padding: 8px;
    background-color: #F6FFEA;
}

.sexo-mujer p{
    /* margin-bottom: 20px; */
    font-family: Montserrat SemiBold;
    font-weight: 600;
}

.sexo-mujer label{
    /* margin: 10px; */
    font-weight: 200;
}

.begin-form input[type=radio]{
    accent-color: #BAD0A2;
    width:16px;
    height:16px;
    margin:10px;
}

.sexo-mujer input[type=text]{
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    background-color: #F6FFEA;
}

.instructions{
    background-color: antiquewhite;
    color: #29292E;
    marginBottom: '0.5rem';
}

.height-recalculada{
    max-height: 160px !important;
    height: 155px;
}

@media(max-width:768px){

    .begin-form .name-fields{
        display: block;
        margin-bottom: 0px;
    }

    .begin-form .name-fields input[type=text],.email-fields input[type=email],.phone-birth-fields input[type=date],.phone-birth-fields input[type=tel],.height-weight-fields input[type=text], #sexo-fields label{
        font-size: 12px;
    }

    .begin-form .fam-groups label{
        font-size: 12px;
        margin-bottom:10px;
    }

/*     .begin-form input[type=text], .begin-form input[type=tel], .begin-form input[type=date],.begin-form input[type=email]{
        height: 28px;
    } */
    .begin-form input[type=text], .begin-form input[type=tel], .begin-form input[type=date],.begin-form input[type=email]{
        height: 28px;
        font-size: 12px;
    }

    .begin-form .email-fields{
        display: block !important;
        margin-bottom: 0px;
    }

    .begin-form{
            padding: 10px;
    }

    .begin-form .phone-birth-fields{
        display: block;
        margin-bottom: 0px;
    }

    .begin-form .height-weight-fields{
        display: block  !important;
        margin-bottom: 0px;
    }

    .begin-form input[type=radio]{
        margin: 5px;
    }

    .sexo-label{
        margin-top:0.8rem;
        font-size:12px !important;
    }

    .sexo-mujer p{
        font-size: 12px;
        margin-top:10px;
        margin-bottom: 10px;
    }

    .sexo-mujer label{
        font-size:7.583px;
        margin: 0px;
    }

    .sexo-mujer input[type=text]{
        font-size: 8px;
        margin-top:0px;
    }
    
    .email-container,.confirm-email-container,.confirm-phone-container,.confirm-date-container{
        margin-top:0.8rem;
    }

    .materialui-fields-label{
        margin-bottom:0px;
    }
/*     .MuiFormControlLabel-root .MuiFormControlLabel-labelPlacementEnd .css-j204z7-MuiFormControlLabel-root{
        margin-bottom:0px;
    } */

    .next-field-personal-data{
        font-size:15px !important;
    }
}