.vertical-spacer-hide {
  display: none;

}

.divider-pink {
  border-top: 1px solid #ECC4B4;
  margin: 1rem 0;
}

.vertical-spacer-desktop {
  display: none;
  /* Temporal elimino las rayas*/
  background-color: #95A585;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 7rem;
  margin-right: 7rem;
}

.vertical-spacer-mobile {
  display: none;
  /* Temporal elimino las rayas*/
  background-color: #95A585;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}


.why-text-tittle {
  font-family: 'Recline Medium';
  font-size: 28px;
  text-align: left;
  font-weight: 500;
  line-height: 43px;
  text-align: justify;
  color: #29292E;
  width: max-content;
}

.why-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: fit-content;
  margin-left: 10%;
  margin-right: 7%;
  margin-bottom: 2%;
  border-bottom: 1px solid #95A585;
}

.why-card-none {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: fit-content;
  margin-left: 10%;
  margin-right: 7%;
  margin-bottom: 2%;
}

.circular-bg {
  background: #BAD0A2;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  margin-bottom: 2rem;
}

.circular-bg img {
  padding: 1rem;
  width:5vw;
}

.why-text-subtittle {
  font-size: 1rem;
  text-align: left;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem;
  color: #29292E;

}


.why-text {
  padding-left: 1rem;
  margin-bottom: 2rem;
}

.why-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;
  padding: 0 4rem;
  justify-content: center;
  grid-row-gap: 1.5rem;
  margin-bottom: 1rem;
}

.tittle {
  font-family: 'Recline Regular';
  font-weight: 500;
  font-size: 40px;
  line-height: 62px;
  color: #29292E;
  margin-bottom: 3rem;
  margin-top: 3rem;
  padding-top: 55px;
}


@media(max-width:1100px){
  .circular-bg img{
    width: 10vw;
  }
}
@media (max-width: 768px) {

  .why-card {
    margin-left: 7%;
  }
  .why-container {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 10px;
    grid-row-gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .section {
    margin-top: 10px;
  }

  .why-text-tittle {
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
  }

  .why-text-subtittle {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .circular-bg img {
    padding: 0.8rem;
    width: 50px;
  }

  .why-text {
    padding-left: 1rem;
    margin-bottom: 0.5rem;
  }

  .circular-bg {
    margin-bottom: 2rem;
  }

  .circular-bg {
    margin-bottom: 0.5rem;
  }

}

