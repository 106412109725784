.bf-faq {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: #291e12;
  padding: 3.5rem 1.25rem 4rem;
}

.bf-faq-container {
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.bf-faq-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.bf-faq-title {
  font-family: 'Recline Medium';
  font-size: 2.625rem;
  line-height: 2.625rem;
  color: #f9fafa;
}

.bf-faq-title-subtitle {
  font-family: 'Montserrat';
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #f9fafa;
}

.bf-faq-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.bf-faq-item {
  background-color: #c3b5a2;
}

.bf-faq-question {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Montserrat Bold';
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 1rem;
  gap: 1rem;
  color: #141a16;
  border: 1px solid #9b8464;
  cursor: pointer;
}

.bf-faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: 1.2;
  color: #141a16;
  /* will-change: max-height; */
}

.bf-faq-answer-wrapper {
  padding: 1rem 1rem 1.25rem;
  border: 1px solid #9b8464;
  margin: 0;
}

.bf-faq-answer-wrapper p {
  margin: 0;
  font-size: inherit;
}
.bf-faq-answer-wrapper p + p {
  margin-top: 0.8em;
}

.bf-faq-answer-wrapper strong {
  font-weight: 600;
  font-family: 'Montserrat Bold';
}

.bf-faq-answer-wrapper ul {
  margin: 20px;
}

.bf-faq-answer-wrapper li + li {
  margin-top: 0.4em;
}

.bf-faq-answer.open {
  max-height: 100vh;
}

.arrow-icon {
  width: 2rem;
  height: 2rem;
  transition: transform 0.3s ease;
}

.arrow-icon.rotate {
  transform: rotate(-45deg);
}

.bf-faq-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bf-faq-button {
  width: 100%;
  padding: 16px 24px;
  border: 2px solid #f9fafa;
  border-radius: 6.25rem;
  background-color: transparent;
  font-family: 'Montserrat SemiBold';
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: #f9fafa;
  text-align: center;
}

@media (width > 37.5rem) {
  .bf-faq {
    padding: 5rem 5rem 96px;
  }

  .bf-faq-title {
    font-size: 4rem;
    line-height: 4rem;
  }

  .bf-faq-title-subtitle {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  .bf-faq-question,
  .bf-faq-answer {
    font-size: 1.125rem;
    line-height: 1.35;
  }

  .bf-faq-button-wrapper {
    width: 18.3125rem;
  }
}

@media (width > 62.5rem) {
  .bf-faq-title {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}
