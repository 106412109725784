.your_diet_container{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6rem;
}

.your_diet_container h1 {
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-family: "Recline Regular";
  font-size: 31px;
  line-height: 36px;
  text-align: center;
  color: #29292e;
}

.your_diet_container p {
  margin: auto;
  margin-bottom: 3.5rem !important;
  font-family: "Montserrat";
  max-width: 700px;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #29292e;
}

.your_diet_container a{
  color: #0B49EB;
  cursor:pointer;
}

.your_diet_download, .your_calendar_download {
  box-sizing: border-box;
  width: 414px;
  background: #ffffff;
  border-radius: 10px;
  margin: auto;
  max-width: 100%;
  height: 78px;
  box-shadow: 0px 4px 8px -2px #F2F4F5, 0px 2px 4px -2px rgba(17, 19, 34, 0.06);
  border-radius: 10px;
  border: 0.2px solid #B1B2B2;
}

.your_diet_download {
  margin-bottom: 1.25rem !important;
}

.your_calendar_download {
  background: #F6FFEA;
}

.hint-diet, hint-calendar {
  display: flex;
  width: 400px;
  margin: 1rem auto;
  max-width: 100%;
  font-size: 10px;
}

.hint-diet p, .hint-calendar p {
  margin-left: 5px !important;
  font-family: "Montserrat";
  width: 100%;
  font-size: 10px;
  line-height: 14px;
  text-align: left;
  color: #29292e;
}

.hint-diet img, .hint-calendar img {
  width: 12px;
  height: 12px;
}

.your_diet_download span, .your_calendar_download span {
  font-family: "Montserrat SemiBold";
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: -0.05em;
  color: #29292e;
  position: relative;
  left: 5%;
  top: -15px;
}

.img_your_diet, .img_your_calendar {
  background-blend-mode: multiply;
  border-radius: 4px;
  background-color: white;
  width: 70px;
  height: 58px;
  padding: 5px;
  position: relative;
  top: 8px;
  left: 8px;
  border: 1px dashed #BAD0A2;
}

.icon_your_diet, .icon_your_calendar , .icon_your_calendar_pregnancy{
  position: relative;
  left: 27%;
  top: 4%;
  bottom: 8.33%;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.icon_your_calendar_pregnancy{
  left: 28%;
}
.icon_your_calendar {
  left: 15%;
}

@media (max-width: 768px){
  .your_diet_container{
    width: 95%;
  }

  .img_your_diet, .img_your_calendar {
    width: 42px;
    height: 38px;
    padding: 3px;
    top: 11px;
    left: 5px;
  }

  .your_diet_container h1 {
    margin-bottom: 1.25rem;
    font-family: "Recline Medium";
    font-size: 20px;
    line-height: 24px;
  }

  .your_diet_container p {
    margin-bottom: 2rem !important;
    font-family: "Montserrat";
    max-width: 100%;
    font-size: 12px;
    line-height: 18px;
  }

  .your_diet_download, .your_calendar_download {
    width: 319px;
    margin: auto;
    max-width: 100%;
    height: 60px;
    box-shadow: 0px 4px 8px -2px #F2F4F5, 0px 2px 4px -2px rgba(17, 19, 34, 0.06);
    border-radius: 10px;
  }

  .your_diet_download span, .your_calendar_download span {
    font-size: 12px !important;
    line-height: 20px;
    position: relative;
    left: 5%;
    top: -3px;
  }

  .icon_your_diet, .icon_your_calendar, .icon_your_calendar_pregnancy{
    position: relative;
    left: 100px;
    top: 10px;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }

  .icon_your_calendar {
    left: 60px;
  }
  
  .icon_your_calendar_pregnancy{
    /* left: 60px; */
  }
  .hint-diet, .hint-calendar {
    width: 320px;
  }

  .hint-diet p, .hint-calendar p {
    font-size: 8px;
    line-height: 12px;
  }
}

@media (max-width: 330px) {
  .icon_your_diet {
    left: 93px;
  }

  .icon_your_calendar {
    left: 55px;
  }
  .icon_your_calendar_pregnancy {
    left: 55px;
  }
  

  .your_calendar_download span {
    font-size: 12px !important;
    line-height: 20px;
    position: relative;
    left: 5%;
    top: -3px;
  }
}

@media (max-width: 600px) {
  .icon_your_diet {
    left: 110px;
  }

  .icon_your_calendar {
    left: 70px;
  }

}

@media (max-width: 500px) {
  .icon_your_diet {
    left: 110px;
  }

  .icon_your_calendar {
    left: 70px;
  }
  .icon_your_calendar_pregnancy{
    /* left: 70px; */
  }

}

@media (max-width: 450px) {
  .icon_your_diet {
    left: 98px;
  }

  .icon_your_calendar {
    left: 60px;
  }
  .icon_your_calendar_pregnancy{
    /* left: 60px; */
  }

}

@media (max-width: 320px) {
  .icon_your_diet {
    left: 86px;
  }

  .icon_your_calendar {
    left: 49px;
  }
  .icon_your_calendar_pregnancy{
    /* left: 49px; */
  }

}

@media (max-width: 310px) {
  .icon_your_diet {
    left: 67px;
  }

  .icon_your_calendar {
    left: 27px;
  }
  
  .icon_your_calendar_pregnancy{
    /* left: 27px; */
  }

}

@media (max-width: 300px) {
  .icon_your_diet {
    left: 61px;
  }

  .icon_your_calendar {
    left: 24px;
  }
  .icon_your_calendar_pregnancy{
    /* left: 24px; */
  }

}

@media (max-width: 290px) {
  .icon_your_diet {
    left: 57px;
  }

  .icon_your_calendar {
    left: 18px;
  }
  .icon_your_calendar_pregnancy{
    /* left: 18px; */
  }

}

@media (max-width: 280px) {
  .icon_your_diet {
    left: 52px;
  }

  .icon_your_calendar {
    left: 16px;
  }
  .icon_your_calendar_pregnancy{
    /* left: 16px; */
  }

}
