.sidebar-container {
    display: flex;
    flex-direction: row;   
    color : #29292E;
  }
  
.options-container {
    display: flex;
    flex-direction: column;
    width: 20%;
    background-color: #fff;
    gap: 17px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #BAD0A2;
    font-family: 'Montserrat' !important;
    z-index: 10000;
    max-width: 100%;
  }


.options-container-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    gap: 17px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #BAD0A2;
    font-family: 'Montserrat';
    position: fixed;
    top: 60px;
    z-index: 10000;
}
  
.option {   
    cursor: pointer;
    opacity: 0.7;
    background: #F8F8F8;
  }

.option .chevron-img {
    height: 6px;
    width: 10px;
}

.option .option_marker {
    width: 7px;
    height: 45px;
}

.white-option {
    cursor: pointer;
    opacity: 0.7;
    background: #ffffff;
}
  
.selected {
    /*background-color: #F6FFEA !important;*/
    opacity: 1;
  }
  
.content-container {
    padding-top: 20px;
    width:80%;
  }
  

.sidebar-profile-icon{     
    position: relative;
}


.sidebar-profile-icon img {
    width: 56px;
    height: 56px;
    position: absolute;
    top: 56px;
    left:  calc(50% - 28px);
    
}

.sidebar-background-letters{
    display :flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;   
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 50%;    
    font-family: 'Recline Regular'; 
    font-size: 31px;    
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    position: absolute;
    top: 56px;
    left: calc(50% - 28px);
    z-index: 8; 

}

.sidebar-profile-username{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Recline Regular';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #29292E;
    margin-top: 125px;
    margin-bottom: 68px;
}

.sidebar-menu-options{
    display:flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 63px;
    font-family: 'Montserrat' !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #29292E;
    border-top: 1px solid #ffff;
    border-bottom: 1px solid #ffff;
}

.sidebar-menu-options img{    
    margin-left: 16px;
    margin-right: 9px;
    width: 20px;
    height: 18px;
}

.sidebar-psi-couple>div>p{
    padding-top: 0.2rem;
}
@media (max-width: 350px){
.couple-burger-title{
    font-size: 16px!important;
}
}

@media (max-width: 1530px) {
    .psychology-subscription-title {
        font-size: 15px;
    }
    .psychology-subscription-options{
        font-size: 15px;
    }
    .psychology-subscription-pairs{
        font-size: 15px;
    }
}
@media (max-width: 1430px) {
    .psychology-subscription-pairs{
        font-size: 13px;
    }
}
@media (max-width: 1080px) {
    .psychology-subscription-title {
        font-size: 13px;
    }
   .psychology-subscription-options{
        font-size: 15px;
    }
    .psychology-subscription-pairs{
        font-size: 12px;
    }
}
@media (min-width: 768px) {
    .content-container {
        height: 80vh;
        overflow-y: auto;
    }
}

@media (max-width: 768px) {
    .options-container {
        display: none;
    }

    .sidebar-psi-couple>div>p{
        padding-top: 0rem;
    }

    .sidebar-menu{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

@media (min-width: 768px) {
    .options-container-mobile {
        display: none;
    }
}
