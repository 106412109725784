.facturation-form-title {
  text-align: center;
  font-family: 'Recline Regular';
  font-weight: 500;
  font-size: 25px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #29292E;
}
.facturation-form-subtitle {
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  margin-top:2rem;
  margin-bottom: 2rem;
  color: #A4A4A4;


}
.facturation-form-container{
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-left: 0.1rem solid  #D8D8D8; 
  border-right: 0.1rem solid  #D8D8D8; 
  border-bottom: 0.1rem solid  #D8D8D8;
  border-top: 0.1rem solid  #BAD0A2;
  border-radius: 0.9rem 0.9rem 0.55rem 0.55rem;
  color: #29292E;
  margin-bottom: 3rem;
  
}

.facturation-form-container-header{
  height: 1rem;
  background-color:#BAD0A2 ;  
  border-top-right-radius: 0.9rem;
  border-top-left-radius: 0.9rem;
}

.reveal-password1, .reveal-password2 {
  position: relative;
  bottom: 45px;
  left: 83%;
  width: 40px;
  height: 40px;
}

.facturation-form-container input, select{
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  background-color: white !important;
  width: 100%;
  box-sizing: border-box;
  border: 0.1rem solid #E5E5E5;
  border-radius: 0.5rem;
  height: 32px !important;
  min-height: 32px !important;
  padding-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  outline: none;
}

.form-container {
  display:flex;
  flex-direction: column;
  width: 80%;
  margin :0 auto;
}

.row-double-input{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}


.input-short1 {
  display:flex;
  flex-direction: column;
  width: 47%;
  align-items: start;
  justify-content: start;
}
.input-short2 {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  width: 47%;
  align-items: start;
  justify-content: start;
}

.form-container label {
    text-align: left;
    font-family: 'Montserrat SemiBold';
}

.facturation-form-container form {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 3rem;
}

.form-row {
  display:flex;
  flex-direction: row;
}

.panel-header p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center !important;
    text-transform: uppercase;
    color: #29292E;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
  }

.red {
  color: red;
}

@media only screen and (max-width: 768px) {
  .address-2-hide {
    margin-top: -0.5rem !important;
  }

  .address-2-hide span{
    display: none;
  }

  .row-double-input{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .input-short1 {
    width: 100%;
    max-height: 65px;
  }

  .reveal-password1 {
    bottom: 42px;
  }

  .reveal-password2 {
    bottom: 43px;
  }

  .input-short1 input {
    margin-bottom: 0;
  }

  .input-short2 {
    width: 100%;
  }
}


.without-label{
  vertical-align: middle;
}

.instructions{
  background-color: antiquewhite;
  color: #29292E;
}
.invalidInput input{
  border: 0.07rem solid red;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 496px) {
  .form-container {
    width: 90%;
    margin :0 auto;
  }
  .form-container label {
    margin-top: 0;    
}
.facturation-form-subtitle {
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 10.24px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #A4A4A4;
}
.facturation-form-title {
  text-align: center;
  font-family: 'Recline Medium';
  font-weight: 500;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;
  color: #29292E;
}
}

