.bf-promotions-container.bf-psychology-container::before {
  background-image: url("../../../../../assets/blackfriday/psychology-mobile.jpg");
}
@media (width > 37.5rem) {
  .bf-promotions-container.bf-psychology-container::before {
    background-image: url("../../../../../assets/blackfriday/psychology-desktop.jpg");
  }
}

.bf-promotions-container.bf-psychology-container::after {
  background-color: rgba(10, 13, 11, 0.7); /* Cambiar color o ajustar su opacidad  */
}

.bf-promotions-container.bf-psychology-container .MuiCircularProgress-root {
  color: rgba(155, 132, 100, 1);
}
