.card-myproducts {
    background-color:#FFFFFF;
    padding: 15px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    width: 75%;
    max-width: 699px;
    max-height: 121px;
    /* box-shadow: 0px 2px 4px -2px #1113220F;
box-shadow: 0px 4px 8px -2px #F2F4F5; */
/* box-shadow: -10px 8px 36px 20px #072A440A;
box-shadow: 0px 8px 36px 0px #072A440A; */
margin: auto;/* box-shadow: 0px 8px 36px 0px #072A440A; */
margin-top: 20px;
/* box-shadow: 0px 4px 6px -2px #11132208; */

box-shadow: 0px 12px 16px 4px #F2F4F5;

}
.myprod-link {
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    /* Agrega otros estilos según tus necesidades */
  }
.myproduct-img img{
    max-width: 126.48px;
    border-radius: 1.983px;
}
.icon-rigth {
    width: 100%;
    margin-left: 0.5rem;
    max-width: 32px;
}
.card-myproducts-main h1{
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 31px;
    line-height: 61px;
    text-transform: capitalize;
    color: #29292E;
}


.myproduct-description {
    margin-left: 20px;
    width: 80%;
}

.myproduct-description-title {
    display: flex;
    /* font-weight: 800; */
    justify-content: space-between;
    /* align-items: end; */
    margin: 1rem 0rem;
}

.myproduct-description-title h2 {
    font-family: 'Recline Medium';
    font-weight: 500;
    text-align: left;
    font-size: 25px;
    text-transform: none;
    line-height: 35px;
    color: #29292E;
    padding-bottom:10px;
    /* text-transform: uppercase; */
}

.myproduct-description-title button {
    background-color: #bad0a200;
    margin-top: 0.5rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-decoration-line: underline;
    color: #29292E;
}

.myproduct-description-subtitle p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 50px;
    color: #B1B2B2;
    text-align: left;
    position: relative;
    margin-top: -30px;
}
.your-products-general{
    min-height: 300px; 
    margin-top: 35px;
}

.progressbar-container{
    width:100%;
    display: flex;
    flex-direction: column;
}

.progressbar-text{
    width:100%;
    font-family: 'Montserrat';
    font-size:12px;
    font-weight:400;
    line-height:16px;
    color: #29292E;
    text-align:left;
    padding-top:10px;
}

.progressbar-line{
    width:40%;
}

.MuiLinearProgress-colorPrimary{
    background-color:#CED5DF !important;
}

.MuiLinearProgress-barColorPrimary{
    background-color:#73D483 !important;
}

@media (max-width: 768px){
    .card-myproducts {
        padding: 15px;
        display: flex !important;
        flex-direction: row;
        align-items: center;
        /* width: 100%; */
        /* height: 60px; */
        width: 95%;
        /* height: 75px; */
        box-shadow: 0px 12px 16px 4px #F2F4F5;
        margin-top: 20px;
    } 
    
    .myproduct-img img{
        /* display: none; */
        width: 62.716px;
    }

    .myproduct-description-title {
        display: flex;
        /* font-weight: 800; */
        justify-content: space-between;
        /* align-items: end; */
        margin: 0rem 0rem;
    }

    .icon-rigth {
        width: 50%;
        margin-left: 0.5rem;
    }

    .myproduct-img{
        justify-content: center;
        display: flex;
        margin-left: 0;
    }

    .myproduct-description-title h2 {
        margin-top: 10px;
        font-size: 20px;
        text-align: left;
    }

    .myproduct-description-subtitle p {
            position: relative;
            font-size: 10px;
    margin-top: -25px;
    }

    .card-myproducts {
        /* max-height: 60px; */
        padding: 15px;
        display: flex !important;
        flex-direction: row;
        align-items: center;
        /* width: 100%; */
        border-radius: 10px;
        box-shadow: 0px 12px 16px 4px #F2F4F5;
        margin-top: 20px;
    } 
    .reduced-size-product {
        max-height: 60px;
    }
    .card-myproducts a{
        text-align: center;
    }

    .myproduct-img{
        margin-left: 0;
    }

    .card-myproducts-main h1{
        font-size: 20px;
    }

    .myproduct-description-title h2 {
        margin-top: 10px;
        font-size: 16px;
        line-height: 15px;
        padding-bottom:10px;
        text-align: left;
    }

    .your-products-general{
        min-height: 400px;
    }

    .progressbar-text{
        width:70%;
        font-size:10px;
        padding-top:5px;
    }
    
    .progressbar-line{
        width:70%;
    }
}

@media (max-width: 440px){
    .progressbar-text{
        width: 80%;
    }
}
@media (max-width: 402px){
    .progressbar-text{
        width: 90%;
    }
}
@media (max-width: 385px){
    .progressbar-text{
        line-height: 10px;
        width:auto;
    }
    .myproduct-description-title h2 {
        font-size: 14px;
    }
    .text-renovation-product{
        font-size: 8px;
    }
    .card-myproducts {
        padding: 5px;
    }
    .progressbar-text{
font-size: 10px;    }
.myproduct-description-title h2 {
    font-size: 16px;
}
}

@media (max-width: 300px){

    .myproduct-description-title h2{
        font-size:16px;
    }

    .progressbar-text{
        width:auto;
    }

   
}
