.works-container {    
    margin: 0 auto;
    width: 90%;
    padding-bottom: 55px;
}

.works-container span {
    color: #95A585;
    font-weight: 900;
    font-size: 40px;
}


.works-container h3 {
    font-family: 'Recline Medium';
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: #29292E;
}

@media only screen and (max-width: 768px) {
    .works-container {
       width: 100%;
    }

    .text-works-h3 {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .text-works-h3 .line-green {
        color: #95A585;
        font-weight: 900;
        font-size: 40px;
        margin: 0 1rem;
        width: 20px;
    }

    .text-works-h3 span {
        display: flex;
        background: #FFFFFF;
        box-shadow: 0px 8px 36px rgba(7, 42, 68, 0.04);
        border-radius: 10.9746px;
        margin-top: 0.5rem;
        flex-direction: column;
        justify-content: center;
        height: 80px;
        width: 172px;
        font-family: 'Recline Regular';
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: #29292E;
    }  
}

